import Button from "./Button";
import imgDeleteAcount from "../../assets/img/img-delete-acount.svg";
import "../../assets/css/closeAccount.css";
import { useTranslation } from "react-i18next";
import popUpAlert from "./popUpAlert";
import Api from "../../services/usersApi";
import { useAppContext } from "../../context/state";

const CloseAccount = ({ setView }) => {
  const { t } = useTranslation();
  const context = useAppContext();

  const closeAccount = async () => {
    const userId = context.user.uuid;
    const deletes = { is_deleted: true };

    const response = await Api.changeUser(userId, deletes);

    if (response == undefined) {
      popUpAlert(false, t("popUpSave.negative"));
    } else {
      popUpAlert(true, t("popUpSave.positive"));
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      window.location.replace(process.env.REACT_APP_CURRENT_URL);
    }
  };

  return (
    <div className="closeaccount__main">
      <img src={imgDeleteAcount}></img>
      <p className="closeaccount__text">
        {t("profilePage.alertDeleteAcountTitle")}
      </p>
      <div className="closeaccount__main-buttons">
        <Button
          text={t("profilePage.alertDeleteAcountButtonFalse")}
          type="primary"
          onClick={() => setView(false)}
        />
        <Button
          text={t("profilePage.alertDeleteAcountButtonTrue")}
          onClick={() => closeAccount()}
        />
      </div>
    </div>
  );
};

export default CloseAccount;
