import { useLayoutEffect, useEffect, useState } from "react";

import { observer } from "mobx-react-lite";

import "../../../assets/css/polotno/pagesControl.css";
import "../../../assets/css/toggleButton.css";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import letter from "../../../assets/img/icons/solar_letter-linear.svg";

const PagesControl = observer(({ store }) => {
  const { t } = useTranslation();
  const [multiplePages, setMultiplePages] = useState(false);
  const [editingBackSide, setEditingBackSide] = useState(true);

  useLayoutEffect(() => {
    const setPageConfiguration = () => {
      if (store.pages.length > 1) {
        setMultiplePages(true);
        store.selectPage(store.pages[0].id);
      } else {
        setMultiplePages(false);
      }
      setEditingBackSide(false);
    };

    setPageConfiguration();
  }, [store.pages.length]);

  useEffect(() => {
    const changeActivePage = () => {
      if (store.pages.length > 1) {
        if (editingBackSide) {
          store.selectPage(store.pages[1].id);
        } else {
          store.selectPage(store.pages[0].id);
        }
      }
    };

    changeActivePage();
  }, [editingBackSide]);

  const toggleActivePage = () => {
    if (store.pages.length > 1) {
      editingBackSide ? setEditingBackSide(true) : setEditingBackSide(false);
    }
  };

  // const changePageAmount = (event) => {
  //   if (event.target.checked) {
  //     store.addPage();
  //   } else {
  //     store.selectPage(store.pages[0].id);
  //     store.deletePages([store.pages[1].id]);
  //     setEditingBackSide(false);
  //   }
  // };

  return (
    <div className="pages-control">
      {/* <p className="design-pages-toggle-title">
        {t("design.boardUI.pagesControl.twoSides")}
      </p> */}

      {/* <div className="toggle-container">
                <input type="checkbox" className="toggle-input purple-toggle" id='design-pages-toggle'
                    onChange={changePageAmount} checked={multiplePages}
                />
                <label htmlFor='design-pages-toggle' className="toggle-label purple-toggle"></label>
            </div> */}
      <div className="pages-control-toggle-text-container">
        {/* <input
          type="checkbox"
          className="toggle-input"
          id="design-page-side-toggle"
          onChange={toggleActivePage}
          checked={editingBackSide}
          disabled={!multiplePages}
        /> */}

        <label
          htmlFor="design-page-side-toggle"
          className={
            editingBackSide
              ? "pages-control-toggle-text "
              : " pages-control-toggle-text toggle-active"
          }
          onClick={() => setEditingBackSide(false)}
        >
          <img src={letter} alt="letter" />
        </label>
        <label
          htmlFor="design-page-side-toggle"
          className={
            !editingBackSide
              ? "pages-control-toggle-text  "
              : "pages-control-toggle-text toggle-active"
          }
          onClick={() => setEditingBackSide(true)}
        >
          <Icon icon="solar:letter-outline" />
        </label>
      </div>
    </div>
  );
});

export default PagesControl;
