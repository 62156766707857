import VerifyAccount from "../../components/users/verifyAccount"
import useQuery from "../../hooks/useQuery"

function VerifyAccountPage() {
  const query = useQuery();

  return (
    <>
      <VerifyAccount toUrl={query.get("next")}/>
    </>
  );
}
export default VerifyAccountPage;
