import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useAppContext } from "../../context/state";
import useCheckLogin from "../../hooks/useCheckLogin";
import NavBar from "../../components/users/NavBar";

const MyAccount = () => {
  const context = useAppContext();
    const [checkLogin] = useCheckLogin();

    useEffect(() => {
      const check= async()=>{
          await checkLogin();}
      check()
  }, []);
  useEffect(() => {
    context.setSharedState({
      ...context.sharedState,
      invitation: null,
    });
  }, [context.sharedState.invitation]);

  return (
    <>
      {context.user && <div className="container events-dashboard-container">
        <NavBar />
        <Outlet />
      </div>}
    </>
  );
};
export default MyAccount;
