import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../context/state";
import "../../assets/css/stepper.css";
import Modal from "../general/modal";
import useStepper from "../../hooks/useStepper";
import EditButton from "../general/editButton";
import InvitationsServices from "../../services/invitationsServices";
import FormUserModal from "./FormUserModal";

const Stepper = ({
  stepState,
  setStepState,
  stepperList,
  currentStep,
  isTrying = false,
  setDesignStep,
  designStep,
}) => {
  const { t } = useTranslation();
  const context = useAppContext();
  const [movil, setMovil] = useState(false);
  const [modal, setModal] = useState(false);
  const [isFirstSave, setIsFirstSave] = useState(true);
  const { step, oneStep, next, previous, stepCondition } = useStepper({
    limit: stepperList?.length - 1,
    currentStep: currentStep,
  });

  useEffect(() => {
    setStepState(step);
  }, [step]);

  useEffect(() => {
    const preSaveInvitation = async () => {
      if (modal) {
        const invitation = await InvitationsServices.saveDesign(
          context.sharedState.invitation,
          context.invitationStore,
          context.envelopeStore,
          false,
          isFirstSave
        );

        if (isFirstSave) {
          setIsFirstSave(false);
        }
        setStepState(0);
        context.setSharedState({
          invitation: invitation,
        });
      }
    };
    preSaveInvitation();
  }, [modal]);

  const handlerPolotno = () => {
    if (
      context.sharedState.invitation?.envelope_configuration?.pages ===
      undefined
    ) {
      setDesignStep(1);
    } else {
      if (isTrying) {
        setModal(true);
      } else {
        next(step, stepperList?.length - 1);
      }
    }
  };

  return (
    <>
      <div className="stepper-container container">
        <ul className="stepper">
          {stepperList.map((step, key) => (
            <li
              key={key}
              onClick={() => {
                isTrying ? setModal(true) : oneStep(key);
              }}
              className={stepState === key ? "step step-active" : "step "}
              disable={`${stepCondition < key}`}>
              <p className=" step-number">{key + 1}</p>

              <p className="step-text ">{step}</p>
            </li>
          ))}
        </ul>
      </div>
      <div className="step-to-step container">
        <EditButton
          text={t("creation.previous")}
          color="#fff"
          icon="ic:round-navigate-before"
          className={`button-preview ${step === 0 && "visibility-hidden"}`}
          onClick={() => {
            isTrying ? setModal(true) : previous(step);
          }}
        />
        <EditButton
          text={t("creation.next")}
          color="#fff"
          className={`button-next ${
            step === stepperList.length - 1 && "visibility-hidden"
          }`}
          icon="ic:round-navigate-next"
          iconSide="rigth"
          onClick={handlerPolotno}
          disabled={stepCondition < step}
        />
      </div>
      <Modal
        onOpen={modal}
        setOnOpen={setModal}
        buttonOn
        style={{ width: "90%", padding: "1rem 1rem 1rem .5rem" }}>
        <FormUserModal />
      </Modal>
    </>
  );
};
export default Stepper;
