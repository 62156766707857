import { useEffect } from "react";
import useQuery from "../../hooks/useQuery";
import BuyInvitations from "../../components/payments/buyInvitations";

function BuyInvitationsPage() {
  const query = useQuery();

  return (
    <>
      <BuyInvitations
        toUrl={query.get("next") ? query.get("next") : "/my-account/my-events"}
        groups={parseInt(query.get("groups"))}
      />
    </>
  );
}
export default BuyInvitationsPage;
