import { useState, useEffect } from "react";
import { UploadSection } from "polotno/side-panel";
import { useAppContext } from "../../context/state";
import useEnvelopeCustomPanel from "../../hooks/useEnvelopeCustomPanels";
import { PolotnoWorkspace } from "./polotno/polotnoWorkspace";
import PagesControl from "./polotno/pagesControl";
import backgorundLetter from "../../assets/img/general/letter.png";

function EnvelopeDesignBoard(props) {
  const context = useAppContext();
  const {
    customTemplatesSection,
    styleSection,
    customShapesSection,
    stampSection,
    postmarkSection,
    customTextSection,
    // waxSealSection,
    waxSealSection,
  } = useEnvelopeCustomPanel();

  const sidePanelSections = [
    customTemplatesSection,
    styleSection,
    customShapesSection,
    // TextSection,
    stampSection,
    postmarkSection,
    customTextSection,
    waxSealSection,
    //waxSealSection
  ];

  const [envelopeStore] = useState(props.store);

  useEffect(() => {
    const loadDesign = () => {
      const config = context.sharedState.invitation?.envelope_configuration;

      if (config?.pages?.length) {
        envelopeStore.deletePages(envelopeStore.pages.map((page) => page.id));

        if (Object.entries(config).length) {
          config?.pages[0]?.children?.map((child) => {
            if (child.type == "image" || child.type == "svg") {
              if (child.src.includes("seed")) {
                const newUrl = child.src.slice(0, child.src.indexOf("?seed"));
                child.src = `${newUrl}?seed=${Math.round(Math.random() * 100)}`;
              } else {
                child.src = `${child.src}?seed=${Math.round(
                  Math.random() * 100
                )}`;
              }
            }
          });
        }

        envelopeStore.loadJSON(config, true);

        if (config?.pages.length == 1) {
          envelopeStore.addPage();
          envelopeStore.setSize(
            envelopeStore.width,
            envelopeStore.height,
            true
          );

          envelopeStore.pages[1].addElement({
            type: "image",
            // x: 0,
            // y: 0,
            width: envelopeStore.width,
            height: envelopeStore.height,

            locked: true, // deprecated
            blurEnabled: true,
            blurRadius: 0,
            brightnessEnabled: true,

            shadowEnabled: true,

            shadowOpacity: 1,
            name: "letter",
            src: backgorundLetter,
            selectable: false,
            showInExport: true,
            contentEditable: false,
            removable: false,
            resizable: false,
          });

          props.saveDesign(envelopeStore);
        }
      } else {
        envelopeStore.setSize(858, 858, true);
        envelopeStore.addPage();
        envelopeStore.addPage();

        envelopeStore.pages[1].addElement({
          type: "image",
          x: 0,
          y: 0,
          width: envelopeStore.width,
          height: envelopeStore.height,
          locked: true,
          blurEnabled: true,
          blurRadius: 0,
          brightnessEnabled: true,
          shadowEnabled: true,
          shadowOpacity: 1,
          name: "letter",
          src: backgorundLetter,
          borderColor: "black",
          selectable: false,
          showInExport: false,
          draggable: true,
          contentEditable: false,
          removable: true,
          resizable: false,
        });

        props.saveDesign(envelopeStore);
      }
      // envelopeStore.pages[1].addElement({
      //   type: "text",
      //   text: "loading",
      // });

      // envelopeStore.selectPage(envelopeStore.pages[1].id);

      const groupElementHost = envelopeStore.pages[1]?.children.find(
        (element) => {
          return element.name === "Nombre del invitado";
        }
      );
      envelopeStore.pages[1].set({
        background: envelopeStore.pages[0].background,
      });
      groupElementHost?.set({
        text: context.sharedState.invitation.sender_name,
      });
      if (
        context.sharedState.invitation.configuration?.height >
        context.sharedState.invitation.configuration?.width
      ) {
        return envelopeStore?.setSize(
          context.sharedState.invitation.configuration.height,
          context.sharedState.invitation.configuration.width,
          true
        );
      } else {
        return envelopeStore?.setSize(
          context.sharedState.invitation.configuration.width,
          context.sharedState.invitation.configuration.height,
          true
        );
      }
    };

    loadDesign();
  }, []);

  return (
    <>
      {!props.loading && <PagesControl store={envelopeStore} />}
      <PolotnoWorkspace
        store={envelopeStore}
        sidePanelSections={sidePanelSections}
        loading={props.loading}
      />
    </>
  );
}
export default EnvelopeDesignBoard;
