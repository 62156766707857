/* REMOVE - FREE INVITATIONS CAMPAIGN */
import { useEffect } from "react";
import { generateToken } from "../services/utils";
import useQuery from "../hooks/useQuery";

const TryKolibirdFree = () => {
  const query = useQuery();
  const tryKb = query.get("try")
  useEffect(()=> {
    const offerToken = generateToken({code: "FREE123"});
    localStorage.setItem("welcomeOffer", offerToken);
    if(tryKb){
      window.location.replace(`/try-kolibird/?template=${tryKb}`);
    }
    else {
      window.location.replace("/");
    }
  }, []);

  return <></>
}
export default TryKolibirdFree;
/* REMOVE - FREE INVITATIONS CAMPAIGN */
