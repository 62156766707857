import { useState, useEffect } from "react";
import { useAppContext } from "../../src/context/state";

const usePreviewEmail = () => {
  const context = useAppContext();
  const [envelope, setEnvelope] = useState(
    context.sharedState.invitation?.show_email_envelope
  );
  const [personalMessage, setPersonalMessage] = useState(
    context.sharedState.invitation.has_email_content
  );
  const [headerMessage, setHeaderMessage] = useState(
    context.sharedState.invitation.has_email_title
  );

  useEffect(() => {
    (() => {
      context.setSharedState({
        ...context.sharedState,
        invitation: {
          ...context.sharedState.invitation,
          show_email_envelope: envelope,
          has_email_content: personalMessage,
          has_email_title: headerMessage,
        },
      });
    })();
  }, [envelope, personalMessage, headerMessage]);

  const toggleEnvelope = () => {
    setEnvelope(!envelope);
    contextUpdate();
  };
  const togglePersonalMessage = () => {
    setPersonalMessage(!personalMessage);
    contextUpdate();
  };
  const toggleHeaderMessage = () => {
    setHeaderMessage(!headerMessage);
    contextUpdate();
  };
  const contextUpdate = () => {};

  return {
    togglePersonalMessage,
    toggleEnvelope,
    toggleHeaderMessage,
    personalMessage,
    headerMessage,
    envelope,
  };
};
export default usePreviewEmail;
