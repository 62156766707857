import * as React from "react";
import { useRef, useCallback } from "react";
import logo from "../../assets/img/logo.svg";
import instagramIcon from "../../assets/img/iconSocialMedia/instagram.svg";
import linkedinIcon from "../../assets/img/iconSocialMedia/linkedin.svg";
import facebookIcon from "../../assets/img/iconSocialMedia/facebook.svg";
import { useForm } from "react-hook-form";
import Button from "./Button";
import CheckBoxButton from "./CheckBoxButton";
import ApiNewsLetter from "../../services/newsletterApi";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Footer() {
  const [t, i18n] = useTranslation("translation");
  const captcha = useRef(null);
  let pathname = window.location.pathname;
  const currentLenguage = i18n.language.slice(0, 2);
  const {
    register,
    handleSubmit,
    resetField,
    setError,
    clearErrors,
    watch,

    formState: { errors },
  } = useForm({ mode: "onChange", reValidateMode: "all" });

  const onSubmit = async (data) => {
    if (captcha.current) {
      const payload = { email: data.email };
      const response = await ApiNewsLetter.postNewsletterEmail(payload);
      if (response != undefined) {
        resetField("email");
        resetField("checkNewsletter");
        clearErrors("errorCaptcha");
      }
    } else {
      setError("errorCaptcha", {
        type: "custom",
        message: "Error en captcha, recargue la pagina",
      });
    }
  };
  const handleVerify = useCallback(async (token) => {
    if (token) {
      captcha.current = await token;
    }
  });

  const changeLanguage = (language) => {
    localStorage.setItem("i18nextLng", language);
    window.location.reload();
  };

  return pathname == "/sign-in" ||
    pathname == "/sign-up" ||
    pathname?.includes("/invitation/") ? (
    <></>
  ) : (
    <footer className="footer">
      <div className="footer-container container">
        <div>
          <a
            href={`${process.env.REACT_APP_CURRENT_URL}${
              currentLenguage == "en" ? `/${currentLenguage}/` : ""
            }`}
          >
            <img src={logo} alt="logo" />
          </a>
        </div>

        <div className="footer-content-container">
          <div className="footer-content-info">
            <div className="footer-nav-container">
              <div className="footer-nav">
                <a
                  href={`${process.env.REACT_APP_CURRENT_URL}`}
                  className="footer-nav-item"
                >
                  {t("header.home")}
                </a>
                <div className=" footer-dropdown-container">
                  <p
                    className="footer-nav-item footer-lenguage"
                    onClick={(e) =>
                      e.target.classList.toggle("active-dropdown")
                    }
                  >
                    {t("general.event")}
                  </p>

                  <div className="footer-dropdown  dropdown-events-container">
                    <a
                      className="footer-nav-item "
                      href={`${process.env.REACT_APP_CURRENT_URL}${
                        currentLenguage == "en" ? `/${currentLenguage}/` : "/"
                      }${t("general.birthday.url")}`}
                    >
                      {t("general.birthday.text")}
                    </a>
                    <a
                      className="footer-nav-item "
                      href={`${process.env.REACT_APP_CURRENT_URL}${
                        currentLenguage == "en" ? `/${currentLenguage}/` : "/"
                      }${t("general.wedding.url")}`}
                    >
                      {t("general.wedding.text")}
                    </a>
                    <a
                      className="footer-nav-item "
                      href={`${process.env.REACT_APP_CURRENT_URL}${
                        currentLenguage == "en" ? `/${currentLenguage}/` : "/"
                      }${t("general.communion.url")}`}
                    >
                      {t("general.communion.text")}
                    </a>
                    <a
                      className="footer-nav-item "
                      href={`${process.env.REACT_APP_CURRENT_URL}${
                        currentLenguage == "en" ? `/${currentLenguage}/` : "/"
                      }${t("general.companies.url")}`}
                    >
                      {t("general.companies.text")}
                    </a>
                  </div>
                </div>
                <a
                  href={`${process.env.REACT_APP_CURRENT_URL}${
                    currentLenguage == "en" ? `/${currentLenguage}/` : "/"
                  }pricing/`}
                  className="footer-nav-item"
                >
                  {t("header.pricing")}
                </a>
                <a
                  href={`${process.env.REACT_APP_CURRENT_URL}/blog/`}
                  className="footer-nav-item"
                >
                  {t("header.blog")}
                </a>

                <Link className="footer-nav-item" to="/contact-us">
                  {t("header.contact")}
                </Link>
              </div>
              <div className="footer-nav">
                <a
                  href={`${process.env.REACT_APP_CURRENT_URL}${
                    currentLenguage == "en" ? `/${currentLenguage}/` : "/"
                  }${t("footer.urlLegal")}/`}
                  className="footer-nav-item"
                >
                  {t("footer.legal")}
                </a>
                <a
                  href={`${process.env.REACT_APP_CURRENT_URL}${
                    currentLenguage == "en" ? `/${currentLenguage}/` : "/"
                  }${t("footer.urlPrivacy")}/`}
                  className="footer-nav-item"
                >
                  {t("footer.privacy")}
                </a>
                <a
                  href={`${process.env.REACT_APP_CURRENT_URL}${
                    currentLenguage == "en" ? `/${currentLenguage}/` : "/"
                  }${t("footer.urlCookies")}/`}
                  className="footer-nav-item"
                >
                  {t("footer.cookies")}
                </a>
              </div>
              <div className="footer-nav footer-idioms-container">
                <p
                  className="footer-nav-item footer-lenguage"
                  onClick={(e) => e.target.classList.toggle("active-idioms")}
                >
                  {t("footer.language.title")}
                </p>

                <div className="footer-idioms">
                  <p
                    className="footer-nav-item"
                    onClick={() => i18n.changeLanguage("es-ES")}
                  >
                    {t("footer.language.spanish")}
                  </p>

                  <p
                    className="footer-nav-item"
                    onClick={() => i18n.changeLanguage("en-US")}
                  >
                    {t("footer.language.english")}
                  </p>
                </div>
              </div>
            </div>
            <div className="social-media">
              <a
                href="https://www.instagram.com/kolibirdapp/"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <img src={instagramIcon} alt="Ir al Instagram de Kolibird" />
              </a>
              <a
                href="https://www.linkedin.com/company/86294976/"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <img src={linkedinIcon} alt="Ir al Linkedin de Kolibird" />
              </a>

              <a
                href="https://www.facebook.com/Kolibirdapp"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <img src={facebookIcon} alt="Ir al Facebook de Kolibird" />
              </a>
            </div>
          </div>
          <div className="footer-newsletter-container">
            <h3 className="footer-newsletter-title">
              {t("footer.newsletter.title")}
            </h3>
            <p className="footer-newsletter-content-text">
              {t("footer.newsletter.content")}
            </p>
            <div>
              <label htmlFor="info-input" className="footer-label-email">
                {t("footer.newsletter.label")}
              </label>
              <form
                id="footer-form"
                onSubmit={handleSubmit(onSubmit)}
                className="footer-newsletter-form"
              >
                <div className="footer-inputs">
                  <div
                    id="info-input"
                    className={
                      !errors?.email && watch().email?.length
                        ? " input-ok info-input-container"
                        : "  info-input-container"
                    }
                  >
                    <input
                      type="text"
                      placeholder={t("footer.newsletter.placeholder")}
                      {...register("email", {
                        required: true,
                        pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                      })}
                      className={errors?.email ? "error input" : "input"}
                    />

                    {errors.email && <span className="error-point"></span>}
                    {errors.email && (
                      <span className="error-text">
                        {t("signIn.emailError")}
                      </span>
                    )}
                  </div>

                  <div className="footer-checkbox-container ">
                    <CheckBoxButton
                      id="footer-check-newsletter"
                      validations={{
                        ...register("checkNewsletter", {
                          required: true,
                        }),
                      }}
                    />
                    {errors?.checkNewsletter && (
                      <span className="error-point"></span>
                    )}
                    {errors?.checkNewsletter && (
                      <span className="error-text">
                        {t("footer.newsletter.termsError")}
                      </span>
                    )}
                    <label
                      htmlFor="footer-check-newsletter"
                      className="footer-label-check"
                    >
                      {t("footer.newsletter.terms")}
                    </label>
                  </div>

                  <div className="captcha">
                    <p className="captcha-policy">
                      This site is protected by reCAPTCHA and the Google
                      <a href="https://policies.google.com/privacy">
                        {" "}
                        Privacy Policy{" "}
                      </a>
                      and
                      <a href="https://policies.google.com/terms">
                        {" "}
                        Terms of Service{" "}
                      </a>
                      apply.
                    </p>
                    {errors?.errorCaptcha && (
                      <span className="error-text">
                        {errors.errorCaptcha.message}
                      </span>
                    )}
                    <GoogleReCaptcha onVerify={handleVerify} />
                  </div>
                </div>
                <Button
                  buttonType="submit"
                  text={t("footer.newsletter.submit")}
                  type="primary"
                  className="footer-button-newsletter"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
