import { useEffect } from "react";
import { useAppContext } from "../../context/state";
import { useTranslation } from "react-i18next";

function StartPoll(props = {}) {
  const context = useAppContext();
  const { t } = useTranslation();

  useEffect(() => {
    if (context.sharedState.questions.length > 0) {
      props.handleStep(2);
    }
  }, []);

  return (
    <>
      <div onClick={() => props.handleStep(2)}>{t("poll.start")}</div>
      <div onClick={() => props.handleStep(1)}>{t("poll.useTemplate")}</div>
    </>
  );
}
export default StartPoll;
