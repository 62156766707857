class UsersServices{
  static isLogIn(){
    return "accessToken" in localStorage;
  }

  static hasRefreshToken(){
    return "refreshToken" in localStorage;
  }
}
export default UsersServices;
