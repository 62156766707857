// import { useState } from "react";
import { JSONCard } from "../animations/JSONCard";

const EnvelopeNoAnimate = ({
  color,
  postcardElement,
  frontCard,
  invitation,
  envelope,
  envelopeSide = "back",
  card,
  scale = 0.25,
}) => {
  const backgroundColor =
    color || frontCard.envelope_configuration.pages[0].background;
  const postalForm = () => {
    if (postcardElement.width === postcardElement.height) {
      //cuadrada
      return {
        shapes: "envelope--square",
        styles: {
          width: postcardElement.width,
          height: postcardElement.height,
          transform: `rotateZ(0deg) scale(${scale})`,
          position: "absolute",
          left: invitation && "0%",
        },
        styleCard: { right: "-10%", bottom: invitation && "14%" },
      };
    } else if (postcardElement.height > postcardElement.width) {
      //portrai
      return {
        shapes: "envelope--landscape",
        styles: {
          width: postcardElement.height,
          height: postcardElement.width,
          transform: `rotateZ(0deg) scale(${scale})`,
          position: "absolute",
          // bottom: "50%",
          left: "0%",
        },
        styleCard: {
          scale: "1.1",
          bottom: invitation && "30%",
          right: invitation && "-20%",
          width: postcardElement.width * scale,
          height: postcardElement.height * scale,
          ...(!invitation && { display: "flex", justifyContent: "center" }),
        },
      };
    } else {
      //landscape

      return {
        shapes: "envelope--landscape",
        styles: {
          width: postcardElement.width,
          height: postcardElement.height,
          transform: `rotateZ(0deg) scale(${scale})`,

          position: "absolute",
          // bottom: "50%",
        },
        styleCard: {
          scale: "1",
          right: "-10%",
          bottom: "25%",
          width: postcardElement.width * scale,
          height: postcardElement.height * scale,
        },
      };
    }
  };

  return (
    <div
      className="no-animated-container"
      style={{
        width: postalForm().styles.width * (scale * 1.2),
        height: postalForm().styles.height * (scale * 1.8),
      }}
    >
      {(invitation || envelope) && (
        <>
          <div
            className="no-animate"
            style={{
              width: postalForm().styles.width * scale,
              height: postalForm().styles.height * scale,
              left: 0,
            }}
          >
            <div
              className={`envelope-container ${postalForm().shapes}`}
              style={{
                transform: postalForm().styles.transform,
                position: "absolute",
                bottom: postalForm().styles.bottom,
                left: postalForm().styles.left,
              }}
            >
              <div className="wrap-envelope-transform">
                <div className="envelope-transform">
                  <div
                    className="envelope--back card-back "
                    style={{
                      width: postalForm().styles.width,
                      height: postalForm().styles.height,
                      ...(envelopeSide == "front" && {
                        transform: "rotateY(0deg)",
                      }),
                    }}
                  >
                    {envelopeSide === "back" && (
                      <>
                        <div className="envelope--back-laterals">
                          <div
                            className="envelope--back-lateral"
                            style={{ backgroundColor: backgroundColor }}
                          ></div>
                          <div
                            className="envelope--back-lateral"
                            style={{ backgroundColor: backgroundColor }}
                          ></div>
                        </div>

                        <div className="envelope--back-bottom">
                          <div
                            className="envelope--back-bottom-element"
                            style={{ backgroundColor: backgroundColor }}
                          ></div>
                        </div>
                      </>
                    )}
                    {envelopeSide === "front" && (
                      <>
                        <div
                          className="postcard--front "
                          style={{ backgroundColor }}
                        >
                          <JSONCard
                            card={{
                              ...frontCard.envelope_configuration,
                              ...postalForm().styles,
                            }}
                            index={0}
                            isACard={false}
                            // className="prueba-back"
                          />
                        </div>
                      </>
                    )}

                    <div className="envelope--back-tongue-container">
                      <div
                        className="envelope--back-tongue"
                        style={{ backgroundColor: backgroundColor }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {(card === true || invitation === true) && (
        <div
          className="no-animate"
          style={{
            width: postalForm().styles.width * scale,
            height: postalForm().styles.height * scale,
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
          }}
        >
          <img
            src={frontCard.image}
            className="template-image"
            style={{
              width: postalForm().styleCard.width,
              height: postalForm().styleCard.height,

              ...postalForm().styleCard,
            }}
          />
        </div>
      )}
    </div>
  );
};
export default EnvelopeNoAnimate;
