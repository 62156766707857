const MoreOptions = ({ side, openOptions, setOptions, children }) => {
  let optionsContainerStyles = { position: "relative", height: "100%" };
  let optionsStyles = {
    position: "absolute",
    bottom: "30px",
    right: "-0vw",
    height: "-webkit-max-content",
    height: "-moz-max-content",
    height: "max-content",
    display: "flex",
    justifyContent: "center",
    zIndex: 401,
  };
  let closed = {
    position: "fixed",
    bottom: "0",
    right: "0",
    top: "0",
    left: "0",
    zIndex: "400",
  };

  return (
    <>
      {openOptions && (
        <>
          <div style={closed} onClick={() => setOptions(false)}></div>
          <div style={optionsContainerStyles}>
            <div style={optionsStyles}>{children}</div>
          </div>
        </>
      )}
    </>
  );
};
export default MoreOptions;
