import axios from "axios";
import {generateApiToken} from "./utils";

class Api {
  static async uploadFile(payload) {
    const url = `uploads/`;

    payload.token = generateApiToken();

    const payloadSplit = payload.file.split("base64,");
    if (payloadSplit.length > 1) {
      payload.file = payloadSplit[1];
    }

    let response = { data: "" };
    try {
      response = await axios.post(
        `${process.env.REACT_APP_API2_URL}${url}`,
        payload,
      );
    } catch (error) {
      return error.response.data.error;
    }
    return JSON.parse(response.data.body);
  }
}
export default Api;
