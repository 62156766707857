import "./PostCard.css";
import { useEffect, useState } from "react";
import { JSONCard } from "./../JSONCard";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export const PostCard = ({
  color,
  postcardElement,
  frontCard,
  guests,
  groupName,
}) => {
  const [backgroundColor] = useState(color || frontCard.pages[0].background);
  const [sizeScreen, setSizeScreen] = useState(window.innerWidth);
  const [zoom, setZoom] = useState(false);

  const cardStyles = () => {
    const transform = postalForm();
    return {
      width: transform.styles.width,
      height: transform.styles.height,

      ...transform,
    };
  };
  const postalForm = () => {
    if (postcardElement.width === postcardElement.height) {
      //cuadrada
      return {
        shapes: "envelope-square",
        styles: {
          width: postcardElement.width,
          height: postcardElement.height,
          transform: "rotateZ(0deg) scale(.2) ",
        },
      };
    } else if (postcardElement.height > postcardElement.width) {
      //portrai
      return {
        shapes: "envelope-landscape",
        styles: {
          width: postcardElement.height,
          height: postcardElement.width,
          transform: "rotateZ(-0deg) scale(.3)",
        },
      };
    } else {
      //landscape

      return {
        shapes: "envelope-landscape",
        styles: {
          width: postcardElement.width,
          height: postcardElement.height,
          transform: "rotateZ(0deg) scale(.3)",
        },
      };
    }
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      setSizeScreen(window.innerWidth);
    });
  }, []);

  const texture = frontCard.pages[0].children.find((texture) =>
    texture?.name == "texture" ? texture.src : null
  )?.src;

  document?.documentElement.style.setProperty(
    "--backgroundTexture",
    `url("${texture}")`
  );

  return (
    <div
      className={`postcard-container ${postalForm().shapes}`}
      style={postalForm().styles}
    >
      <div className=" wrap-original-transform ">
        <div className="original-transform">
          <div
            className="postcard--front card-front"
            style={{ backgroundColor }}
          >
            <JSONCard
              card={{ ...frontCard, ...postalForm().styles }}
              isACard={false}
              index={0}
              className="prueba-back"
              guests={guests}
              groupName={groupName}
            />
          </div>
          <div
            className="postcard--back card-back "
            style={{
              width: postalForm().styles.width,
              height: postalForm().styles.height,
              backgroundColor: "#fff",
            }}
          >
            <div className="postcard--back-laterals">
              <div
                className="postcard--back-lateral"
                style={{ backgroundColor }}
              ></div>
              <div
                className="postcard--back-lateral"
                style={{ backgroundColor }}
              ></div>
              <JSONCard
                card={{ ...frontCard, ...postalForm().styles }}
                isACard={false}
                index={1}
                className="prueba-back"
                guests={guests}
                groupName={groupName}
              />
            </div>

            <div
              className={`postcard--postal-container postcard--postal--animation ${
                zoom && "postcard--postal-container-prueba"
              } ${
                postcardElement.width > postcardElement.height &&
                "postcard--postal--container--landscape"
              }`}
              onClick={() => setZoom(!zoom)}
              onMouseLeave={() => setZoom(false)}
            >
              <JSONCard
                card={postcardElement}
                index={0}
                isACard={postcardElement.width <= postcardElement.height}
                className="postcard--postal"
              />
            </div>

            <div className="postcard--back-bottom">
              <div
                className="postcard--back-bottom-element"
                style={{ backgroundColor }}
              ></div>
            </div>

            <div className="postcard--back-tongue-container">
              <div
                className="postcard--back-tongue"
                style={{
                  backgroundColor,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {(() => {
                  const groupElementHost = frontCard.pages[1]?.children.find(
                    (element) => {
                      return element.name === "hostName";
                    }
                  );
                  return (
                    <p
                      style={{
                        ...groupElementHost,
                        position: "relative",
                        zIndex: 7,
                        width: "auto",
                      }}
                    >
                      {groupElementHost?.text}
                    </p>
                  );
                })()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
