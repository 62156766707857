import { useEffect, useState } from "react";
import "../../assets/css/signOut.css";
import Modal from "../../components/general/modal";
import signOutImg from "../../assets/img/general/signOut.svg";
import Button from "../../components/general/Button";
import { Link } from "react-router-dom";

function SignOut({ setOnOpen, setExtended }) {
  const handleclick = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    window.location.replace(process.env.REACT_APP_CURRENT_URL);
  };

  return (
    <div className="signOut-container">
      <img src={signOutImg} alt="sign out"></img>
      <h1 className="sigOut-modal-title">
        Estás a punto de cerrar sesión ¿Estás segur@?
      </h1>
      <div className="button-container-signout">
        <Button
          text="No, me quedo un rato más"
          type="primary"
          onClick={() => {
            setExtended(false);
            setOnOpen(false);
          }}
        />
        <a onClick={handleclick}>Sí, de momento me voy</a>
      </div>
    </div>
  );
}
export default SignOut;
