import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/state";
import { useTranslation } from "react-i18next";
import Button from "../general/Button";
import Api from "../../services/usersApi";
import "../../assets/css/verifyAccount.css";
import imageVerify from "../../assets/img/draws/verify.svg";

function VerifyAccount(props = { toUrl: "/my-events/" }) {
  const context = useAppContext();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const checkAccount = async () => {
    const user = await Api.getCurrentUser();
    context.setUser(user);

    if (user.is_verified) {
      navigate(props.toUrl, { replace: true });
    }
  };

  return (
    <div className="verifyAccount__main container creation-board">
      <h1>{t("verifyAccount.title")}</h1>
      <img
        className="img-verify-account"
        src={imageVerify}
        alt="image of verifycation"
      />

      <p className="verifyAccount__text">
        {t("verifyAccount.textOne")}{" "}
        <span>{t("verifyAccount.textSpan")}</span>{" "}
        {t("verifyAccount.textTwo")}
      </p>

      <Button
        type={"primary"}
        text={t("verifyAccount.checkAccount")}
        onClick={() => checkAccount()}
      />
    </div>
  );
}
export default VerifyAccount;
