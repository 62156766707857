import { useState } from "react";

function ShortTextForm(props = { value: "" }) {
  const [text, setText] = useState(props.value);

  return (
    <div className="info-input-container">
      <input
        className="input"
        type="text"
        onChange={(event) => {
          setText(event.target.value);
          props.modifyForm(props.question.id, event.target.value);
        }}
        value={text}
        required={props.question.required}
      />
    </div>
  );
}
export default ShortTextForm;
