import {
  invitationExtension,
  invitationPlace,
} from "../constants/uploadConstants";
import UploadApi from "../services/uploadApi";
import * as CryptoJS from "crypto-js";

export const resizeBase64Img = (base64, newWidth, newHeight) => {
  return new Promise((resolve, reject) => {
    let canvas = document.createElement("canvas");
    canvas.style.width = newWidth.toString() + "px";
    canvas.style.height = newHeight.toString() + "px";
    canvas.width = newWidth;
    canvas.height = newHeight;
    let context = canvas.getContext("2d");
    let img = document.createElement("img");
    img.src = base64;
    img.onload = function () {
      context.scale(newWidth / img.width, newHeight / img.height);
      context.drawImage(img, 0, 0);
      resolve(canvas.toDataURL());
    };
  });
};

export const localFileToURL = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const uploadInvitationImageFile = async (
  base64File,
  invitationUuid,
  fileName = null,
  isStaff = false
) => {
  let imagePlace = invitationPlace;

  if (isStaff) {
    imagePlace = `${imagePlace}/template`;
  }

  if (!isStaff && process.env.REACT_APP_ENV !== "pro") {
    imagePlace = `pre/${imagePlace}`;
  }

  const payload = {
    upload_place: invitationPlace,
    resource_id: invitationUuid,
    extension: invitationExtension,
    file: base64File,
    file_name: fileName,
  };

  const response = await UploadApi.uploadFile(payload, invitationUuid);

  return response.url;
};

export const generateApiToken = (data = null) => {
  const origin = process.env.REACT_APP_FAKE_ORIGIN
    ? process.env.REACT_APP_FAKE_ORIGIN
    : window.location.origin;
  const time = Math.floor(Date.now() / 1000);

  let tokenData = { url: origin, date: time };
  if (data) {
    tokenData = { ...tokenData, ...data };
  }

  const objectToken = JSON.stringify(tokenData);
  const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AUTH_KEY);
  const token = CryptoJS.AES.encrypt(objectToken, key, {
    mode: CryptoJS.mode.ECB,
  });
  return token.toString().replaceAll("+", "-");
};

export const generateToken = (data = null) => {
  const time = Math.floor(Date.now() / 1000);
  let tokenData = { date: time };
  if (data) {
    tokenData = { ...tokenData, ...data };
  }
  const objectToken = JSON.stringify(tokenData);
  return btoa(btoa(objectToken));
};

export const decodeToken = (token) => {
  return JSON.parse(atob(atob(token)));
};

export const findLocation = () => {
  const location = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
  if (location) {
    return location;
  }
  return false;
};

export const isEuropean = () => {
  const validLocation = [
    "Europe",
    "Canary",
    "Ceuta",
    "Madeira",
    "Azores",
    "Reykjavik",
    "Faroe",
  ];
  try {
    const location = findLocation();
    if (location) {
      const isEuropeanLocation = validLocation.some((validLoc) =>
        location.includes(validLoc)
      );
      return isEuropeanLocation;
    }
    /*Si hubiese algun error al intentar acceder al sistema o la ubicacion se devuelve true para por defecto sea siempre en Euros aunque falle */
    return true;
  } catch {
    return true;
  }
};
