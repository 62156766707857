import { Icon } from "@iconify/react";
import { useForm } from "react-hook-form";
import "../../assets/css/contactUs.css";
import Button from "../../components/general/Button";
import Api from "../../services/usersApi";
import kolipict from "../../assets/img/general/colibri-contacta.png";
import { t } from "i18next";
import { useState, useEffect } from "react";
import useScrollbySize from "../../hooks/useScroll";
import popUpAlert from "../../components/general/popUpAlert";
import { useTranslation } from "react-i18next";
import imgContactUs from "../../assets/img/contactUs/contactUs.svg";

function ContactUs() {
  const { t } = useTranslation();
  const scroll = useScrollbySize(500, 600, "top") 
  const [itSend, setItSend] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  useEffect(()=>{
    scroll()
  },[])
  
  function handleForm(data) {
    const contactMessage = {
      topic: data?.name,
      text: data?.textarea,
      email: data?.email,
    };
    const response = Api.userContact(contactMessage);
    if (response !== undefined) {
      setItSend(true);
    }
  }

  return (
    <div className="container creation-board contact-us-container">
      {itSend ? (
        <div className="contact-us-form-send">
          <img src={imgContactUs} alt="send mesage" />
          <h2>Gracias por contactar</h2>
          <p>En breve nos pondremos en contacto contigo</p>
          <div className="contact-social-media contact-us-form-send-social-media">
            <p>No te olvides seguirnos en:</p>
            <div>
              <a
                href="https://www.instagram.com/kolibirdapp/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="teenyicons:instagram-solid" color="#6b3378" />
              </a>
              <a
                href="https://www.linkedin.com/company/kolibird-app/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="brandico:linkedin-rect" color="#6b3378" />
              </a>
              <a
                href="https://www.facebook.com/Kolibirdapp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="brandico:facebook-rect" color="#6b3378" />
              </a>
            </div>
          </div>
        </div>
      ) : (
        <>
          {" "}
          <div className="contact-us">
            <div className="contact-info">
              <div className="contact-us-header">
                <h1>{t("form.title")}</h1>
                <h2 className="contact-us-header-subtitle">
                  {t("form.subTitle")}
                </h2>
              </div>
              <p>{t("form.contactInfo")}</p>
              <img className="contact-kolipict" src={kolipict} alt="colibri" />
              <a href="mailto:hi@kolibird.app" className="contact-email">
                <Icon icon="fluent:mail-16-filled" />
                hi@kolibird.app
              </a>
              <div className="contact-social-media">
                <a
                  href="https://www.instagram.com/kolibirdapp/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon icon="ph:instagram-logo-fill" color="#6b3378" />
                </a>
                <a
                  href="https://www.linkedin.com/company/kolibird-app/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon icon="brandico:linkedin-rect" color="#6b3378" />
                </a>

                <a
                  href="https://www.facebook.com/Kolibirdapp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon icon="brandico:facebook-rect" color="#6b3378" />
                </a>
              </div>
            </div>

            <form className="contact-us-form">
              <p>{t("form.sendUsMessage")}</p>

              <div className=" info-field">
                <label className="info-label">{t("form.name")}</label>
                <div
                  id="info-input"
                  className="info-input-container info-input-container-contact"
                >
                  <input
                    {...register("name", {
                      required: {
                        value: true,
                      },
                      minLength: {
                        value: 2,
                      },
                      maxLength: {
                        value: 40,
                      },
                    })}
                    placeholder={t("form.namePlaceholder")}
                    className="input"
                    type="text"
                  />
                  {errors?.name && <span className="error-point"></span>}
                  {errors?.name && (
                    <span className="error-text">
                      {t("form.nameNullError")}
                    </span>
                  )}
                </div>
              </div>
              <div className=" info-field">
                <label className="info-label" htmlFor="email_title">
                  {t("form.email")}
                </label>
                <div
                  id="info-input"
                  className="info-input-container info-input-container-contact"
                >
                  <input
                    type="text"
                    placeholder={t("form.emailPlaceholder")}
                    {...register("email", {
                      required: true,
                      minLength: 5,
                      pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                    })}
                    className="input"
                  />
                  {errors?.email && <span className="error-point"></span>}
                  {errors.email && (
                    <span className="error-text">
                      {t("recovery.emailError")}
                    </span>
                  )}
                </div>
              </div>
              <div className=" info-field">
                <label className="info-label">{t("form.yourMessage")}</label>

                <div
                  id="info-input"
                  className="info-input-container contact-us-form-textarea info-input-container-contact "
                >
                  <textarea
                    {...register("textarea", {
                      required: {
                        value: true,
                        message: t("general.requiredField"),
                      },
                      minLength: { value: 8, message: t("form.textareaError1") },
                      maxLength: {
                        value: 500,
                        message: t("form.textareaError2"),
                      },
                    })}
                    type="textarea"
                    rows="4"
                    cols="50"
                    className="input"
                    placeholder={t("form.enterHere")}
                  />
                  {errors?.textarea && <span className="error-point"></span>}
                  {errors.textarea && (
                    <div className="spanblock">
                      <span className="error-text">
                        {t("form.textareaError")}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <Button
                text={t("general.send")}
                type="primary"
                icon="fluent:checkmark-12-filled"
                onClick={handleSubmit(handleForm)}
              />
            </form>
          </div>
        </>
      )}
    </div>
  );
}
export default ContactUs;
