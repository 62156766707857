import "../../assets/css/checkBoxButton.css";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
// the props validations receives an object with the own validations of an input checkbox

const CheckBoxButton = ({
  id,
  validations,
  checked,
  className,
  onClick,
  required,
  name,
}) => {
  const [check, setcheck] = useState(false);

  useEffect(() => {
    if (checked == true) {
      setcheck(true);
    } else {
      setcheck(false);
    }
  }, [checked]);

  return (
    <div className="checkbox-container">
      {validations ? (
        <input
          onClick={onClick}
          type="checkbox"
          {...validations}
          className={`checkbox-input ${className}`}
          id={id}
        />
      ) : (
        <input
          type="checkbox"
          className={`checkbox-input ${className}`}
          onChange={() => setcheck(!check)}
          onClick={onClick}
          id={id}
          checked={check}
          required={required}
          name={name}
        />
      )}
      <label htmlFor={id} className={` ${className} checkbox-label`}>
        <Icon icon="fa6-solid:check" />
      </label>
    </div>
  );
};

export default CheckBoxButton;
