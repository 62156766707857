import { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import ApiNewsLetter from "../../services/newsletterApi";
import { useTranslation } from "react-i18next";
import { captureException } from "@sentry/react";
import Api from "../../services/usersApi";
import Datalayer from "../../services/datalayer";
import "../../assets/css/log.css";
import logo from "../../assets/img/logo.svg";
import { Icon } from "@iconify/react";
import Button from "../general/Button";
import EditButton from "../general/editButton";
import Modal from "../general/modal";
import RecoveryPassword from "../users/recoveryPassword";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import jwt_decode from "jwt-decode";

function SignIn(props = { toUrl: null, isModal: false, handleModal: null }) {
  const [isSignIn, setIsSignIn] = useState("accessToken" in localStorage);
  const [requestError, setRequestError] = useState(null);
  const [showPassword, setShowPassword] = useState(true);
  const [openModalRecovery, setOpenModalRecovery] = useState(false);
  const captcha = useRef(null);

  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSignIn && props.toUrl !== null) {
      Datalayer.push("login");
      navigate(props.toUrl, { replace: true });
    }
  }, [isSignIn]);

  const signIn = async (data, aouthGoogle) => {
    if (captcha.current) {
      const signInError = await Api.signIn(data.email, data.password);
      aouthGoogle !== true && setRequestError(signInError);

      setIsSignIn("accessToken" in localStorage);
      clearErrors("errorCaptcha");

      return signInError;
    } else {
      setError("errorCaptcha", {
        type: "custom",
        message: t("general.captchaError"),
      });
    }
  };

  const handleVerify = async (token) => {
    if (token) {
      captcha.current = await token;
    }
  };

  const handleCallbackResponse = async (response) => {
    const userObject = jwt_decode(response.credential);

    const logIn = await signIn(
      {
        email: userObject.email,
        password: userObject.aud.slice(0, 13),
      },
      true
    );

    if (logIn.status === 400) {
      const [user, signUpUser] = await Api.signUp(
        userObject.email,
        userObject.aud.slice(0, 13)
      );

      signUpUser?.status === 400 && setRequestError(signUpUser);

      ApiNewsLetter.postNewsletterEmail({ email: userObject.email });
      await Api.verifyUser(user?.uuid);
      await signIn({
        email: userObject.email,
        password: userObject.aud.slice(0, 13),
      });
    }
  };
  useEffect(() => {
    /*global google */
    const handleClickGoogle = () => {
      try {
        window.google.accounts.id.initialize({
          client_id: process.env.REACT_APP_ID_OAUTH_GOOGLE,
          callback: handleCallbackResponse,
        });
        window.google.accounts.id.renderButton(
          document.getElementById("oauthGoogle"),
          {
            theme: "outline",
            size: "large",
            text: "signin_with",
            shape: "circle",
            width: 100,
          }
        );
      } catch (e) {
        captureException(e);
      }
    };
    window.google && handleClickGoogle();
  }, []);

  return (
    <div
      className={props.isModal ? "log-container log-modal" : "log-container"}>
      <div className="log-logo">
        <img alt="logo" src={logo} />
      </div>
      <div className="log-content-container">
        <EditButton
          text={"inicio"}
          icon="ic:round-navigate-before"
          onClick={() =>
            (window.location.href = process.env.REACT_APP_CURRENT_URL)
          }
        />
        <div>
          <h2 className="title-login">{t("signIn.sectionTitle")}</h2>

          <form onSubmit={handleSubmit(signIn)} className="log-form">
            <div style={{ display: requestError !== null ? "block" : "none" }}>
              <span className="error-singIn">{t("signIn.error")}</span>
            </div>
            <div className="info-field">
              <label className="info-label" htmlFor="event_name">
                {t("general.mail")}
              </label>
              <div
                id="info-input"
                className={
                  !errors?.email && watch().email?.length
                    ? " input-ok info-input-container"
                    : "  info-input-container"
                }>
                <input
                  type="text"
                  autoComplete="username"
                  placeholder={t("signIn.emailPlaceholder")}
                  {...register("email", {
                    required: true,
                    pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                  })}
                  className={
                    errors?.email
                      ? "error input email-signin"
                      : "input email-signin"
                  }
                />

                {errors.email && <span className="error-point"></span>}
                {errors.email && (
                  <span className="error-text">{t("signIn.emailError")}</span>
                )}
              </div>
            </div>
            <div className="info-field">
              <label className="info-label" htmlFor="event_name">
                {t("signIn.password")}
              </label>
              <div
                id="info-input"
                className={
                  !errors?.password && watch().password?.length
                    ? "  info-input-container input-password"
                    : "info-input-container input-password"
                }>
                <input
                  type={showPassword ? "password" : "text"}
                  autoComplete="current-password"
                  placeholder={t("signIn.passwordPlaceholder")}
                  {...register("password", {
                    required: true,
                    minLength: 2,
                    maxLength: 15,
                  })}
                  className={errors?.password ? "error input" : "input"}
                />
                <span
                  onClick={() => setShowPassword(!showPassword)}
                  className="show-password">
                  {showPassword ? (
                    <Icon icon="fluent:eye-16-filled" />
                  ) : (
                    <Icon icon="fluent:eye-off-16-filled" />
                  )}
                </span>
                {errors.password && <span className="error-point"></span>}
                {errors.password?.type === "required" && (
                  <span className="error-text">
                    {t("signIn.passwordError")}
                  </span>
                )}
              </div>
            </div>
            <div className="captcha">
              <p className="captcha-policy">
                {t("general.captcha1")}{" "}
                <a href="https://policies.google.com/privacy">
                  {t("general.policy")}
                </a>{" "}
                {t("general.and")}{" "}
                <a href="https://policies.google.com/terms">
                  {t("general.terms")}
                </a>{" "}
                {t("general.captcha2")}
              </p>
              {errors?.errorCaptcha && (
                <span className="error-text">
                  {errors.errorCaptcha.message}
                </span>
              )}
              <GoogleReCaptcha onVerify={handleVerify} />
            </div>
            <div className="forgot-password">
              <a onClick={() => setOpenModalRecovery(!openModalRecovery)}>
                {t("signIn.recovery")}
              </a>
            </div>

            <div className="log-button">
              <Button
                buttonType="submit"
                type="primary"
                text={t("signIn.signIn")}
              />
            </div>
          </form>

          <div className="oauthGoogle-container">
            <div className="separation-oauthGoogle">
              <p> {t("general.logEmail")}</p>
            </div>
            <div id="oauthGoogle"></div>
          </div>
          <div className="singIn-singUp forgot-password">
            <p>{t("signIn.hasAccount")}</p>
            <Icon icon="akar-icons:arrow-right" />
            {!props.isModal ? (
              <Link to="/sign-up">{t("signIn.signUp")}</Link>
            ) : (
              <button onClick={() => props.handleModal && props.handleModal()}>
                {t("signIn.signUp")}
              </button>
            )}
          </div>
        </div>
        <Modal
          onOpen={openModalRecovery}
          setOnOpen={setOpenModalRecovery}
          buttonOn>
          <RecoveryPassword setOnOpen={setOpenModalRecovery} />
        </Modal>
      </div>
    </div>
  );
}
export default SignIn;
