import { useState } from "react";
import CheckBoxButton from "../general/CheckBoxButton";
function MultiSelectForm(
  props = { possibilities: [], answers: [], guestId: "" }
) {
  const [required, setRequired] = useState(props.question.required);

  return (
    <div>
      {props.possibilities.map((possibility) => {
        const answer = props.answers.filter(
          (answers) => Number(possibility.id) === Number(answers.option)
        );

        const handleClick = async (e) => {
          const resultAnswer = await props.modifyMulti(
            possibility.question,
            possibility.id,
            answer.length > 0 ? answer.id : null
          );

          setRequired(
            resultAnswer.length > 0 ? false : props.question.required
          );
        };

        return (
          <div key={possibility.uuid} className="invitation-question-checkbox">
            <CheckBoxButton
              id={`${possibility.uuid}${props.guestId}`}
              type="checkbox"
              onClick={handleClick}
              name={`${possibility.question}`}
              required={required}
              checked={answer.length > 0}
            />

            <label htmlFor={`${possibility.uuid}${props.guestId}`}>
              {possibility.text}
            </label>
          </div>
        );
      })}
    </div>
  );
}
export default MultiSelectForm;
