import SignOut from "../../components/users/signOut"

function SignOutPage() {


  return (
    <>
      <SignOut />
    </>
  );
}
export default SignOutPage;
