import { useState, useEffect } from "react";
import usePrevious from "./usePrevious";
import { useAppContext } from "../context/state";
import Api from "../services/pollsApi";

function useQuestions(props = {}) {
  const context = useAppContext();
  const [question, setQuestion] = useState(props.question.text);
  const [required, setRequired] = useState(props.question.required);
  const [groupQuestion, setGroupQuestion] = useState(
    props.question.is_group_question
  );

  const prevRequired = usePrevious(required);
  const prevGroupQuestion = usePrevious(groupQuestion);

  useEffect(() => {
    if (prevRequired !== undefined) {
      const questionInfo = {
        required: required,
      };
      makeQuestionRequest(questionInfo, props.question.uuid);
    }
  }, [required]);

  useEffect(() => {
    if (prevGroupQuestion !== undefined) {
      const questionInfo = {
        is_group_question: groupQuestion,
      };
      makeQuestionRequest(questionInfo, props.question.uuid);
    }
  }, [groupQuestion]);

  const makeQuestionRequest = async (questionInfo, questionUuid) => {
    const response = await Api.changeQuestion(
      questionInfo,
      context.sharedState.invitation.uuid,
      questionUuid
    );
    const temporal = context.sharedState.questions.map((temp) => {
      if (temp.uuid === questionUuid) {
        return { ...temp, ...questionInfo };
      } else {
        return temp;
      }
    });

    context.setSharedState({
      ...context.sharedState,
      questions: temporal,
    });
  };

  const changeText = (event, questionUuid) => {
    const questionInfo = {
      text: event.target.value,
    };
    makeQuestionRequest(questionInfo, questionUuid);
  };

  return [
    required,
    setRequired,
    question,
    setQuestion,
    groupQuestion,
    setGroupQuestion,
    changeText,
  ];
}
export default useQuestions;
