import Api from "../../services/usersApi";
import "../../assets/css/verifiyingAccount.css";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Button from "../general/Button";
import { t } from "i18next";
import VerifyAccount from "./verifyAccount";
import Spinner from "../general/Spinner";

const VerifiyingAccount = () => {
  const [hasErrors, setHasErrors] = useState(false);
  const navigate = useNavigate();
  const { uuid } = useParams();

  const changeVerified = async () => {
    try {
      await Api.verifyUser(uuid);
      setTimeout(() => {
        navigate("/my-account/my-events");
      }, 2000);
    } catch (err) {
      setHasErrors(true);
    }
  };

  useEffect(() => {
    changeVerified();
  }, []);

  return (
    <div className="verifiyingAccount__main container creation-board">
      {hasErrors ? (
        <>
          <p className="verifiyingAccount__title">
            {t("verifyAccount.titleBad")}
          </p>
          <Button
            text={t("verifyAccount.buttonText")}
            type={"primary"}
            onClick={() => navigate("/")}
          />
        </>
      ) : (
        <>
          <p className="verifiyingAccount__title">
            {t("verifyAccount.titleGood")}
          </p>
          <Spinner />
        </>
      )}
    </div>
  );
};

export default VerifiyingAccount;
