import "../../assets/css/radioButton.css";

const RadioButton = ({
  name,
  id,
  checked,
  onClick,
  required,
  defaultChecked,
  value,
  className,
}) => {
  return (
    <div className="radio-container">
      <input
        type="radio"
        className={`radio-input ${className}`}
        id={id}
        defaultValue={value}
        name={name}
        checked={checked}
        onChange={(e) => onClick(e)}
        onInput={onClick}
        {...required}
        defaultChecked={defaultChecked}
      />
      <label htmlFor={id} className="radio-label" id="radio-label"></label>
    </div>
  );
};
export default RadioButton;
