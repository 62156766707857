import {generateApiToken} from "./utils";

class ApiNewsLetter {
  static async postNewsletterEmail(payload) {
    if (process.env.REACT_APP_ENV === "pro") {
      payload.token = generateApiToken();
      const url = `${process.env.REACT_APP_API2_URL}newsletter/`;
      const config = {
        method: "POST",
        mode: "no-cors",
        body: JSON.stringify(payload),
        headers: { "Content-type": "application/json; charset=UTF-8" },
      };
      let newsLetter;
      try {
        const response = await fetch(url, config);
        newsLetter = response;
      } catch (error) {
        return error;
      }
      return newsLetter;
    }
  }
}
export default ApiNewsLetter;
