import "../../assets/css/informationBoard.css";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAppContext } from "../../context/state";
import Api from "../../services/invitationsApi";
import SaveButtonTwo from "../general/SaveButtonTwo";
import { useTranslation } from "react-i18next";
import SaveButton from "../general/saveButton";
import Datalayer from "../../services/datalayer";
import ToggleButton from "../general/ToggleButton.js";
import { Icon } from "@iconify/react";
import AutocompleteInput from "./inputAddress";

function InformationBoard() {
  const [saveError, setIsSaveError] = useState();
  const context = useAppContext();
  const invitation = context.sharedState?.invitation;
  const { t } = useTranslation();
  const [infoEvent, setInfoEvent] = useState({});
  const [enableMap, setEnableMap] = useState(invitation?.has_map);
  const [numberInvitationHover, setNumberInvitationHover] = useState(false);

  const [defaultInfo] = useState({
    ...invitation,
  });
  const [currentQr, setCurrentQr] = useState(
    context.sharedState.invitation.has_qr
  );

  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });

  const hasMapValue = watch("has_map");

  useEffect(() => {
    Datalayer.push("information_step");
  }, []);

  const localDate = () => {
    let date = new Date();
    const fullDate = `${date.getFullYear()}-${String(
      date.getUTCMonth() + 1
    ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}T${String(
      date.getHours()
    ).padStart(2, "0")}`;
    return fullDate;
  };

  const handleInput = (e) => {
    const data = e.target;
    if (data.id === "date") {
      setInfoEvent({
        ...infoEvent,
        date: `${data.value}T${watch().time ? watch().time : "00:00:00"}Z`,
      });
    } else if (data.id === "time") {
      setInfoEvent({
        ...infoEvent,
        date: `${watch().date}T${data.value}:00Z`,
      });
    } else if (data.id == "deadline_response") {
      setInfoEvent({
        ...infoEvent,

        deadline_response: `${data.value}T${
          watch().deadline_time ? watch().deadline_time : "00:00:00"
        }Z`,
      });
    } else if (data.id == "deadline_time") {
      setInfoEvent({
        ...infoEvent,
        deadline_response: `${watch().deadline_response}T${data.value}:00Z`,
      });
    } else if (data.id == "has_qr") {
      const hasQr = !currentQr;
      setCurrentQr(hasQr);
      setInfoEvent({ ...infoEvent, [data.id]: hasQr });
    } else if (data.id === "has_map") {
      setValue("has_map", Boolean(data.checked));
      setInfoEvent({ ...infoEvent, [data.id]: data.checked });
    } else {
      setInfoEvent({ ...infoEvent, [data.id]: data.value });
    }
  };
  /** Se modifican juntos has map y place address para evitar un cambio erroneo del estado al ejecutar dos handleinput a la vez y que uno de los dos no llegue a realizarse pro superposicion de estados */
  const cancelMap = (locationValue) => {
    const newDataEvent = infoEvent;
    setEnableMap(false);
    setValue("has_map", false);
    newDataEvent["has_map"] = false;
    newDataEvent["place_address"] = locationValue;
    setInfoEvent(newDataEvent);
  };

  const handleUrl = (e) => {
    const data = e.target;
    let url = data.value;
    const regexUrl =
      /^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;

    if (regexUrl.test(data.value)) {
      setInfoEvent({ ...infoEvent, [data.id]: url });
    } else {
      url = `https://${url}`;
      setInfoEvent({ ...infoEvent, [data.id]: url });
    }
  };

  const onSubmit = async (data) => {
    context.setSharedState({
      ...context.sharedState,
      invitation: { ...context.sharedState.invitation, ...infoEvent },
    });
    const response = await Api.changeInvitation(
      infoEvent,
      context.sharedState.invitation?.uuid
    );

    response === undefined ? setIsSaveError(true) : setIsSaveError(false);
  };
  return (
    <div className="info-board-container white-container">
      <div>
        <form onSubmit={handleSubmit(onSubmit)} className="container-form">
          <div className="save-button-poll">
            <SaveButton saveError={isValid ? saveError : true} />
          </div>
          <div className="info-form">
            <div className="info-personal-container">
              <h3>{t("information.personalInfo")}</h3>
              <div className="info-personal">
                <div className="info-field ">
                  <label
                    className="info-label input-required"
                    htmlFor="sender_name"
                  >
                    {t("information.hostName")}
                  </label>
                  <div
                    id="info-input"
                    className={
                      !errors?.sender_name && watch().sender_name?.length
                        ? " input-ok info-input-container"
                        : "  info-input-container"
                    }
                  >
                    <input
                      {...register("sender_name", {
                        required: true,
                        minLength: 2,
                        maxLength: 60,
                      })}
                      maxLength="100"
                      type="text"
                      id="sender_name"
                      className={errors?.sender_name ? "error input" : "input"}
                      defaultValue={defaultInfo.sender_name}
                      onInput={handleInput}
                    />

                    {errors?.sender_name && (
                      <span className="error-point"></span>
                    )}
                    {errors?.sender_name?.type === "required" && (
                      <span className="error-text">
                        {t("general.requiredField")}
                      </span>
                    )}
                  </div>{" "}
                </div>
              </div>
            </div>
            <div className="info-event-container">
              <h3>{t("information.eventInfo")}</h3>
              <div className="info-event">
                <div className="info-field">
                  <label
                    className="info-label input-required"
                    htmlFor="event_name"
                  >
                    {t("information.name")}
                  </label>
                  <div
                    id="info-input"
                    className={
                      !errors?.event_name && watch().event_name?.length
                        ? " input-ok info-input-container"
                        : "  info-input-container"
                    }
                  >
                    <input
                      {...register("event_name", {
                        required: true,
                        minLength: 2,
                        maxLength: 30,
                      })}
                      maxLength="200"
                      type="text"
                      id="event_name"
                      className={errors?.event_name ? "error input" : "input"}
                      defaultValue={defaultInfo.event_name}
                      onInput={handleInput}
                    />

                    {errors?.event_name && (
                      <span className="error-point"></span>
                    )}
                    {errors?.event_name?.type === "required" && (
                      <span className="error-text">
                        {t("general.requiredField")}
                      </span>
                    )}
                  </div>
                </div>

                <div className="info-field">
                  <label className="info-label input-required" htmlFor="date">
                    {t("information.date")}
                  </label>
                  <div
                    id="info-input"
                    className={
                      !errors?.date && watch().date?.length
                        ? " input-ok info-input-container"
                        : "  info-input-container"
                    }
                  >
                    <input
                      {...register("date", {
                        required: true,
                        minLength: 2,

                        min: localDate()?.slice(0, -3),
                      })}
                      type="date"
                      id="date"
                      min={localDate()?.slice(0, -3)}
                      className={errors?.date ? "error input" : "input"}
                      defaultValue={defaultInfo.date?.slice(0, 10)}
                      onInput={handleInput}
                    />

                    {errors?.date && <span className="error-point"></span>}
                    {errors?.date?.type === "required" && (
                      <span className="error-text">
                        {t("general.requiredField")}
                      </span>
                    )}
                    {errors?.date?.type === "min" && (
                      <span className="error-text">
                        {t("information.incorrectDate")}
                      </span>
                    )}
                  </div>
                </div>

                <div className="info-field">
                  <label className="info-label" htmlFor="date">
                    {t("information.endDate")}
                  </label>
                  <div
                    id="info-input"
                    className={
                      !errors?.end_date && watch().end_date?.length
                        ? " input-ok info-input-container"
                        : "  info-input-container"
                    }
                  >
                    <input
                      {...register("end_date", {
                        minLength: 2,
                        min: localDate()?.slice(0, -3),
                      })}
                      type="date"
                      id="end_date"
                      min={localDate()?.slice(0, -3)}
                      className={errors?.end_date ? "error input" : "input"}
                      defaultValue={defaultInfo.end_date?.slice(0, 10)}
                      onInput={handleInput}
                    />

                    {errors?.end_date && <span className="error-point"></span>}
                    {errors?.end_date?.type === "min" && (
                      <span className="error-text">
                        {t("information.incorrectDate")}
                      </span>
                    )}
                  </div>
                </div>

                <div className="info-field">
                  <label className="info-label" htmlFor="date">
                    {t("information.time")}
                  </label>
                  <div
                    id="info-input"
                    className={
                      !errors?.time && watch().time?.length
                        ? " input-ok info-input-container"
                        : "  info-input-container"
                    }
                  >
                    <input
                      {...register("time", {
                        // minLength: 2,
                      })}
                      type="time"
                      id="time"
                      className={errors?.time ? "error input" : "input"}
                      defaultValue={
                        new Date(defaultInfo.date).getTimezoneOffset()
                          ? defaultInfo.date?.slice(11, -1)
                          : "00:00:00"
                      }
                      onInput={handleInput}
                    />

                    {errors?.time && <span className="error-point"></span>}
                    {errors?.time?.type === "required" && (
                      <span className="error-text">
                        {t("general.requiredField")}
                      </span>
                    )}
                  </div>
                </div>

                <div className="info-field">
                  <label className="info-label" htmlFor="place_name">
                    {t("information.place")}
                  </label>
                  <div
                    id="info-input"
                    className={
                      !errors?.place_name && watch().place_name?.length
                        ? " input-ok info-input-container"
                        : "  info-input-container"
                    }
                  >
                    <input
                      {...register("place_name", {
                        // required: true,
                        minLength: 2,
                        maxLength: 100,
                      })}
                      maxLength="100"
                      type="text"
                      id="place_name"
                      className={errors?.place_name ? "error input" : "input"}
                      defaultValue={defaultInfo.place_name}
                      onInput={handleInput}
                    />

                    {errors?.place_name && (
                      <span className="error-point"></span>
                    )}
                    {errors?.place_name?.type === "required" && (
                      <span className="error-text">
                        {t("general.requiredField")}
                      </span>
                    )}
                  </div>
                </div>

                <div className="info-field">
                  <label className="info-label" htmlFor="place_address">
                    {t("information.address")}
                  </label>
                  <div
                    id="info-input"
                    className={
                      !errors?.place_address && watch().place_address?.length
                        ? " input-ok info-input-container"
                        : "  info-input-container"
                    }
                  >
                    <AutocompleteInput
                      name="place_address"
                      control={control} // control proviene de useForm()
                      defaultValue={defaultInfo?.place_address}
                      rules={{ minLength: 2, maxLength: 100 }}
                      errors={errors}
                      idInput="place_address"
                      inputFunction={handleInput}
                      cancelCheckMap={cancelMap}
                      handleLocation={setEnableMap}
                    />
                    {errors?.place_address && (
                      <span className="error-point"></span>
                    )}
                    {errors?.place_address?.type === "required" && (
                      <span className="error-text">
                        {t("general.requiredField")}
                      </span>
                    )}
                  </div>
                </div>
                <div className="info-field">
                  <label className="info-label" htmlFor="has_map">
                    {t("information.hasMap")}
                    <div id="info-input " className="map-input-container">
                      <input
                        {...register("has_map", {
                          value: invitation?.has_map,
                        })}
                        type="checkbox"
                        id="has_map"
                        className="map-input"
                        onChange={handleInput}
                        checked={hasMapValue || false}
                        disabled={!enableMap}
                      />
                      <div className="map-container--icon">
                        <Icon
                          icon="fa6-solid:check"
                          className="map-input--icon"
                        />
                      </div>
                    </div>
                  </label>
                </div>

                <div className="info-field">
                  <label className="info-label" htmlFor="virtual_place_url">
                    {t("information.website")}
                  </label>
                  <div
                    id="info-input"
                    className={
                      !errors?.virtual_place_url &&
                      watch().virtual_place_url?.length
                        ? " input-ok info-input-container"
                        : "  info-input-container"
                    }
                  >
                    <input
                      {...register("virtual_place_url", {
                        minLength: 2,
                        maxLength: 100,
                        pattern:
                          /[-a-zA-Z0-9@%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/,
                      })}
                      maxLength="100"
                      type="text"
                      id="virtual_place_url"
                      className={
                        errors?.virtual_place_url ? "error input" : "input"
                      }
                      defaultValue={defaultInfo.virtual_place_url}
                      onInput={handleUrl}
                    />

                    {errors?.virtual_place_url && (
                      <span className="error-point"></span>
                    )}
                    {errors?.virtual_place_url?.type === "pattern" && (
                      <span className="error-text">
                        {t("information.invalidUrl")}
                      </span>
                    )}
                    {errors?.virtual_place_url?.type === "minLength" && (
                      <span className="error-text">
                        {t("information.invalidUrl")}
                      </span>
                    )}
                    {errors?.virtual_place_url?.type === "required" && (
                      <span className="error-text">
                        {t("general.requiredField")}
                      </span>
                    )}
                  </div>
                </div>

                <div className="info-field">
                  <label className="info-label" htmlFor="date">
                    {t("information.deadline")}
                  </label>
                  <div
                    id="info-input"
                    className={
                      !errors?.deadline_response &&
                      watch().deadline_response?.length
                        ? " input-ok info-input-container"
                        : "  info-input-container"
                    }
                  >
                    <input
                      {...register("deadline_response", {
                        // required: true,

                        min: localDate()?.slice(0, -3),
                      })}
                      type="date"
                      id="deadline_response"
                      min={localDate()?.slice(0, -3)}
                      className={
                        errors?.deadline_response ? "error input" : "input"
                      }
                      defaultValue={defaultInfo.deadline_response?.slice(0, 10)}
                      onInput={handleInput}
                    />

                    {errors?.deadline_response && (
                      <span className="error-point"></span>
                    )}
                    {errors?.deadline_response?.type === "min" && (
                      <span className="error-text">
                        {t("information.dateError")}
                      </span>
                    )}
                  </div>
                </div>

                <div className="info-field">
                  <label className="info-label" htmlFor="date">
                    {t("information.deadlineTime")}
                  </label>
                  <div
                    id="info-input"
                    className={
                      !errors?.deadline_response &&
                      watch().deadline_response?.length
                        ? " input-ok info-input-container"
                        : "  info-input-container"
                    }
                  >
                    <input
                      {...register("deadline_time", {
                        // required: true,
                      })}
                      type="time"
                      id="deadline_time"
                      className={
                        errors?.deadline_time ? "error input" : "input"
                      }
                      defaultValue={
                        new Date(
                          defaultInfo.deadline_response
                        ).getTimezoneOffset()
                          ? defaultInfo.deadline_response?.slice(11, -1)
                          : "00:00:00"
                      }
                      onInput={handleInput}
                    />
                    {errors?.deadline_time && (
                      <span className="error-point"></span>
                    )}
                    {errors?.deadline_time?.type === "required" && (
                      <span className="error-text">
                        {t("information.requiredField")}
                      </span>
                    )}
                  </div>
                </div>
              </div>{" "}
              <div className="info-field qr-container">
                <label className="info-label" htmlFor="date">
                  {t("information.hasQr")}
                </label>
                <div id="info-input" className="input-qr">
                  <ToggleButton
                    type="checkbox"
                    id="has_qr"
                    // className="input qr-input"
                    onClick={handleInput}
                    checked={currentQr}
                  />
                  <div
                    className={`info-qr-container ${
                      currentQr && "qr-activate"
                    }`}
                  >
                    <Icon icon="fluent:qr-code-24-regular" />
                    <p>{t("information.hascard")}</p>
                    <div
                      className="popUp"
                      onMouseEnter={() => setNumberInvitationHover(true)}
                      onMouseLeave={() => setNumberInvitationHover(false)}
                    >
                      <div className="popUp-icon-container">
                        <p className="popUp-icon">?</p>
                      </div>
                      <div
                        className={`hover-popUp popUp-hover-${numberInvitationHover}`}
                      >
                        <p className="hover-popUp-display">
                          {t("information.hasPopUp")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="info-event-container">
              <h3>{t("information.senderInfo")}</h3>
              <div className="info-event">
                <div className="info-field">
                  <label
                    className="info-label input-required"
                    htmlFor="email_topic"
                  >
                    {t("information.topic")}
                  </label>
                  <div
                    id="info-input"
                    className={
                      !errors?.email_topic && watch().email_topic?.length
                        ? " input-ok info-input-container"
                        : "  info-input-container"
                    }
                  >
                    <input
                      {...register("email_topic", {
                        required: true,
                        maxLength: 50,
                      })}
                      maxLength="100"
                      type="text"
                      id="email_topic"
                      className={errors?.email_topic ? "error input" : "input"}
                      name="email_topic"
                      defaultValue={defaultInfo.email_topic}
                      onInput={handleInput}
                    />

                    {errors?.email_topic && (
                      <span className="error-point"></span>
                    )}
                    {errors?.email_topic?.type === "required" && (
                      <span className="error-text">
                        {t("general.requiredField")}
                      </span>
                    )}
                    {errors?.email_topic?.type === "minLength" && (
                      <span className="error-text">
                        {t("information.errorMax")}
                      </span>
                    )}
                  </div>
                </div>
                {/* <div className=" info-field">
                  <label className="info-label" htmlFor="email_title">
                    {t("information.title")}
                  </label>
                  <div
                    id="info-input"
                    className={
                      !errors?.email_title && watch().email_title?.length
                        ? " input-ok info-input-container"
                        : "  info-input-container"
                    }
                  >
                    <input
                      {...register("email_title", {
                        minLength: 5,
                        maxLength: 100,
                      })}
                      maxLength="25"
                      type="text"
                      id="email_title"
                      className={errors?.email_title ? "error input" : "input"}
                      defaultValue={defaultInfo.email_title}
                      onInput={handleInput}
                    />

                    {errors?.email_title && (
                      <span className="error-point"></span>
                    )}
                    {errors?.email_title?.type === "minLength" && (
                      <span className="error-text">
                        {t("information.errorMin")}
                      </span>
                    )}
                    {errors?.email_title?.type === "maxLength" && (
                      <span className="error-text">
                        {t("information.errorMax")}
                      </span>
                    )}
                  </div>
                </div> */}
                <div className="info-field info-textarea ">
                  <label className="info-label" htmlFor="email_content">
                    {t("information.emailMessage")}
                  </label>
                  <div
                    id="info-input"
                    className={
                      !errors?.email_content && watch().email_content?.length
                        ? " input-ok info-input-container"
                        : "  info-input-container"
                    }
                  >
                    <textarea
                      {...register("email_content", {
                        minLength: 5,
                        maxLength: 255,
                      })}
                      maxLength="255"
                      id="email_content"
                      className={
                        errors?.email_content ? "error input" : "input"
                      }
                      defaultValue={defaultInfo.email_content}
                      onInput={handleInput}
                    />

                    {errors?.email_content && (
                      <span className="error-point"></span>
                    )}
                    {errors?.email_content?.type === "minLength" && (
                      <span className="error-text">
                        {t("information.errorMin")}
                      </span>
                    )}
                    {errors?.email_content?.type === "maxLength" && (
                      <span className="error-text">
                        {t("information.contentMax")}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="submit-list-group-button"></div>
          <SaveButtonTwo saveError={isValid ? saveError : true} />
        </form>
      </div>
    </div>
  );
}
export default InformationBoard;
