import { useState, useEffect } from "react";
import { useAppContext } from "../../../context/state";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { Button } from "@blueprintjs/core";
import { ImagesGrid } from "polotno/side-panel";
import { getImageSize } from "polotno/utils/image";
import { resizeBase64Img, localFileToURL } from "../../../services/utils";
import Modal from "../../general/modal";
import { Icon } from "@iconify/react";

export const UploadPanel = observer(({ store, setUploadImages }) => {
  const [images, setImages] = useState([]);
  const [currentImages, setCurrentImages] = useState([]);
  const [isFirstImage, setIsFirstImage] = useState(true);
  const [modal, setModal] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [isUploading, setUploading] = useState(false);
  const context = useAppContext();
  const { t } = useTranslation();

  const load = async () => {
    setCurrentImages(images);
    setUploadImages(() => [...images]);
  };

  useEffect(() => {
    if (isUploading) {
      setUploading(false);
    }
  }, [modal, modalError]);

  const handleFileInput = async (e) => {
    if (!isFirstImage && context.sharedState.isTrying) {
      setModal(true);
      return;
    }

    const MAX_SIZE = 880;
    const MAX_WEIGTH = 30000000;
    const ACCEPTED_TYPES = ["image/jpeg", "image/png", "image/svg+xml"];
    const { target } = e;
    setUploading(true);
    for (const file of target.files) {
      if (!ACCEPTED_TYPES.includes(file.type) || file.size > MAX_WEIGTH) {
        setModalError(true);
        return;
      }
      const b64img = await localFileToURL(file);
      let newImage = new Image();

      newImage.onload = async function () {
        let width = newImage.width;
        let height = newImage.height;

        if (width > MAX_SIZE || height > MAX_SIZE) {
          if (width > height && width > MAX_SIZE) {
            const ratio = height / width;
            height = MAX_SIZE * ratio;
            width = MAX_SIZE;
          }

          if (width < height && height > MAX_SIZE) {
            const ratio = width / height;
            width = MAX_SIZE * ratio;
            height = MAX_SIZE;
          }
        }

        const resizedImage = await resizeBase64Img(b64img, width, height);
        let newImages = images;
        newImages.push({
          source: resizedImage,
        });
        setImages(newImages);
        load();
      };
      newImage.src = b64img;
      setUploading(false);
      setIsFirstImage(false);
      target.value = null;
    }
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ marginBottom: "20px" }}>
          <label htmlFor="input-file">
            <Button
              icon="upload"
              style={{ width: "100%" }}
              onClick={() => {
                document.querySelector("#input-file")?.click();
              }}
              loading={isUploading}
            >
              {t("design.uploadFile")}
            </Button>
            <input
              type="file"
              id="input-file"
              style={{ display: "none" }}
              onChange={handleFileInput}
              multiple
            />
          </label>
        </div>
        {/* solo para Seccion Upload <ImagesGrid
          images={currentImages}
          getPreview={(image) => image.source}
          crossOrigin="anonymous"
          onSelect={async (image, pos, element) => {
            let { width, height } = await getImageSize(image.source);

            while (width > store.width || height > store.height) {
              width *= 0.8;
              height *= 0.8;
            }

            const x = (pos?.x || store.width / 2) - width / 2;
            const y = (pos?.y || store.height / 2) - height / 2;
            store.activePage.addElement({
              type: image.type === "1" ? "svg" : "image",
              src: image.source,
              width,
              height,
              x,
              y,
            });
          }}
          rowsNumber={2}
        /> */}
      </div>
      <Modal onOpen={modal} setOnOpen={setModal} buttonOn>
        <div className="modal-max-draft modal-max-draft-error">
          <Icon icon="mdi:close-circle" />
          <p>{t("design.onlyOneImage")}</p>
          <Button
            text={t("alertUx.errorButton")}
            onClick={() => setModal(false)}
          ></Button>
        </div>
      </Modal>
      <Modal onOpen={modalError} setOnOpen={setModalError} buttonOn>
        <div className="modal-max-draft modal-max-draft-error">
          <Icon icon="mdi:close-circle" />
          <p>{t("design.errorTypeOrSize")}</p>
          <Button
            text={t("design.errorButton")}
            onClick={() => setModalError(false)}
          ></Button>
        </div>
      </Modal>
    </>
  );
});
