import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clock from "../../assets/img/icons/clock.svg";
import map from "../../assets/img/icons/map.svg";
import backgroundDate from "../../assets/img/eventsDashboard/backgroundDate.svg";
import { Icon } from "@iconify/react";
import MoreOptions from "../general/moreOptions";
import { useAppContext } from "../../context/state";
import EditButton from "../general/editButton";
import { useState } from "react";
import Api from "../../services/invitationsApi";

function InvitationInformation({ invitationData }) {
  const { t } = useTranslation();
  const evenDate = new Date(invitationData.date);
  const context = useAppContext();
  const updated = new Date(invitationData.updated);
  const [moreOptions, setMoreOptions] = useState(false);
  const navigate = useNavigate();
  const deleteInvitation = async (data) => {
    const response = await Api.changeInvitation(
      { is_deleted: true },
      data.uuid
    );
    const draft = context.sharedState.invitations?.filter(
      (invitation) => invitation.uuid != response?.uuid
    );

    setMoreOptions(!moreOptions);
    context.setSharedState({
      ...context.sharedState,
      invitations: draft,
    });
  };

  return (
    <div className="invitation-info">
      <div className="invitation-info-preview">
        <div className="invitation-container-json template template-big" style={{height: "auto"}}>
          <img
            className="invitation-preview"
            src={invitationData.image}
            onClick={() =>
              navigate(`/invitation-laboratory/${invitationData.uuid}/`)
            }
          />
        </div>
      </div>
      <div className="invitation-info-date-draw">
        <img className="invitation-info-img-draw" src={backgroundDate} />
        <p className="invitation-info-month-draw">
          {evenDate.toLocaleDateString(localStorage.i18nextLng, {
            month: "long",
          })}
        </p>
        <p className="invitation-info-string-day-draw">{evenDate.getDate()}</p>
        <p className="invitation-info-day-draw">
          {evenDate.toLocaleDateString(localStorage.i18nextLng, {
            weekday: "long",
          })}
        </p>
      </div>
      <div className="invitation-info-text">
        <h4>{invitationData.event_name}</h4>
        <p className="invitation-info-updated">{`${t(
          "events.lastModification"
        )}: ${updated.toLocaleDateString(localStorage.i18nextLng, {
          year: "numeric",
          month: "long",
          day: "numeric",
        })}`}</p>
        <div className="invitation-info-other-data">
          <div className="invitation-info-other-items">
            <img src={clock} />
            <p>{`${evenDate.getUTCHours()}:${evenDate
              .getMinutes()
              .toString()
              .padStart(2, "0")} horas`}</p>
          </div>

          <div className="invitation-info-other-items">
            <img src={map} />
            <p>{invitationData.place_address}</p>
          </div>
        </div>
        <div className="invitation-info-edit">
          <EditButton
            color="#6B3378"
            icon="fluent:edit-16-filled"
            text={t("events.editEvent")}
            className="invitation-edit-button"
            onClick={() =>
              navigate(`/invitation-laboratory/${invitationData.uuid}/`)
            }
          />

          <div>
            <Icon
              onClick={() => setMoreOptions(!moreOptions)}
              icon="akar-icons:more-vertical-fill"
              color="#6b3378"
            />
            <MoreOptions openOptions={moreOptions} setOptions={setMoreOptions}>
              <div className="more-options-container">
                <EditButton
                  color="#364F6B"
                  icon="heroicons:trash-solid"
                  text={t("events.removeEvent")}
                  colorText="#364F6B"
                  onClick={() => deleteInvitation(invitationData)}
                />
              </div>
            </MoreOptions>
          </div>
        </div>
      </div>
    </div>
  );
}
export default InvitationInformation;
