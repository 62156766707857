import { useState } from "react";

function EmailForm(props = { value: "" }) {
  const [email, setEmail] = useState(props.value);

  return (
    <div className="info-input-container">
      <input
        type="email"
        className="input"
        onChange={(event) => {
          setEmail(event.target.value);
          props.modifyForm(props.question.id, event.target.value);
        }}
        value={email}
        required={props.question.required}
      />
    </div>
  );
}
export default EmailForm;
