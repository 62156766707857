import { useState } from "react";

export const useLoadingFetch = ( fetchFunction ) => {
    const [loading, setLoading] = useState(false)
    const fetch = async (...props)=> {
        setLoading(true)
        try{
            const response = await fetchFunction(...props)
            return response
        }catch(e){
            throw e
        }finally{
            setLoading(false)
        }   
    }
    return {loading, fetch}
}