import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../context/state";
import Api from "../../services/paymentsApi";
import Datalayer from "../../services/datalayer";
import UsersApi from "../../services/usersApi";
import kolibirdSuccess from "../../assets/img/kolibirdSuccess.jpg";
import "../../assets/css/paymentSuccess.css";

function PaymentSuccess(props = { toUrl: "/my-account/my-events" }) {
  const [hasError, setHasError] = useState(false);
  const { t } = useTranslation();
  const context = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    const confirmPayment = async () => {
      const isCorrect = await Api.confirmPayment(props.paymentUuid);
      if (isCorrect) {
        Datalayer.push("payment_ok");
        setTimeout(async () => {
          const user = await UsersApi.getCurrentUser();
          context.setUser(user);
          navigate(props.toUrl, { replace: false });
        }, 5000);
      } else {
        setHasError(true);
      }
    };

    confirmPayment();
  }, []);

  return (
    <div className="paymentsuccess__main container creation-board">
      <div className="paymentsuccess__body">
        <div className="paymentsuccess__frame">
          <img
            className="paymentsuccess__frame-photo"
            src={kolibirdSuccess}
            alt="Perfil Photo"
          />
        </div>
        <p className="paymentsuccess__title">{t("payment.buyThanks")}</p>
        <p className="paymentsuccess__subtitle">{t("payment.correctOrder")}</p>
      </div>
      {!hasError && <p>{t("payment.success")}</p>}
      {hasError && <p>{t("payment.confirmError")}</p>}
    </div>
  );
}
export default PaymentSuccess;
