import axios from "axios";
import {generateApiToken} from "./utils";

class TranslationsApi {
  static async translate(text, language){
    const token = generateApiToken();
    const payload = {
      token: token,
      text: text,
      language: language
    };
    let results;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API2_URL}translation/`,
        payload
      );
      results = response.data.body;
    } catch (error) {
      return [];
    }
    return results;
  }
}

export default TranslationsApi;
