import axios from "axios";
import UsersApi from "./usersApi";
import { generateApiToken } from "./utils";
import TranslationsApi from "./translationsApi";
import { defaultLanguage } from "../constants/languages";
import { captureException } from "@sentry/react";

class Api {
  static getConfig() {
    return {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    };
  }

  static getConfigNoAuth() {
    return {
      headers: {
        token: generateApiToken(),
      },
    };
  }

  static async getInvitations() {
    const url = `/invitations/`;

    const config = Api.getConfig();

    let invitations = [];
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        config
      );
      invitations = response.data;
    } catch (error) {
      await UsersApi.refreshToken();
      return error.response.data.error;
    }
    return invitations;
  }

  static async getInvitationsGroups(id) {
    const url = `/invitations/${id}/groups/`;

    const config = Api.getConfig();

    let invitations;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        config
      );
      invitations = response.data;
    } catch (error) {
      if (error.code !== 404)
        captureException({ error: error?.message, header: config.headers });
      await UsersApi.refreshToken();
      return error.response.data.error;
    }
    return invitations;
  }

  static async getInvitation(id) {
    const url = `/invitations/${id}/`;

    const config = Api.getConfig();

    let invitation;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        config
      );
      invitation = response.data;
    } catch (error) {
      if (error.code !== 404)
        captureException({ error: error?.message, header: config.headers });
      await UsersApi.refreshTo;
      return error.response.data.error;
    }
    return invitation;
  }

  static async getInvitationPreview(id) {
    const url = `/invitations/group/${id}/`;

    const config = Api.getConfigNoAuth();

    let invitation;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        config
      );
      invitation = response.data;
    } catch (error) {
      if (error.code !== 404)
        captureException({ error: error?.message, header: config.headers });
      return error.response.data.error;
    }
    return invitation;
  }

  static async createSignUpInvitation(payload) {
    const url = `/invitations/sign-up/`;

    const config = Api.getConfigNoAuth();

    try {
      await axios.post(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        payload,
        config
      );
    } catch (error) {}
  }

  static async createInvitation(payload) {
    const url = `/invitations/`;

    const config = Api.getConfig();

    let invitation;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        payload,
        config
      );
      invitation = response.data;
    } catch (error) {
      await UsersApi.refreshToken();
      return error.response.data.error;
    }
    return invitation;
  }

  static async changeInvitation(payload, id) {
    const url = `/invitations/${id}/`;

    const config = Api.getConfig();

    let invitation;
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        payload,
        config
      );

      invitation = response.data;
      return invitation;
    } catch (error) {
      await UsersApi.refreshToken();
      return error.response.data.error;
    }
  }

  static async getInvitationConfigs() {
    const url = `/invitations/configs/`;

    const config = Api.getConfigNoAuth();

    let invitationConfigs;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        config
      );
      invitationConfigs = response.data;
    } catch (error) {
      await UsersApi.refreshToken();
      return error.response.data.error;
    }
    return invitationConfigs;
  }

  static async getTemplates(filters = {}) {
    const queryParams = Object.keys(filters)
      .map((key) => `${key}=${filters[key]}`)
      .join("&");
    const url = `/invitations/templates/?${queryParams}`;
    const config = Api.getConfig();

    let templates;
    let totalPages;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        config
      );
      templates = response.data.results;
      totalPages = Math.ceil(response.data.count / 8);
    } catch (error) {
      if (error.response.status !== 404)
        captureException({ error: error?.toJson(), config });
      // return [error.response.error, null];
      return [[], null];
    }
    return [templates, totalPages];
  }

  static async getTemplate(templateId) {
    const url = `/invitations/templates/${templateId}/`;

    const config = Api.getConfigNoAuth();

    let template;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        config
      );
      template = response.data;
    } catch (error) {
      if (error.code !== 404)
        captureException({ error: error?.message, header: config.headers });
      return error.response.error;
    }
    return template;
  }

  static async getFilterTags() {
    const url = "/invitations/template-tags/";

    const config = Api.getConfigNoAuth();

    let filterTags;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        config
      );
      filterTags = response.data;
    } catch (error) {
      if (error.code !== 404)
        captureException({ error: error?.message, header: config.headers });
      return error.response.error;
    }
    return filterTags;
  }

  static async getFilterTagTypes() {
    const url = "/invitations/template-tag-types/";

    const config = Api.getConfigNoAuth();

    let filterTagTypes;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        config
      );
      filterTagTypes = response.data;
    } catch (error) {
      return error.response.error;
    }
    return filterTagTypes;
  }

  static async getAssets(filters = {}) {
    const queryParams = Object.keys(filters)
      .map((key) => `${key}=${filters[key]}`)
      .join("&");
    const url = `/invitations/images/?${queryParams}`;

    const config = Api.getConfigNoAuth();

    let assets;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        config
      );
      assets = response.data;
    } catch (error) {
      return error.response.error;
    }
    return assets;
  }

  static async getGuests(idGroup) {
    const url = `/invitations/groups/${idGroup}/guests/`;
    const config = Api.getConfigNoAuth();
    let guests;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        config
      );
      guests = response.data;
    } catch (error) {
      return error.response.error;
    }
    return guests;
  }

  static async getGuestsResponse(idGroup) {
    const url = `/invitations/groups/${idGroup}/guests/response/`;

    const config = Api.getConfigNoAuth();

    let guests;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        config
      );
      guests = response.data;
    } catch (error) {
      return error.response.error;
    }
    return guests;
  }

  static async changeGuest(payload, groupUuid) {
    const url = `/invitations/groups/${groupUuid}/guests/${payload.uuid}/`;

    const config = Api.getConfig();

    let guest;
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        payload,
        config
      );

      guest = response.data;
      return guest;
    } catch (error) {
      await UsersApi.refreshToken();
      return error.response.data.error;
    }
  }

  static async changeGuestResponse(payload, groupUuid) {
    const url = `/invitations/groups/${groupUuid}/guests/${payload.uuid}/response/`;

    const config = Api.getConfigNoAuth();

    let guest;
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        payload,
        config
      );
      guest = response.data;

      return guest;
    } catch (error) {
      return error.response.data.error;
    }
  }

  static async sendInvitations(payload, id) {
    const url = `/invitations/${id}/send-invitations/`;

    const config = Api.getConfig();

    let invitation;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        payload,
        config
      );
      invitation = response.data;
    } catch (error) {
      await UsersApi.refreshToken();
      return error.response.data.error;
    }
    return invitation;
  }

  static async getInvitationResults(payload) {
    const url = `/invitations/export-answers/`;
    let config = Api.getConfig();
    config.headers = {
      ...config.headers,
      ["Content-Type"]: "multipart/form-data",
    };
    let results;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        payload,
        config
      );
      results = response.data;
    } catch (error) {
      await UsersApi.refreshToken();
      return error.response.data.error;
    }
    return results;
  }

  static async getInvitationPrintResults(payload) {
    const url = `/invitations/print-answers/`;
    let config = Api.getConfig();
    config.headers = {
      ...config.headers,
      ["Content-Type"]: "multipart/form-data",
    };
    let results;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        payload,
        config
      );
      results = response.data;
    } catch (error) {
      await UsersApi.refreshToken();
      return error.response.data.error;
    }
    return results;
  }

  static async getThirdPartyImages(filters) {
    if (filters.section == 2) {
      const text = await TranslationsApi.translate(
        filters.search,
        defaultLanguage
      );
      filters.search = text;
    }

    let results;
    const token = generateApiToken();
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API2_URL}resources?search=${filters.search}&section=${filters.section}&token=${token}`
      );
      results = response.data;
    } catch (error) {
      return [];
    }

    return results;
  }
}
export default Api;
