export const totallyFree = "price_1t50nm3";

export const dollarsPerQuantity = {
  20: "21.77",
  40: "43.56",
  60: "64.26",
  80: "86.04",
  100: "108.92",
  120: "129.61",
  140: "151.39",
  160: "173.18",
  180: "194.96",
  200: "216.74",
  225: "238.52",
  250: "260.31",
  275: "282.09",
  300: "303.87",
};
