import "../../assets/css/popUpSave.css";

const popUpAlert = (state, text) => {
  const body = document.querySelector("#root");

  const node = document.createElement("div");

  if (state === true) {
    node.classList.add("invitation-save", "invitation-saved");
    node.innerHTML = `<p><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="#08c96c" d="m10 13.6l5.9-5.9q.275-.275.7-.275q.425 0 .7.275q.275.275.275.7q0 .425-.275.7l-6.6 6.6q-.3.3-.7.3q-.4 0-.7-.3l-2.6-2.6q-.275-.275-.275-.7q0-.425.275-.7q.275-.275.7-.275q.425 0 .7.275Z"/></svg></p>`;
  } else if (state === false) {
    node.classList.add("invitation-save", "invitation-no-save");
    node.innerHTML = `<p><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 256 256"><path fill="#f50a0a" d="M208.5 191.5a12 12 0 0 1 0 17a12.1 12.1 0 0 1-17 0L128 145l-63.5 63.5a12.1 12.1 0 0 1-17 0a12 12 0 0 1 0-17L111 128L47.5 64.5a12 12 0 0 1 17-17L128 111l63.5-63.5a12 12 0 0 1 17 17L145 128Z"/></svg></p>`;
  }

  body.appendChild(node);

  setTimeout(() => {
    body.removeChild(node);
  }, 20000);
};
export default popUpAlert;
