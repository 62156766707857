import { useEffect } from "react";
import EventsDashboard from "../../components/users/eventsDashboard";
import { useAppContext } from "../../context/state";
import useCheckLogin from "../../hooks/useCheckLogin";
import UsersServices from "../../services/usersServices";

function DashboardPage() {
    const {user} = useAppContext();
  const [checkLogin] = useCheckLogin();

    useEffect(() => {
      const check= async()=>{
        if(!user && UsersServices.isLogIn())
          await checkLogin();}
      check()
  }, []);
  return (
    <>
      {user && <EventsDashboard />}
    </>
  );
}
export default DashboardPage;
