import { useState } from "react";
import SignIn from "../users/signIn";
import SignUp from "../users/signUp";
export default function FormUserModal() {
  const [toggleForms, setToggleForms] = useState(false);

  const handleToggle = () => {
    setToggleForms((prev) => !prev);
  };

  return toggleForms ? (
    <SignIn isModal={true} toUrl="/my-account" handleModal={handleToggle} />
  ) : (
    <SignUp isModal={true} toUrl="/my-account" handleModal={handleToggle} />
  );
}
