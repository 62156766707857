import { Doughnut, Bar } from "react-chartjs-2";
import { Chart } from "chart.js/auto";
//Type can be Doughnut, otherwise Bar will be default and data is the data you want to make a Chart from.
function CustomChart({ type, data, chartLegend }) {
  const config = {
    labels: [...chartLegend],
    datasets: [
      {
        label: "Kolibird assistance Chart",
        data: data,
        backgroundColor: ["#6B3378", "#E33D8C", "#0AF5F0"],
        borderWidth: 0,
        width: "100%",
      },
    ],
  };
  return (
    <div className="chart-component">
      {type === "Doughnut" ? <Doughnut data={config} /> : <Bar data={config} />}
    </div>
  );
}
export default CustomChart;
