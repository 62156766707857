import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from "polotno";
import { setTranslations } from "polotno/config";
import { Toolbar } from "polotno/toolbar/toolbar";
import { ZoomButtons } from "polotno/toolbar/zoom-buttons";
import { SidePanel } from "polotno/side-panel";
import { Workspace } from "polotno/canvas/workspace";
import { useTranslation } from "react-i18next";
import "@blueprintjs/core/lib/css/blueprint.css";
import "../../../assets/css/polotno/polotnoWorkspace.css";
import PagesControl from "./pagesControl";
import { getTranslations } from "polotno/config";
import Spinner from "../../general/Spinner";

export const PolotnoWorkspace = (props) => {
  const store = props.store;
  const { t } = useTranslation();
  setTranslations({
    toolbar: {
      opacity: t("design.boardUI.toolbar.opacity"),
      effects: t("design.boardUI.toolbar.effects"),
      blur: t("design.boardUI.toolbar.blur"),
      brightness: t("design.boardUI.toolbar.brightness"),
      sepia: t("design.boardUI.toolbar.sepia"),
      grayscale: t("design.boardUI.toolbar.grayscale"),
      textStroke: t("design.boardUI.toolbar.textStroke"),
      shadow: t("design.boardUI.toolbar.shadow"),
      border: "Borde",
      cornerRadius: t("design.boardUI.toolbar.cornerRadius"),
      position: t("design.boardUI.toolbar.position"),
      layering: t("design.boardUI.toolbar.layering"),
      toForward: t("design.boardUI.toolbar.toForward"),
      up: t("design.boardUI.toolbar.up"),
      down: t("design.boardUI.toolbar.down"),
      toBottom: t("design.boardUI.toolbar.toBottom"),
      alignLeft: t("design.boardUI.toolbar.alignLeft"),
      alignCenter: t("design.boardUI.toolbar.alignCenter"),
      alignRight: t("design.boardUI.toolbar.alignRight"),
      alignTop: t("design.boardUI.toolbar.alignTop"),
      alignMiddle: t("design.boardUI.toolbar.alignMiddle"),
      alignBottom: t("design.boardUI.toolbar.alignBottom"),
      flip: t("design.boardUI.toolbar.flip"),
      flipHorizontally: t("design.boardUI.toolbar.flipHorizontally"),
      flipVertically: t("design.boardUI.toolbar.flipVertically"),
      fitToBackground: t("design.boardUI.toolbar.fitToBackground"),
      removeBackground: t("design.boardUI.toolbar.removeBackground"),
      cancelRemoveBackground: t(
        "design.boardUI.toolbar.cancelRemoveBackground"
      ),
      confirmRemoveBackground: t(
        "design.boardUI.toolbar.confirmRemoveBackground"
      ),
      crop: t("design.boardUI.toolbar.crop"),
      cropDone: t("design.boardUI.toolbar.cropDone"),
      cropCancel: t("design.boardUI.toolbar.cropCancel"),
      removeClip: t("design.boardUI.toolbar.removeClip"),
      removeMask: t("design.boardUI.toolbar.removeMask"),
      transparency: t("design.boardUI.toolbar.transparency"),
      lockedDescription: t("design.boardUI.toolbar.lockedDescription"),
      unlockedDescription: t("design.boardUI.toolbar.unlockedDescription"),
      removeElements: t("design.boardUI.toolbar.removeElements"),
      duplicateElements: t("design.boardUI.toolbar.duplicateElements"),
      download: t("design.boardUI.toolbar.download"),
      saveAsImage: t("design.boardUI.toolbar.saveAsImage"),
      saveAsPDF: t("design.boardUI.toolbar.saveAsPDF"),
      selectable: t("design.boardUI.toolbar.selectable"),
      draggable: t("design.boardUI.toolbar.draggable"),
      contentEditable: t("design.boardUI.toolbar.contentEditable"),
      styleEditable: t("design.boardUI.toolbar.styleEditable"),
      alwaysOnTop: t("design.boardUI.toolbar.alwaysOnTop"),
      showInExport: t("design.boardUI.toolbar.showInExport"),
    },
    workspace: {
      noPages: t("design.boardUI.workspace.noPages"),
      addPage: t("design.boardUI.workspace.addPages"),
      removePage: t("design.boardUI.workspace.removePages"),
      duplicatePage: t("design.boardUI.workspace.duplicatePage"),
      moveUp: t("design.boardUI.workspace.moveUp"),
      moveDown: t("design.boardUI.workspace.moveDown"),
    },
    scale: {
      reset: t("design.boardUI.scale.reset"),
    },
    error: {
      removeBackground: t("design.boardUI.error.removeBackground"),
    },
    sidePanel: {
      templates: t("design.boardUI.sidePanel.templates"),
      searchTemplatesWithSameSize: t(
        "design.boardUI.sidePanel.searchTemplatesWithSameSize"
      ),
      searchPlaceholder: t("design.boardUI.sidePanel.searchPlaceholder"),
      otherFormats: t("design.boardUI.sidePanel.otherFormats"),
      noResults: t("design.boardUI.sidePanel.noResults"),
      error: t("design.boardUI.sidePanel.error"),
      text: t("design.boardUI.sidePanel.text"),
      uploadFont: t("design.boardUI.sidePanel.uploadFont"),
      myFonts: t("design.boardUI.sidePanel.myFonts"),
      photos: t("design.boardUI.sidePanel.photos"),
      elements: t("design.boardUI.sidePanel.elements"),
      upload: t("design.boardUI.sidePanel.upload"),
      uploadImage: t("design.boardUI.sidePanel.uploadImage"),
      uploadTip: t("design.boardUI.sidePanel.uploadTip"),
      background: t("design.boardUI.sidePanel.background"),
      resize: t("design.boardUI.sidePanel.resize"),
      layers: t("design.boardUI.sidePanel.layers"),
      layersTip: t("design.boardUI.sidePanel.layersTip"),
      noLayers: t("design.boardUI.sidePanel.noLayers"),
      namePlaceholder: t("design.boardUI.sidePanel.namePlaceholder"),
      useMagicResize: t("design.boardUI.sidePanel.useMagicResize"),
      width: t("design.boardUI.sidePanel.width"),
      height: t("design.boardUI.sidePanel.height"),
      magicResizeDescription: t(
        "design.boardUI.sidePanel.magicResizeDescription"
      ),
      headerText: t("design.boardUI.sidePanel.headerText"),
      createHeader: t("design.boardUI.sidePanel.createHeader"),
      subHeaderText: t("design.boardUI.sidePanel.subHeaderText"),
      createSubHeader: t("design.boardUI.sidePanel.createSubHeader"),
      bodyText: t("design.boardUI.sidePanel.bodyText"),
      createBody: t("design.boardUI.sidePanel.createBody"),
    },
  });

  return (
    <div style={{ height: "calc(100% - 50px)" }}>
      <PolotnoContainer id="polotno-app-container">
        <div style={{ display: props.loading ? "none" : "contents" }}>
          <SidePanelWrap>
            <SidePanel store={store} sections={props.sidePanelSections} />
          </SidePanelWrap>

          <WorkspaceWrap>
            <Toolbar store={store} />

            <Workspace
              store={store}
              components={{ PageControls: () => null }}
              pageControlsEnabled={true}
            />
            <ZoomButtons store={store} />
          </WorkspaceWrap>
        </div>

        <div style={{ display: !props.loading ? "none" : "contents" }}>
          <Spinner typeColor={"purple"} />
        </div>
      </PolotnoContainer>
    </div>
  );
};
