import { useState } from "react";
import { useAppContext } from "../../context/state";
import { useTranslation } from "react-i18next";
import useQuestions from "../../hooks/useQuestions";
import CheckBoxButton from "../general/CheckBoxButton";
import Choice from "./choice";

function QuestionTerms(props = {}) {
  const { t } = useTranslation();
  const context = useAppContext();
  const [choices, setChoices] = useState(
    context.sharedState.questionsPossibilities.filter(
      (choice) => props.question.id === choice.question
    )
  );
  const [required, setRequired, question, setQuestion, , , changeText] =
    useQuestions(props);

  const title = t("poll.termsField");

  return (
    <>
      <h4 className="type-question">{title}</h4>

      <p className="number-question">
        {t("poll.question")} {props.index + 1}
      </p>
      <p className="add-response">
        Añade la url que dirige a tus términos y condiciones
      </p>
      <div className="info-input-container question-input-container">
        <input
          className="input"
          type="text"
          onChange={(event) => setQuestion(event.target.value)}
          onBlur={(event) => changeText(event, props.question.uuid)}
          value={question != "null" ? question : ""}
        />
      </div>
      <div className="checkbox-options">
        <div className="checkbox-option">
          <CheckBoxButton
            id={`obligatory-multi-checkbox-${props.index}`}
            type="checkbox"
            onClick={(event) => setRequired(event.target.checked)}
            checked={required}
          />
          <label>{t("poll.labelObligatory")}</label>
        </div>
        {/* <div className="checkbox-option">
          <CheckBoxButton
            id={`all-guests-multi-checkbox-${props.index}`}
            type="checkbox"
            onClick={(event) => setGroupQuestion(event.target.checked)}
            checked={groupQuestion}
          />
          <label>{t("poll.labelAllGuests")}</label>
        </div> */}
      </div>
      <div className="response-choice-container">
        {choices.map((choice, index) => {
          if (choice.question === props.question.id) {
            return (
              <Choice
                key={choice.uuid}
                index={index}
                choice={choice}
                setChoices={setChoices}
                questionType={props.question.type}
              />
            );
          }
        })}
      </div>
    </>
  );
}
export default QuestionTerms;
