import { useEffect, useState } from "react";
import { CardPiece } from "./internal-components/CardPiece";

export const JSONCard = ({
  card,
  isACard,
  className,
  guests,
  groupName,
  index,
}) => {
  const { dpi, height, pages, width } = card;
  const [sizeScreen, setSizeScreen] = useState(window.innerWidth);

  const finalStyles = {
    transform: isACard ? "rotate(90deg)" : "rotate(0deg)",
  };

  const prepareFonts = () => {
    const newLink = document.createElement("link");
    newLink.rel = "preconnect";
    newLink.href = `https://fonts.gstatic.com`;
    newLink.crossOrigin = true;
    document.head.append(newLink);
  };

  useEffect(() => {
    prepareFonts();
    window.addEventListener("resize", () => {
      setSizeScreen(window.innerWidth);
    });
  }, []);

  useEffect(() => {}, [sizeScreen]);

  return (
    <section
      dpi={dpi}
      className={`postcard--postal ${className}`}
      style={finalStyles}>
      {pages?.map((e, i) => {
        if (index == i) {
          const { background, bleed, children, id } = e;
          const childrenWithNames = children?.map((child) => {
            if (child.name === "guestName") {
              const newText = groupName || child.text;
              const result = { ...child, ["text"]: newText };
              return result;
            }
            return child;
          });
          const internalFrameStyles = {
            position: "relative",
            width: `${width}px`,
            height: `${height}px`,
            overflow: "hidden",
            backgroundColor: background,
          };
          return (
            <div
              className="postcard--size" /*esta es la clase que agregue*/
              id={id}
              bleed={bleed}
              style={internalFrameStyles}
              key={id}>
              {background.includes("https") ? (
                <img src={background} alt="background" />
              ) : null}

              {children
                ? childrenWithNames.map((e) => {
                    const { id } = e;
                    return <CardPiece element={e} key={id} store={card} />;
                  })
                : null}
            </div>
          );
        }
      })}
    </section>
  );
};
