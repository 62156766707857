/* REMOVE - FREE INVITATIONS CAMPAIGN */
import TryKolibirdFree from "../components/tryKolibirdFree";

function TryKolibirdFreePage() {
  return (
    <>
      <TryKolibirdFree />
    </>
  );
}
export default TryKolibirdFreePage;
/* REMOVE - FREE INVITATIONS CAMPAIGN */
