import { useEffect, useState } from "react";
import Api from "../../../services/invitationsApi";
import { ImagesGrid } from "polotno/side-panel/images-grid";
import SidePanelTextInput from "./SidePanelTextInput";
import backgorundLetter from "../../../assets/img/general/letter.png";

const TemplatesPanelSection = (props) => {
  const [search, setSearch] = useState("");
  const [templates, setTemplates] = useState([]);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(2);

  const getInputValue = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  const mergeTemplates = (myTemplates, newTemplates) => {
    const currentTemplates = myTemplates.concat(newTemplates);

    return currentTemplates.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.uuid === value.uuid)
    );
  };

  const getNewTemplates = async () => {
    let newTemplates = [];
    let payload = { page: page + 1 };
    if (search !== "") {
      payload["search"] = search;
    }

    let [currentTemplates, currentTotalPages] = await Api.getTemplates(payload);

    newTemplates = mergeTemplates(newTemplates, currentTemplates);
    setTotalPages(currentTotalPages);
    setTemplates(mergeTemplates(templates, newTemplates));
    setPage(payload.page);
  };

  useEffect(() => {
    setPage(0);
    setTotalPages(2);
    getNewTemplates();
  }, [search]);

  useEffect(() => {
    if (page < totalPages) {
      getNewTemplates();
    }
  }, [page]);

  const refreshUrl = (url) => {
    if (url.includes("seed")) {
      const newUrl = url.slice(0, url.indexOf("?seed"));
      return `${newUrl}?seed=${Math.round(Math.random() * 100)}`;
    } else {
      return `${url}?seed=${Math.round(Math.random() * 100)}`;
    }
  };

  return (
    <div className="custom-panel">
      <SidePanelTextInput getInputValue={getInputValue} />

      <ImagesGrid
        shadowEnabled={true}
        images={templates}
        getPreview={(item) =>
          props.section === "1"
            ? refreshUrl(item.image)
            : refreshUrl(item.envelope_image)
        }
        onSelect={async (item) => {
          const configuration =
            props.section === "1"
              ? item.configuration
              : item.envelope_configuration;
          configuration.pages[0].children.map((child) => {
            if (child.type == "image" || child.type == "svg") {
              child.src = refreshUrl(child.src);
            }
          });

          props.store.loadJSON(configuration);
          const background = configuration.pages[0]?.background;

          if (props.section === "2") {
            const page = props.store.addPage();

            props.store.pages[1].set({
              background: background,
            });

            page.addElement({
              type: "image",
              x: 0,
              y: 0,
              width: item.envelope_configuration.width,
              height: item.envelope_configuration.height,

              locked: true, // deprecated
              blurEnabled: true,
              blurRadius: 0,
              brightnessEnabled: true,

              shadowEnabled: true,

              shadowOpacity: 1,
              name: "letter", // name of element, can be used to find element in the store

              src: backgorundLetter,

              selectable: false,

              contentEditable: true,

              removable: false,

              resizable: true,
            });
          }
          props.store.selectPage(props.store.pages[0].id);
        }}
        getImageClassName={() => "side_panel_polotno_img"}
        rowsNumber={2}
        crossOrigin="anonymous"
      />
    </div>
  );
};

export default TemplatesPanelSection;
