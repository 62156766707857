const imageUrl = [
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/wet-snow.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/xv.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/dark-wood (1).png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/washi.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/txture.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/type.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/tweed.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/tex2res4.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/bright-squares.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/smooth-wall-dark.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/skulls.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/shley-tree-2.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/sandpaper.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/rice-paper-3.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/robots.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/random-grey-variations.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/pinstriped-suit.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/paper-1.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/otis-redding.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/noisy-net.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/lined-paper.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/knitted-sweater.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/grunge-wall.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/green-fibers.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/fresh-snow.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/food.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/diagonal-striped-brick.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/darth-stripe.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/dark-wood.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/dark-denim-3.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/crisp-paper-ruffles.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/classy-fabric.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/cartographer.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/cartographer.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/black-mamba.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/black-linen-2.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/black-linen.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/asfalt-dark.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/60-lines.png",
  "https://kolibird-resources.s3.eu-west-3.amazonaws.com/textures/45-degree-fabric-dark.png",
];

export { imageUrl };
