import axios from "axios";
import UsersApi from "./usersApi";

class Api {
  static getConfig() {
    return {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    };
  }

  static async getProducts(eurosCurrency) {
    const url = `/payments/products/`;
    const query = eurosCurrency ? "?currency=EUR" : "?currency=USD";
    const config = Api.getConfig();
    let products;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}${query}`,
        config
      );
      products = response.data;
    } catch (error) {
      await UsersApi.refreshToken();
      // return error.response.data.error;
      return false;
    }
    return products;
  }

  static async createSessionUrl(
    currentPriceId,
    toUrl = "my-events",
    coupon = null,
    currency = "EUR"
  ) {
    const url = `/payments/session/`;

    const config = Api.getConfig();

    const payload = {
      price_id: currentPriceId,
      next: toUrl === null ? "my-events" : toUrl,
      coupon: coupon,
      currency: currency,
    };
    let paymentUrl;
    let paymentUuid;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        payload,
        config
      );
      paymentUrl = response.data["url"];
      paymentUuid = response.data["payment"];
    } catch (error) {
      await UsersApi.refreshToken();
      return error.response.data.error;
    }
    return [paymentUrl, paymentUuid];
  }

  static async confirmPayment(paymentUuid) {
    const url = `/payments/confirm/`;

    const config = Api.getConfig();

    const payload = { payment: paymentUuid };
    try {
      await axios.post(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        payload,
        config
      );
    } catch (error) {
      return false;
    }
    return true;
  }

  static async cancelPayment(paymentUuid) {
    const url = `/payments/cancel/`;

    const config = Api.getConfig();

    const payload = { payment: paymentUuid };
    try {
      await axios.post(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        payload,
        config
      );
    } catch (error) {
      await UsersApi.refreshToken();
      return error.response.data.error;
    }
  }
}
export default Api;
