import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/state";
import { useTranslation } from "react-i18next";
import UsersServices from "../../services/usersServices";
import Api from "../../services/invitationsApi";
import InvitationsServices from "../../services/invitationsServices";
import UserApi from "../../services/usersApi";
import CreateInvitation from "./createInvitation";
import { limitNewInvitation } from "../../constants/limitNewInvitation";
import errorPicture from "../../assets/img/general/Error404Picture.svg";
import EnvelopeNoAnimate from "./envelopeNoAnimated";
import Modal from "../general/modal";
import { useState } from "react";
import Button from "../general/Button";
import Spinner from "../general/Spinner";

function TemplatesBoard() {
  const [openTemplate, setOpenTemplate] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState([]);
  const context = useAppContext();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const recoverTemplate = async (templateId) => {
    const draft = context.sharedState.invitations.filter(
      (invitation) => invitation.is_draft
    );
    if (draft.length < limitNewInvitation) {
      let invitation = InvitationsServices.initEmptyInvitation(context.user);

      const template = context.sharedState.templates.filter(
        (template) => template.uuid === templateId
      )[0];

      invitation.configuration = template.configuration;
      invitation.envelope_configuration = template.envelope_configuration;

      if (UsersServices.isLogIn()) {
        let user = context.user;
        if (user === null) {
          user = await UserApi.getCurrentUser();
          context.setUser(user);
        }
        invitation = { ...invitation, user: user.id, user_uuid: user.uuid };
        invitation = await Api.createInvitation(invitation);
      }

      context.setSharedState({
        ...context.sharedState,
        invitation: invitation,
      });

      navigate(`/invitation-laboratory/${invitation.uuid}/`, { replace: true });
    }
  };

  return (
    <div className="template-board-container">
      <h3 className="title-templateBoard">{t("templates.title")}</h3>

      <div className="templates">
        {!context.sharedState.templates?.length ? (
          // <div className="template-error-404-title">
          //   <h1>{t("error404.ups")}</h1>
          //   <p>{t("error404.notFound")}</p>
          //   <img
          //     className="template-error-img"
          //     src={errorPicture}
          //     alt="imagen de gatitos"
          //   />
          // </div>
          <Spinner typeColor={"purple"} customClassStyle={"template-roller"} />
        ) : (
          context.sharedState.templates.map((template, key) => {
            return (
              <div
                key={key}
                onClick={() => {
                  setCurrentTemplate(template);
                  setOpenTemplate(true);
                }}
                className="template">
                <EnvelopeNoAnimate
                  frontCard={template}
                  postcardElement={template.configuration}
                  envelope={true}
                  envelopeSide="back"
                  invitation={true}
                  scale={0.26}
                />

                <p>{template.name}</p>
              </div>
            );
          })
        )}
      </div>
      <Modal onOpen={openTemplate} setOnOpen={setOpenTemplate} buttonOn>
        <div className="modal-container-template">
          <div className="modal-invitation-container">
            <EnvelopeNoAnimate
              frontCard={currentTemplate}
              postcardElement={currentTemplate.configuration}
              envelope={true}
              envelopeSide="front"
              scale={0.4}
            />

            <EnvelopeNoAnimate
              frontCard={currentTemplate}
              postcardElement={currentTemplate.configuration}
              card={true}
              scale={0.4}
            />
          </div>
          <div className="modal-invitation-container-two">
            <p>{currentTemplate.name}</p>
            <CreateInvitation key={currentTemplate.uuid} newInvitation={false}>
              <Button
                text="Usar esta template"
                type="primary"
                onClick={() => {
                  recoverTemplate(currentTemplate.uuid);
                }}
              />
            </CreateInvitation>
          </div>
        </div>
      </Modal>
    </div>
  );
}
export default TemplatesBoard;
