import { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../context/state";
import { captureException } from "@sentry/react";
import Api from "../../services/usersApi";
import InvitationApi from "../../services/invitationsApi";
import ApiNewsLetter from "../../services/newsletterApi";
import Datalayer from "../../services/datalayer";
import logo from "../../assets/img/logo.svg";
import { Icon } from "@iconify/react";
import Button from "../general/Button";
import EditButton from "../general/editButton";
import CheckBoxButton from "../general/CheckBoxButton";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import completeSignUp from "../../assets/img/draws/completeSignUp.svg";
import "../../assets/css/signUp.css";
import jwt_decode from "jwt-decode";
import { decodeToken } from "../../services/utils";

function SignUp(props = { toUrl: null, isModal: false }) {
  const [isSignUp, setIsSignUp] = useState(false);
  const [requestError, setRequestError] = useState(null);
  const [showPassword, setShowPassword] = useState(true);
  const [valueInput, setValueInput] = useState("");
  const captcha = useRef(null);
  const [isSignIn, setIsSignIn] = useState("accessToken" in localStorage);
  const context = useAppContext();
  const [checkPassword, setCheckPassword] = useState({
    capital: false,
    lowercase: false,
    number: false,
    minLength: false,
  });
  const {
    register,
    handleSubmit,
    setError,
    watch,
    formState: { errors },
  } = useForm({ mode: "all" });
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = i18n.language.slice(0, 2);

  useEffect(() => {
    if (isSignIn && props.toUrl !== null) {
      Datalayer.push("register");
      navigate(props.toUrl, { replace: true });
    }
  }, [isSignIn]);

  const signUp = async (data) => {
    if (captcha.current) {
      let welcomeOffer = "";
      if (localStorage.getItem("welcomeOffer")) {
        const offer = decodeToken(localStorage.getItem("welcomeOffer"));
        const origin = process.env.REACT_APP_FAKE_ORIGIN
          ? process.env.REACT_APP_FAKE_ORIGIN
          : window.location.origin;
        welcomeOffer = offer.code;
        localStorage.setItem("welcomeOffer", "");
      }
      const [user, signUpError] = await Api.signUp(
        data.email,
        data.password,
        welcomeOffer
      );

      if (user) {
        if (context.sharedState?.invitation) {
          const invitation = {
            ...context.sharedState.invitation,
            user: user?.id,
          };
          await InvitationApi.createSignUpInvitation(invitation);
        }

        ApiNewsLetter.postNewsletterEmail({ email: data.email });
        Datalayer.push("register");
      }

      if (signUpError === null) {
        setIsSignUp(true);
      } else {
        setRequestError(signUpError);
      }
    } else {
      setError("errorCaptcha", {
        type: "custom",
        message: t("general.captchaerror"),
      });
    }
  };
  const handleInput = (e) => {
    const password = checkPassword;
    /[A-Z]/.test(e.target.value)
      ? (password.capital = true)
      : (password.capital = false);

    /[a-z]/.test(e.target.value)
      ? (password.lowercase = true)
      : (password.lowercase = false);

    /[0-9]/.test(e.target.value)
      ? (password.number = true)
      : (password.number = false);

    e.target.value.length >= 8
      ? (password.minLength = true)
      : (password.minLength = false);

    setCheckPassword(password);
    setValueInput(e.target.value);
  };

  const handleVerify = async (token) => {
    if (token) {
      captcha.current = await token;
    }
  };

  const handleCallbackResponse = async (response) => {
    const userObject = jwt_decode(response.credential);

    if (captcha.current && userObject) {
      const [signUpUser] = await Api.signUp(
        userObject.email,
        userObject.aud.slice(0, 13)
      );

      if (signUpUser != undefined) {
        ApiNewsLetter.postNewsletterEmail({ email: userObject.email });
        Datalayer.push("register");
        if (context.sharedState?.invitation) {
          const invitation = {
            ...context.sharedState.invitation,
            user: signUpUser?.id,
          };
          await InvitationApi.createSignUpInvitation(invitation);
        }
        await Api.verifyUser(signUpUser?.uuid);
      }
      const logIn = await Api.signIn(
        userObject.email,
        userObject.aud.slice(0, 13)
      );

      if (logIn.status === 400) {
        setRequestError(signUpUser);
      } else {
        setIsSignIn("accessToken" in localStorage);
        Datalayer.push("login");
        navigate(props.toUrl, { replace: true });
      }
    }
  };
  useEffect(() => {
    const handleClickGoogle = () => {
      /*global google */
      try {
        window.google.accounts.id.initialize({
          client_id: process.env.REACT_APP_ID_OAUTH_GOOGLE,
          callback: handleCallbackResponse,
        });
        window.google.accounts.id.renderButton(
          document.getElementById("oauthGoogle"),
          {
            theme: "outline",
            size: "large",
            text: "signup_with",
            shape: "circle",
            width: 100,
            // type: "icon",
          }
        );
      } catch (e) {
        captureException(e);
      }
    };
    window.google && handleClickGoogle();
  }, []);

  return (
    <>
      {isSignUp ? (
        <div className="signUp__main container">
          <h1>{t("signUp.registerCompleted")}</h1>
          <img
            className="signUp__img"
            src={completeSignUp}
            alt="image complete sing up"
          />
          <h2>{t("signUp.completedH2")}</h2>
          <p className="signUp__text">{t("signUp.completed")}</p>
          <div style={{ display: "none" }}>
            {setTimeout(() => navigate("/sign-in", { replace: true }), 10000)}
          </div>
        </div>
      ) : (
        <div
          className={
            props.isModal ? "log-container log-modal" : "log-container"
          }>
          <div className="log-logo">
            <img alt="logo" src={logo} />
          </div>
          <div className="log-content-container">
            <EditButton
              text={"inicio"}
              icon="ic:round-navigate-before"
              onClick={() =>
                (window.location.href = process.env.REACT_APP_CURRENT_URL)
              }
            />
            <div>
              <h2 className="title-login">{t("signUp.title")}</h2>

              <form onSubmit={handleSubmit(signUp)} className="log-form">
                <div
                  style={{ display: requestError !== null ? "block" : "none" }}>
                  <span className="error-singIn">{t("signIn.error")}</span>
                </div>
                <div className="info-field">
                  <label className="info-label" htmlFor="email">
                    {t("general.mail")}
                  </label>
                  <div
                    id="info-input"
                    className={
                      !errors?.email && watch().email?.length
                        ? " input-ok info-input-container"
                        : "info-input-container"
                    }>
                    <input
                      type="text"
                      id="email"
                      placeholder={t("signUp.emailPlaceholder")}
                      {...register("email", {
                        required: true,
                        pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                      })}
                      className={
                        errors?.email
                          ? "error input email-signup"
                          : "input email-signup"
                      }
                    />

                    {errors.email && <span className="error-point"></span>}
                    {errors.email && (
                      <span className="error-text">
                        {t("signUp.emailError")}
                      </span>
                    )}
                    {watch().email?.length > 0 && !errors?.email ? (
                      <div className={`complete-password`}>
                        <Icon icon="bi:check-circle-fill" />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="info-field">
                  <label className="info-label" htmlFor="password">
                    {t("signUp.password")}
                  </label>
                  <div
                    id="info-input"
                    className={
                      errors.password?.type === "required" ||
                      errors.password?.type === "maxLength"
                        ? "info-input-container input-password"
                        : " input-ok info-input-container input-password"
                    }>
                    <input
                      type={showPassword ? "password" : "text"}
                      placeholder={t("signUp.passwordPlaceholder")}
                      id="password"
                      {...register("password", {
                        required: true,
                        minLength: 8,
                        maxLength: 15,
                        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9])/,
                      })}
                      autoFocus
                      className={errors?.password ? "error input" : "input"}
                      onInput={handleInput}
                      value={valueInput}
                      autoComplete="true"
                    />
                    <span
                      onClick={() => setShowPassword(!showPassword)}
                      className="show-password">
                      {showPassword ? (
                        <Icon icon="fluent:eye-16-filled" />
                      ) : (
                        <Icon icon="fluent:eye-off-16-filled" />
                      )}
                    </span>
                    {errors.password && <span className="error-point"></span>}
                    {errors.password?.type === "required" && (
                      <span className="error-text">
                        {t("general.requiredField")}
                      </span>
                    )}
                    {errors.password?.type === "maxLength" && (
                      <span className="error-text">
                        Debe tener menos de 15 caracteres
                      </span>
                    )}
                    {watch().password?.length > 0 && !errors?.password ? (
                      <div className={`complete-password`}>
                        <Icon icon="bi:check-circle-fill" />
                      </div>
                    ) : null}
                  </div>
                  <div className="check-password-container">
                    <div
                      className={`check-password-${checkPassword.lowercase}`}>
                      <Icon icon="bi:check-circle-fill" />
                      <p>{t("signUp.lowercaseError")}</p>
                    </div>
                    <div className={`check-password-${checkPassword.capital}`}>
                      <Icon icon="bi:check-circle-fill" />
                      <p>{t("signUp.uppercaseError")}</p>
                    </div>
                    <div className={`check-password-${checkPassword.number}`}>
                      <Icon icon="bi:check-circle-fill" />
                      <p>{t("signUp.numberError")}</p>
                    </div>
                    <div
                      className={`check-password-${checkPassword.minLength}`}>
                      <Icon icon="bi:check-circle-fill" />
                      <p>{t("signUp.charsError")}</p>
                    </div>
                  </div>
                </div>

                <div className="privacy-conditions">
                  <CheckBoxButton
                    id="check-privacy"
                    validations={{
                      ...register("checkPrivacy", {
                        required: true,
                      }),
                    }}
                  />

                  <p>
                    {t("signUp.acceptText")}{" "}
                    <a
                      href={`${
                        process.env.REACT_APP_CURRENT_URL
                      }/${currentLanguage}/${t("general.termsUrl")}/`}
                      target="_blank">
                      {t("general.terms")}
                    </a>{" "}
                    {t("general.and")}{" "}
                    <a
                      href={`${
                        process.env.REACT_APP_CURRENT_URL
                      }/${currentLanguage}/${t("general.policyUrl")}/`}
                      target="_blank">
                      {t("general.policy")}
                    </a>
                  </p>
                  {errors?.checkPrivacy && (
                    <span className="error-point"></span>
                  )}
                  {errors?.checkPrivacy?.type === "required" && (
                    <span className="error-text error-text-sign-up">
                      {t("general.requiredField")}
                    </span>
                  )}
                </div>
                <div className="captcha">
                  <p className="captcha-policy">
                    {t("general.captcha1")}{" "}
                    <a href="https://policies.google.com/privacy">
                      {t("general.policy")}
                    </a>{" "}
                    {t("general.and")}{" "}
                    <a href="https://policies.google.com/terms">
                      {t("general.terms")}
                    </a>{" "}
                    {t("general.captcha2")}
                  </p>
                  {errors?.errorCaptcha && (
                    <span className="error-text">
                      {errors.errorCaptcha.message}
                    </span>
                  )}
                  <GoogleReCaptcha onVerify={handleVerify} />
                </div>
                <div className="button-log-container">
                  <Button
                    buttonType="submit"
                    type="primary"
                    text={t("signUp.start")}
                  />
                </div>
              </form>
              <div className="oauthGoogle-container">
                <div className="separation-oauthGoogle">
                  <p> {t("general.logEmail")}</p>
                </div>
                <div id="oauthGoogle"></div>
              </div>
              <div className="singIn-singUp forgot-password">
                <p>{t("signUp.hasAccount")}</p>
                <Icon icon="akar-icons:arrow-right" />
                {!props.isModal ? (
                  <Link to="/sign-in">{t("signUp.signIn")}</Link>
                ) : (
                  <button
                    onClick={() => props.handleModal && props.handleModal()}>
                    {t("signUp.signIn")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default SignUp;
