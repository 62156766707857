import "../../assets/css/email.css";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useAppContext } from "../../context/state";
import BodyEmail from "./bodyEmail";
import Api from "../../services/invitationsApi";
import EditButton from "../general/editButton";
import usePreviewEmail from "../../hooks/usePreviewEmail";
import ToggleButton from "../general/ToggleButton";
import popUpAlert from "../general/popUpAlert";
import SaveButton from "../general/saveButton";

function PreviewEmail() {
  const context = useAppContext();
  const [saveError, setSaveError] = useState();
  const [editEmailTopic, setEditEmailTopic] = useState(false);
  const {
    toggleEnvelope,
    togglePersonalMessage,
    toggleHeaderMessage,
    headerMessage,
    personalMessage,
    envelope,
  } = usePreviewEmail();
  const [infoEvent, setInfoEvent] = useState({
    ...context.sharedState,
  });
  const { t } = useTranslation();
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  useEffect(() => {
    (() => {
      context.setSharedState({
        ...context.sharedState,
        invitation: {
          ...context.sharedState.invitation,
          ...infoEvent.invitation,
        },
      });
    })();
  }, [infoEvent]);

  const onSubmit = async (e) => {
    e.preventDefault();
    handleSubmit(onSubmit);
    const target = e.target[0];
    const dataTarget = { [e.target[0].id]: e.target[0].value };

    if (errors[target.id] || target?.value.length < 1) {
      return;
    }
    setInfoEvent({
      ...infoEvent,
      invitation: { ...infoEvent.invitation, ...dataTarget },
    });
    const response = await Api.changeInvitation(
      dataTarget,
      context.sharedState.invitation.uuid
    );

    response === undefined
      ? popUpAlert(false, t("popUpSave.negative"))
      : popUpAlert(true, t("popUpSave.positive"));

    setEditEmailTopic(false);
  };

  const handleSave = async () => {
    const response = await Api.changeInvitation(
      context.sharedState.invitation,
      context.sharedState.invitation.uuid
    );

    response === undefined ? setSaveError(true) : setSaveError(false);
  };

  return (
    <div className="preview-container">
      <div className="save-button-poll">
        <SaveButton handleClick={handleSave} saveError={saveError} />
      </div>
      <div className="preview-options">
        <div className="preview-option">
          <ToggleButton
            checked={headerMessage}
            onClick={toggleHeaderMessage}
            id="headerMessage"
          />
          <p>{t("preview.defaultHead")}</p>
        </div>
        <div className="preview-option">
          <ToggleButton
            checked={!envelope}
            onClick={toggleEnvelope}
            id="envelope"
          />
          <p>{t("preview.invitationBody")}</p>
        </div>
        <div className="preview-option">
          <ToggleButton
            checked={envelope}
            onClick={toggleEnvelope}
            id="letter"
          />
          <p>{t("preview.envelopeBody")}</p>
        </div>
        <div className="preview-option">
          <ToggleButton
            checked={personalMessage}
            onClick={togglePersonalMessage}
            id="personalMessage"
          />
          <p>{t("preview.customMessage")}</p>
        </div>
        {personalMessage && (
          <form onSubmit={onSubmit}>
            <div className=" email-info-field  email-body-textarea">
              <div
                id="info-input"
                className={
                  !errors?.email_content && watch().email_content?.length
                    ? " input-ok info-input-container"
                    : "  info-input-container"
                }
              >
                <textarea
                  {...register("email_content", {
                    required: true,
                    maxLength: 255,
                  })}
                  maxLength="255"
                  type=""
                  id="email_content"
                  className={errors?.email_content ? "error input" : "input"}
                  defaultValue={context.sharedState.invitation.email_content}
                />

                {errors?.email_content && <span className="error-point"></span>}

                {errors?.email_content?.type === "maxLength" && (
                  <span className="error-text">{t("preview.contentMax")}</span>
                )}
                {errors?.email_content?.type === "required" && (
                  <span className="error-text">
                    {t("general.requiredField")}
                  </span>
                )}
              </div>
              <div className="email-edit-button-container">
                <EditButton
                  text={t("general.save")}
                  icon={"material-symbols:save-outline"}
                  color="#E33D8C"
                  colorText="#361A3C"
                />
              </div>
            </div>
          </form>
        )}
      </div>
      <div className=" container-email-body">
        <div className="email-sender">
          {/* {editEmailTopic === true ? (
            <form onSubmit={onSubmit} id="form1">
              <div className="email-info-field email-sender-input">
                <div
                  id="info-input"
                  className={
                    !errors?.email_topic && watch().email_topic?.length
                      ? " input-ok info-input-container"
                      : "  info-input-container"
                  }
                >
                  <input
                    {...register("email_topic", {
                      required: true,
                      minLength: 2,
                      maxLength: 25,
                    })}
                    autoFocus
                    maxLength="25"
                    type="text"
                    id="email_topic"
                    className={errors?.email_topic ? "error input" : "input"}
                    name="email_topic"
                  />

                  {errors?.email_topic && <span className="error-point"></span>}
                  {errors?.email_topic?.type === "required" && (
                    <span className="error-text">
                      {t("general.requiredField")}
                    </span>
                  )}
                </div>
                <div className="email-edit-button-container">
                  <EditButton
                    text={t("general.save")}
                    icon={"material-symbols:save-outline"}
                    color="#E33D8C"
                    colorText="#361A3C"
                  />

                  <EditButton
                    onClick={() => setEditEmailTopic(false)}
                    type="button"
                    text={t("general.cancel")}
                    icon="fluent:dismiss-20-filled"
                    color="#E33D8C"
                    colorText="#361A3C"
                  />
                </div>
              </div>
            </form> */}
          {/* ) : ( */}
          <div className="email-info-field">
            <p>{t("preview.email")}:</p>
            <p className="email-sender-event-name">{context.user.email}</p>
            {/* <EditButton
              onClick={() => {
                setEditEmailTopic(true);
              }}
              text={t("general.edit")}
              icon={"fluent:edit-48-filled"}
              color="#E33D8C"
              colorText="#361A3C"
            /> */}
          </div>
          <div className="email-info-field">
            <p>{t("preview.emailSubject")}:</p>
            <p className="email-sender-event-name">
              {infoEvent.invitation.email_topic}
            </p>
            {/* <EditButton
              onClick={() => {
                setEditEmailTopic(true);
              }}
              text={t("general.edit")}
              icon={"fluent:edit-48-filled"}
              color="#E33D8C"
              colorText="#361A3C"
            /> */}
          </div>
          {/* )} */}

          <div className="email-info-field ">
            <p className="email-sender-email">{infoEvent.invitation.email}</p>
          </div>
        </div>

        <BodyEmail
          dataProps={{
            infoEvent,
            setInfoEvent,
            onSubmit,
            headerMessage,
            personalMessage,
            envelope,
          }}
        />
      </div>
    </div>
  );
}
export default PreviewEmail;
