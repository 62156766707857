import "../../../assets/css/gestListBoard.css";
import { useEffect, useState } from "react";
import Button from "../../general/Button";
import groupApi from "../../../services/groupsApi";
import Api from "../../../services/invitationsApi";
import { useAppContext } from "../../../context/state";
import Modal from "../../general/modal";
import useFilterObject from "../../../hooks/useFilterObject";
import AddGuest from "./addGuest";
import { t } from "i18next";
import { Icon } from "@iconify/react";
import Empty from "../../general/Empty";
import EditButton from "../../general/editButton";
import trashIcon from "../../../assets/img/trash.svg";
import CheckBoxButton from "../../general/CheckBoxButton";
import Spinner from "../../general/Spinner";
// import ApiGroups from "../../../services/groupsApi";
import AddGroup from "./addGroup";
// import PopUpAlert from "../../general/popUpAlert";

const GuestsListBoardComponent = ({ page, loadingGroups, setQuery, handleQuery, initialGroups, totalGroups}) => {
  const context = useAppContext();
  const {attend, not_attend, no_answer, not_open, total_guests} = context.sharedState.invitation
  const totalPages= totalGroups > 20 ?  Math.ceil(totalGroups / 20) : ""
  const [groups, setGroups] = useState(initialGroups);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalEditGroup, setModalEditGroup] = useState(false);
  const [filterButton, setFilterButton] = useState({focus:"all", type: "undefined" });
  const [basicQuery, setBasicQuery]= useState("")
  const [searchQuery, setSearchQuery] = useState("");
  const [order, setOder] = useState("");
  const [deleteGroup, setDeleteGroup] = useState({});
  const [editGroup, setEditGroup] = useState();
  const { filtering, orderFilter, filteringNestedArray } = useFilterObject();
  const [invitationSave, setInvitationSave] = useState();
  const [selectGroups, setSelectcGroups] = useState([]);
  
  const queryTypes= {
    pag: "?page=",
    has_answer: "has_answer=",
    is_confirmated:`guests__is_confirmated=`,
    search: "search=",
    isOpened: "is_opened=",
    attend: "attend=",
    total: "total"
  }
  useEffect(() => {
      filterButtons(filterButton?.focus, filterButton?.key, filterButton?.value, filterButton?.guest)

  }, [initialGroups]);

  useEffect(()=>{
    const hasManyQueries =
        basicQuery !== "" && searchQuery !== "" ? "&" : searchQuery ? "?" : "";
    const totalQuery =
        basicQuery === "" && searchQuery === ""
          ? ""
          : `${basicQuery}${hasManyQueries}${searchQuery}`;
    setQuery(totalQuery)

  }, [basicQuery, searchQuery])

   const filterButtons = (focus, key, value, typeGuest) => {
     setFilterButton({focus: focus, key: key,  value: value, typeGuest: typeGuest});

     if (focus == "noAssist" ){
     return setGroups(initialGroups.filter(group => 
        group.guests.some(guest=>
          guest.is_confirmated === false
        )
      ))
    } 
     if (focus == "answer") {
      return setGroups(filtering(initialGroups, "has_answer", false));
    }else if(focus === "assist"){
      setGroups(initialGroups.filter(group => 
          group.guests.some(guest=>
          guest.is_confirmated === true
        )
      ))
    } else if(focus === "hasAnswer"|| focus === "open" || focus === "all"){
      setGroups(initialGroups) 
    }else {
      return setGroups(filtering(initialGroups, key, value, typeGuest));
    }
  };  

  const removeGuest = async (id) => {
    const response = await groupApi.deleteGroups(
      context.sharedState.invitation?.uuid,
      id,
    );
    setModalDelete(!modalDelete);
    filterButtons("all", "undefined")
    updateInvitation()
  };
 

    const handleQueryParam= (e, type)=> {
    if(type === queryTypes.search ){
      // quitar?
      // setFilterButton({focus:"all", type: "undefined" })
      setSearchQuery(`${queryTypes.search}${e}`)
    }else if(type=== queryTypes.isOpened){
      setFilterButton({focus: "open"})
      setBasicQuery(`?${queryTypes.isOpened}${e}`)
    }else if(type === queryTypes.has_answer){
      setBasicQuery(`?${queryTypes.has_answer}${e}`)
      setFilterButton({focus:"hasAnswer"})
    }else if(type === queryTypes.is_confirmated){
      setBasicQuery(`?${queryTypes.is_confirmated}${e}`)
      setFilterButton({focus: `${e === true ? "assist" : "noAssist"}`})
    }else if(type=== queryTypes.total){
      // setGroups(initialGroups)
      setFilterButton({focus:"all", type: "undefined" })
      setBasicQuery("")
    }

  }
  const updateInvitation = async() => {
      const invitationId = context.sharedState.invitation?.uuid;
       const newInvitation= await Api.getInvitation(invitationId)
       context.setSharedState({
        ...context.sharedState,
        invitation: newInvitation,
       })
  }
  const orderList = (e, colum) => {
    if (!e.target.className.includes("order-active")) {
      setGroups(orderFilter(groups, colum));
      setOder(colum);
    } else {
      setGroups(orderFilter(groups, colum));
      setOder("");
    }
    return;
  };

  const exportList = () => {};

  const selectGroup = (e, index) => {
    if (e.target.checked === true) {
      setSelectcGroups([...selectGroups, index]);
    } else if (e.target.checked === false) {
      const filteringSelect = selectGroups?.filter((item) => item != index);
      setSelectcGroups(filteringSelect);
    }
  };
  const selectAllGroups = (e) => {
    if (e.target.checked === true) {
      setSelectcGroups(groups);
    } else if (e.target.checked === false) {
      setSelectcGroups([]);
    }
  };

  const checkAssitand = (group, guest) => {
    const NORESPONSE = "table-guess";
    const POSITIVE = "table-guest-confirmated-true";
    const NEGATIVE = "table-guest-confirmated-false";
    let assitandState = NORESPONSE;
    if (guest.is_confirmated) {
      assitandState = POSITIVE;
    } else if (!guest.is_confirmated) {
      assitandState = NEGATIVE;
    } else {
      assitandState = NORESPONSE;
    }
    return assitandState;
  };
    const handlePage= async (pageDirection)=>{
    if(pageDirection === "back"){
      await handleQuery(page.back, -1)

    }
    if(pageDirection === "forward"){
      await handleQuery(page.next, 1)
    }
  }

  return (
    <div>
      <div className="guest-list-header">
        <div className="guest-list-search">
          <input
            placeholder={t("guestListBoard.searchPlaceholder")}
            className="input "
            type="search"
            onInput={(e) =>
              handleQueryParam(e.target.value, queryTypes.search)
            }
          />
          <Icon icon="fe:search" />
        </div>
      </div>
      <div className="table-guests-container">
        <div className="table-guest-static-filters">
          <p
            onClick={() => !loadingGroups &&
              handleQueryParam(false, queryTypes.total)}
            className={
              filterButton.focus === "all"
                ? "table-guest-static-filter filter-button-active"
                : "table-guest-static-filter"
            }
          >
            {t("guestListBoard.filterAll")} ({total_guests})
          </p>

          <p
            onClick={() => !loadingGroups &&
              // filterButtons("assist", "is_confirmated", true, "guests")
              handleQueryParam(true, queryTypes.is_confirmated)
            }
            className={ 
              filterButton.focus === "assist"
                ? "table-guest-static-filter filter-button-active"
                : "table-guest-static-filter"
            }
          >
            {t("guestListBoard.filterAssist")} (
            {attend})
          </p>
          <p
            onClick={() => !loadingGroups &&
              // filterButtons("noAssist", "is_confirmated", false, "guests")
              handleQueryParam(false, queryTypes.is_confirmated)

            }
            className={
              filterButton.focus === "noAssist"
                ? "table-guest-static-filter filter-button-active"
                : "table-guest-static-filter"
            }
          >
            {t("guestListBoard.filterNoAssist")} (
              {
              not_attend
            })
          </p>
          <p
            onClick={() => !loadingGroups && handleQueryParam(false, queryTypes.has_answer)}
            className={
              filterButton.focus === "hasAnswer"
                ? "table-guest-static-filter filter-button-active"
                : "table-guest-static-filter"
            }
          >
            {t("guestListBoard.filterAnswer")} (
            {
              no_answer
              })
          </p>
        </div>
        <div className="table-guests">
          <div className="table-guest-container table-guest-title">
            <div className="table-guest-email">
              <p
                onClick={(e) => orderList(e, "email")}
                className={order === "email" ? " order-active" : ""}
              >
                {t("guestListBoard.tableTitleEmail")}
              </p>
            </div>
            <CheckBoxButton
              onClick={(e) => selectAllGroups(e)}
              className="table-guest-check"
              id="table-guest-check"
              checked={
                selectGroups?.length === groups?.length && groups?.length
                  ? true
                  : false
              }
            />
            <div className="table-guest-name ">
              <p
                onClick={(e) => orderList(e, "name")}
                className={order === "name" ? "order-active" : ""}
              >
                {t("guestListBoard.tableTitleName")}
              </p>
            </div>
            <div className="table-guest-phone">
              <p>{t("guestListBoard.tableTitlePhone")}</p>
            </div>
            <div className="table-guest-modified">
              <p
                onClick={(e) => orderList(e, "updated")}
                className={order === "updated" ? "order-active" : ""}
              >
                {t("guestListBoard.tableTitleModified")}
              </p>
            </div>
            <div className="table-guest-state">
              <p>{t("guestListBoard.tableTitleState")}</p>
            </div>
            <div className="table-guest-numbers-menbers">
              <p>{t("guestListBoard.tableTitleNumbersMenbers")}</p>
            </div>

            <div className="table-guest-assist">
              <p>{t("tracking.attend")}</p>
            </div>
            <div className="table-guest-younger">
              <p>{t("guestListBoard.tableTitleYounger")}</p>
            </div>
            <div className="table-guest-actions">
              <p>{t("guestListBoard.tableTitleActions")}</p>
            </div>
          </div>
          <div className="table-guest-body-container">
            {groups?.length === 0 && !loadingGroups && <Empty tittle="No hay ningún invitado" />}

            {!loadingGroups ? groups?.map((group, index) => {
              return (
                <div
                  className={
                    selectGroups?.includes(group.uuid)
                      ? "table-guest-body group-selected"
                      : "table-guest-body"
                  }
                  key={index}
                >
                  <div className="table-guest-container ">
                    <p
                      onClick={(e) =>
                        e.target.parentNode.classList.toggle("group-active")
                      }
                      className="table-guest-email"
                    >
                      {group.email}
                    </p>
                    <CheckBoxButton
                      checked={selectGroups?.includes(group) ? true : false}
                      className="table-guest-check"
                      id={`table-guest-check-${index}`}
                      onClick={(e) => selectGroup(e, group)}
                    />
                    <p className="table-guest-name">{group.name}</p>
                    <p className="table-guest-phone">{group.phone_number}</p>
                    <p className="table-guest-modified">
                      {group.updated.slice(0, 10)} {group.updated.slice(11, 16)}
                    </p>
                    <div className="table-guest-state">
                      {group.guests.some(guest => 
                        guest.is_confirmated
                      ) 
                      ? (
                        <p className="has-answer ">A</p>
                      )
                      : 
                      group?.has_sent_error ?
                      (
                      <p className="error-to-sent ">{t("tracking.e")}</p>
                      )
                      :
                      (
                        <p className="is-not-sent ">N</p>
                      )}
                    </div>
                    <p className="table-guest-numbers-menbers">
                      {group.guests?.length}
                      <Icon icon="bi:people-fill" />
                    </p>

                    <p className="table-guest-assist">
                      {
                        group?.guests.filter(
                          (guest) => guest.is_confirmated === true,
                        )?.length
                      }
                    </p>
                    <p className="table-guest-younger">
                      {
                        group?.guests.filter(
                          (guest) => guest.is_under_age === true,
                        )?.length
                      }
                    </p>
                    <div className="table-guest-actions">
                      <Icon
                        icon="fluent:edit-48-filled"
                        onClick={() => {
                          setEditGroup(group);
                          setModalEditGroup(true);
                        }}
                      />

                      <Icon
                        icon="heroicons:trash-solid"
                        onClick={() => {
                          setDeleteGroup(group);
                          setModalDelete(!modalDelete);
                        }}
                      />
                    </div>
                  </div>
                  <ul className="table-guest-menbers">
                    {orderFilter(group?.guests, "id", "creasing").map(
                      (guest, index) => {
                        return (
                          <li
                            key={index}
                            className={checkAssitand(group, guest)}
                          >
                            {guest?.first_name == "null"
                              ? "Datos desconocidos"
                              : `${guest?.first_name} ${guest?.last_name}`}

                            <Icon
                              icon={
                                guest.is_under_age &&
                                "ic:baseline-child-friendly"
                              }
                            />
                          </li>
                        );
                      },
                    )}
                  </ul>
                </div>
              );
            })
            : <Spinner typeColor={"purple"}/>
          }
          </div>
        </div>
        <div className="table-resumen">
          <p>{`${selectGroups?.length} ${t("general.of")} ${
            initialGroups.length
          }`}</p>
          <div className={"table-resumen-pagination-container"}>
              {page.back && <Icon icon="material-symbols:arrow-back-ios-new-rounded" 
                className={"table-resumen-pagination-icon"}
                onClick={()=> handlePage("back")}/>}
              <p className={"table-resumen-pagination"}>{page.actual}{totalPages > 1 ? "/" : ""}{totalPages}</p>
              {page.next && <Icon icon="material-symbols:arrow-forward-ios-rounded" 
                className={"table-resumen-pagination-icon"}
                 onClick={()=> handlePage("forward")}/>}
          </div>
          
          <div className="table-resumen-states-container">
            <div className="table-resumen-state-container">
              <p className="has-answer">{t("tracking.a")}</p>
              <p>{t("tracking.attend")}</p>
            </div>
            <div className="table-resumen-state-container">
              <p className="is-not-sent ">{t("tracking.nr")}</p>
              <p>{t("tracking.notAttend")}</p>
            </div>
            <div className="table-resumen-state-container">
              <p className="error-to-sent ">{t("tracking.e")}</p>
              <p>{t("tracking.error")}</p>
            </div>
          </div>
        </div>
        <Modal onOpen={modalEditGroup} setOnOpen={setModalEditGroup} buttonOn>
          <div className="add-groups-container">
            <AddGroup group={editGroup} setOnOpen={setModalEditGroup} onFormAction={updateInvitation}/>
          </div>
        </Modal>
        <Modal onOpen={modalDelete} setOnOpen={setModalDelete}>
          <div className="table-group-delete-modal">
            <div className="table-group-delete-img">
              <img src={trashIcon}></img>
            </div>
            <p className="table-group-delete-modal-title ">
              {t("tracking.confirm")}
            </p>
            <p className="table-group-delete-modal-content ">
              {t("tracking.removeMessage")}
            </p>
            <div className="table-group-delete-buttons">
              <EditButton
                icon="heroicons-solid:x"
                className="table-group-delete-cancel-button"
                text={t("tracking.cancel")}
                onClick={() => setModalDelete(false)}
              />
              <Button
                className="table-group-delete-delete-button"
                type="primary"
                text={t("tracking.accept")}
                icon="bi:check-lg"
                onClick={() => removeGuest(deleteGroup.uuid)}
              />
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};
export default GuestsListBoardComponent;
