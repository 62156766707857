import Api from "./invitationsApi";
import { uploadInvitationImageFile } from "./utils";

class InvitationsServices {
  static initEmptyInvitation(user = null) {
    let invitation = {
      event_name: "",
      configuration: {},
      envelope_configuration: {},
    };

    if (user) {
      invitation.user_uuid = user.uuid;
      invitation.user = user.id;
    }

    return invitation;
  }

  static hasLocalInvitation() {
    return localStorage.getItem(btoa("invitation")) !== null;
  }

  static removeLocalInvitation() {
    localStorage.removeItem(btoa("invitation"));
  }

  static recoveryStorageInvitation() {
    const codedInvitation = localStorage[btoa("invitation")];
    const decodedInvitation = atob(codedInvitation);
    const invitation = JSON.parse(decodedInvitation);
    return invitation;
  }

  static saveStorageInvitation(invitation) {
    const strInvitation = JSON.stringify(invitation);
    const codedInvitation = btoa(strInvitation);
    localStorage[btoa("invitation")] = codedInvitation;
  }

  static async updateInvitationInfo(invitation, newInfo, hasApiSaving = true) {
    let updatedInvitation = { ...invitation, ...newInfo };
    try {
      if ("uuid" in invitation && hasApiSaving) {
        updatedInvitation = await Api.changeInvitation(
          newInfo,
          invitation.uuid
        );
      } else {
        InvitationsServices.saveStorageInvitation(updatedInvitation);
      }

      return updatedInvitation;
    } catch (error) {
      return error;
    }
  }

  static async getTemplateConfigsByTemplateId(templates, templateId) {
    let configs;
    if (templates.length < 1) {
      const response = await Api.getTemplate(templateId);
      configs = response.template_configs_selected;
    } else {
      configs = templates.filter((template) => template.uuid === templateId)[0][
        "template_configs_selected"
      ];
    }
    return configs;
  }

  static async configJsonWithoutBase64Images(config, invitationUuid) {
    let newConfig = { ...config };

    newConfig.pages = [];
    for (const page of config.pages) {
      let newPage = { ...page };
      let base64PageSplit = [];
      if ("background" in newPage) {
        base64PageSplit = newPage.background.split("base64,");
      }
      if (base64PageSplit.length > 1) {
        const pageImageUrl = await uploadInvitationImageFile(
          base64PageSplit[1],
          invitationUuid
        );
        newPage.background = pageImageUrl;
      }

      newPage.children = [];
      for (const child of page.children) {
        let newChild = { ...child };
        let base64Split = [];
        if ("src" in newChild) {
          base64Split = newChild.src.split("base64,");
        }
        if (base64Split.length > 1) {
          const imageUrl = await uploadInvitationImageFile(
            base64Split[1],
            invitationUuid
          );
          newChild.src = imageUrl;
        }
        newPage.children.push(newChild);
      }

      newConfig.pages.push(newPage);
    }

    return newConfig;
  }

  static async saveDesign(
    invitation,
    invitationStore = null,
    envelopeStore = null,
    hasApiSaving = true,
    hasImageSaving = true
  ) {
    let newConfiguration = { ...invitation };
    if (invitation) {
      if (invitationStore) {
        let imageUrl;
        try {
          if (hasImageSaving) {
            // if (invitationStore.pages.length < 1) {
            //   invitationStore.loadJSON(invitation.configuration);
            // }
            /*const originalJSON = envelopeStore.toJSON();
            let invitationTempJSON = JSON.parse(JSON.stringify(originalJSON));
            if((invitationTempJSON.pages[0].background === "white") || (invitationTempJSON.pages[0].background === "#fff") || (invitationTempJSON.pages[0].background === "#FFF") || (invitationTempJSON.pages[0].background === "#ffffff") || (invitationTempJSON.pages[0].background === "#FFFFFF")){
              invitationTempJSON.pages[0].background = "#CFCFCF";
            }

            invitationStore.loadJSON(invitationTempJSON);*/

            const b64Image = await invitationStore.toDataURL();
            //invitationStore.loadJSON(originalJSON);
            imageUrl = await uploadInvitationImageFile(
              b64Image,
              invitation.uuid,
              "invitation"
            );
          }
        } catch (error) {
          imageUrl = "";
        }

        try {
          let designJson = invitationStore.toJSON();
          designJson = await InvitationsServices.configJsonWithoutBase64Images(
            designJson,
            invitation.uuid
          );
          newConfiguration.configuration = designJson;
          newConfiguration.image = imageUrl;
        } catch (error) {}
      }

      if (envelopeStore) {
        let imageUrl;
        try {
          if (hasImageSaving) {
            if (envelopeStore.pages.length < 1) {
              envelopeStore.loadJSON(invitation.envelope_configuration);
            }
            /*const originalJSON = envelopeStore.toJSON();
            let envelopeTempJSON = JSON.parse(JSON.stringify(originalJSON));
            if((envelopeTempJSON.pages[0].background === "white") || (envelopeTempJSON.pages[0].background === "#fff") || (envelopeTempJSON.pages[0].background === "#FFF") || (envelopeTempJSON.pages[0].background === "#ffffff") || (envelopeTempJSON.pages[0].background === "#FFFFFF")){
              envelopeTempJSON.pages[0].background = "#CFCFCF";
            }

            if((envelopeTempJSON.pages[1].background === "white") || (envelopeTempJSON.pages[1].background === "#fff") || (envelopeTempJSON.pages[1].background === "#FFF") || (envelopeTempJSON.pages[1].background === "#ffffff") || (envelopeTempJSON.pages[1].background === "#FFFFFF")){
              envelopeTempJSON.pages[1].background = "#CFCFCF";
            }

            envelopeStore.loadJSON(envelopeTempJSON);*/
            const b64Image = await envelopeStore.toDataURL();
            //envelopeStore.loadJSON(originalJSON);
            imageUrl = await uploadInvitationImageFile(
              b64Image,
              invitation.uuid,
              "envelope"
            );
          }
        } catch (error) {
          imageUrl = "";
        }

        try {
          let designJson = envelopeStore.toJSON();
          designJson = await InvitationsServices.configJsonWithoutBase64Images(
            designJson,
            invitation.uuid
          );
          newConfiguration.envelope_configuration = designJson;
          newConfiguration.envelope_image = imageUrl;
        } catch (error) {}
      }
      const updatedInvitation = await InvitationsServices.updateInvitationInfo(
        invitation,
        newConfiguration,
        hasApiSaving
      );

      return updatedInvitation;
    }
    return invitation;
  }
}
export default InvitationsServices;
