import { useState } from "react";

function TextForm(props = { value: "" }) {
  const [text, setText] = useState(props.value);

  return (
    <div className="invitation-question-textarea info-input-container  email-body-textarea">
      <textarea
        onChange={(event) => {
          setText(event.target.value);
          props.modifyForm(props.question.id, event.target.value);
        }}
        className="input"
        value={text}
        required={props.question.required}
      ></textarea>
    </div>
  );
}
export default TextForm;
