import { useAppContext } from "../../context/state";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../services/pollsApi";
import useQuestions from "../../hooks/useQuestions";
import Choice from "./choice";
import EditButton from "../general/editButton";
import { Icon } from "@iconify/react";
import CheckBoxButton from "../general/CheckBoxButton";

function QuestionMulti(props = {}) {
  const context = useAppContext();
  const { t } = useTranslation();
  const [choices, setChoices] = useState(
    context.sharedState.questionsPossibilities.filter(
      (choice) => props.question.id === choice.question
    )
  );

  const [
    required,
    setRequired,
    question,
    setQuestion,
    groupQuestion,
    setGroupQuestion,
    changeText,
  ] = useQuestions(props);

  const addChoice = async () => {
    const choice = {
      text: " ",
      question: props.question.id,
    };
    const savedChoice = await Api.createPosibility(
      choice,
      context.sharedState.invitation.uuid
    );
    let newChoices = context.sharedState.questionsPossibilities;
    newChoices.push(savedChoice);
    context.setSharedState({
      ...context.sharedState,
      questionsPossibilities: newChoices,
    });
    setChoices(newChoices);
  };

  const getTitle = () => {
    switch (props.question.type) {
      case "1":
        return t("poll.multipleChoiceField");
      case "2":
        return t("poll.singleOptionField");
      case "3":
        return t("poll.dropDownField");
      default:
        return t("poll.unknownField");
    }
  };

  return (
    <>
      <h4 className="type-question">{getTitle()}</h4>

      <p className="number-question">
        {t("poll.question")} {props.index + 1}
      </p>
      <div className="info-input-container question-input-container">
        <input
          placeholder={` ${t("poll.question")} ${props.index + 1}`}
          className="input "
          type="text"
          onChange={(event) => setQuestion(event.target.value)}
          onBlur={(event) => changeText(event, props.question.uuid)}
          value={question != "null" ? question : ""}
        />
      </div>
      <div className="checkbox-options">
        <div className="checkbox-option">
          <CheckBoxButton
            id={`obligatory-multi-checkbox-${props.index}`}
            type="checkbox"
            onClick={(event) => setRequired(event.target.checked)}
            checked={required}
          />
          <label>{t("poll.labelObligatory")}</label>
        </div>
        <div className="checkbox-option">
          <CheckBoxButton
            id={`all-guests-multi-checkbox-${props.index}`}
            type="checkbox"
            onClick={(event) => setGroupQuestion(event.target.checked)}
            checked={groupQuestion}
          />
          <label>{t("poll.labelAllGuests")}</label>
        </div>
      </div>
      <div className="response-choice-container">
        {choices.map((choice, index) => {
          if (choice.question === props.question.id) {
            return (
              <Choice
                key={choice.uuid}
                index={index}
                choice={choice}
                setChoices={setChoices}
                questionType={props.question.type}
              />
            );
          }
        })}
      </div>
      <EditButton
        className="add-response"
        onClick={addChoice}
        text={t("poll.addAnswer")}
        icon="fluent:add-12-regular"
      />
    </>
  );
}
export default QuestionMulti;
