import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Api from "../../services/paymentsApi.js";
import Datalayer from "../../services/datalayer";
import "../../assets/css/paymentCanceled.css";
import Button from "../general/Button.js";

function PaymentCanceled(props = { toUrl: "/my-events" }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const cancelPayment = async () => {
      await Api.cancelPayment(props.paymentUuid);
      Datalayer.push("payment_ko");
      setTimeout(() => {
        navigate(`/buy-invitations?next=${props.toUrl}`, { replace: false });
      }, 5000);
    };

    cancelPayment();
  }, []);

  return (
    <div className="paymentcanceled__main container creation-board">
      <div className="paymentcanceled__draw">
        <div className="paymentcanceled__draw-nest"></div>
      </div>
      <div className="paymentcanceled__body">
        <div className="paymentcanceled__body-text">
          <p className="paymentcanceled__text"> {t("payment.cancelTitle")} </p>
          <p className="paymentcanceled__text">
            {" "}
            {t("payment.cancelSubtitle")}{" "}
          </p>
        </div>
        <Button
          type={"primary"}
          text={t("payment.cancelButton")}
          onClick={() => navigate("/buy-invitations")}
        />
      </div>
    </div>
  );
}
export default PaymentCanceled;
