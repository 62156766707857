import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import {
  SectionTab,
  SidePanel,
  TextSection,
  UploadSection,
} from "polotno/side-panel";
import { Icon } from "@iconify/react";

import ImagesGridPanel from "../components/invitations/polotno/imagesGridPanel";
import StylePanel from "../components/invitations/polotno/stylePanel";
import postmark from "../assets/img/icons/postmark.svg";
import waxSeal from "../assets/img/icons/waxSeal.svg";
import TemplatesPanelSection from "../components/invitations/polotno/TemplatesPanelSection";

import { useAppContext } from "../context/state";
import InvitationsServices from "../services/invitationsServices";
import ToggleButton from "../components/general/ToggleButton";

function useEnvelopeCustomPanels() {
  const { t } = useTranslation();
  const context = useAppContext();
  const textBlockedPage = t("design.boardUI.sidePanel.textBlockedPage");
  const styleSection = {
    name: "style",
    Tab: (props) => (
      <SectionTab name={t("design.boardUI.sidePanel.style")} {...props}>
        <Icon icon="fluent:design-ideas-24-regular" />
      </SectionTab>
    ),
    Panel: observer(({ store }) => {
      return <StylePanel store={store} />;
    }),
  };

  const customTemplatesSection = {
    name: "custom-templates",
    Tab: (props) => (
      <SectionTab name={t("design.boardUI.sidePanel.templates")} {...props}>
        <Icon icon="fluent:board-24-regular" />
      </SectionTab>
    ),
    Panel: observer(({ store }) => {
      return <TemplatesPanelSection store={store} section="2" />;
    }),
  };

  const customShapesSection = {
    name: "shapesCustom",
    Tab: (props) => (
      <SectionTab name={t("design.boardUI.sidePanel.shapes")} {...props}>
        <Icon icon="fluent:shapes-24-regular" />
      </SectionTab>
    ),
    Panel: observer(({ store }) => {
      return store.pages[1] != store.activePage ? (
        <ImagesGridPanel store={store} section={3} key={3} />
      ) : (
        <p>{textBlockedPage}</p>
      );
    }),
  };

  const stampSection = {
    name: "stamp",
    Tab: (props) => (
      <SectionTab name={t("design.boardUI.sidePanel.stamp")} {...props}>
        <Icon icon="bi:postage-heart" />
      </SectionTab>
    ),
    Panel: observer(({ store }) => {
      return store.pages[1] != store.activePage ? (
        <ImagesGridPanel store={store} section={5} />
      ) : (
        <p>{textBlockedPage}</p>
      );
    }),
  };

  const postmarkSection = {
    name: "postmark",
    Tab: (props) => (
      <div className="icon-img">
        <SectionTab name={t("design.boardUI.sidePanel.postmark")} {...props}>
          <img src={postmark} alt="postmark" />
        </SectionTab>
      </div>
    ),
    Panel: observer(({ store }) => {
      return store.pages[1] != store.activePage ? (
        <ImagesGridPanel store={store} section={6} />
      ) : (
        <p>{textBlockedPage}</p>
      );
    }),
  };

  const waxSealSection = {
    name: "photosCustom",
    Tab: UploadSection.Tab,
    Panel: observer(({ store }) => {
      return store.pages[1] != store.activePage ? (
        UploadSection.Panel({ store })
      ) : (
        <p>{textBlockedPage}</p>
      );
    }),
  };

  const customTextSection = {
    name: "photosCustoms",
    Tab: TextSection.Tab,

    Panel: observer(({ store }) => {
      let element;
      const groupElement = store.pages[0]?.children.find((element) => {
        return element.name === "guestName";
      });
      const groupElementHost = store.pages[1]?.children.find((element) => {
        return element.name === "hostName";
      });

      let state = groupElement?.id === undefined ? false : true;
      let stateHost = groupElementHost?.id === undefined ? false : true;

      const toggleGroupName = async () => {
        if (groupElement?.id === undefined) {
          element = store.pages[0].addElement({
            type: "text",
            x: store.width / 2 - 300 / 2,
            y: store.height / 2,
            fill: "black",
            text: t("design.boardUI.sidePanel.guestName"),
            name: "guestName",
            fontWeight: "bold",
            fontSize: 32,
            width: 300,
          });
          element.set({
            contentEditable: false,
            // resizable: false,
            removable: false,
          });
          state = true;
        } else {
          store.deleteElements([groupElement?.id]);
          state = false;
        }

        const updatedInvitation = await InvitationsServices.saveDesign(
          context.sharedState.invitation,
          null,
          store
        );

        context.setSharedState({
          ...context.sharedState,
          invitation: { ...updatedInvitation },
        });
      };
      const toggleHost = () => {
        if (groupElementHost?.id === undefined) {
          element = store.pages[1].addElement({
            type: "text",
            x: 0,
            y: store.height / 4,
            fill: "black",
            backgroundColor: "transparent",
            name: "hostName",
            text: context.sharedState.invitation.sender_name.length
              ? context.sharedState.invitation.sender_name
              : t("design.boardUI.sidePanel.hostName"),
            fontWeight: "bold",
            fontSize: 32,
            selectable: true,
            width: store.width,
            align: "center",
            draggable: false,
            removable: false,
            resizable: false,
          });

          stateHost = true;
        } else {
          store.deleteElements([groupElementHost?.id]);
          stateHost = false;
        }
      };

      return (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
                justifyContent: "center",
                padding: "1rem  0",
                width: "100%",
              }}
            >
              <ToggleButton
                id="toggleGroupName"
                checked={state}
                onClick={toggleGroupName}
              />
              <p style={{ fontWeight: 600, margin: 0 }}>
                {t("design.boardUI.sidePanel.customText")}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "1rem",
                margin: "0 0 1rem 0",
                width: "100%",
                borderBottom: "1px solid #9ca7b3",
              }}
            >
              <ToggleButton
                id="toggleHostName"
                checked={stateHost}
                onClick={toggleHost}
              />
              <p style={{ fontWeight: 600, margin: 0 }}>
                {t("design.boardUI.sidePanel.customHostName")}
              </p>
            </div>
            <div
              style={{
                height: "calc(100% - 145px)",
              }}
            >
              {store.pages[1] != store.activePage &&
                TextSection.Panel({ store: store })}
            </div>
          </div>
        </>
      );
    }),
  };

  return {
    customTemplatesSection: customTemplatesSection,
    styleSection: styleSection,
    customShapesSection: customShapesSection,
    stampSection: stampSection,
    postmarkSection: postmarkSection,
    waxSealSection: waxSealSection,
    customTextSection: customTextSection,
    // waxSealSection: waxSealSection,
  };
}

export default useEnvelopeCustomPanels;
