import "../../../assets/css/gestListBoard.css";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../general/Button";
import { useAppContext } from "../../../context/state";
import Modal from "../../general/modal";
import useFilterObject from "../../../hooks/useFilterObject";
import AddGuest from "./addGuest";
import { t } from "i18next";
import { Icon } from "@iconify/react";
import CheckBoxButton from "../../general/CheckBoxButton";
import ApiGroups from "../../../services/groupsApi";
import Api from "../../../services/invitationsApi";
import Datalayer from "../../../services/datalayer";
import AddGroup from "./addGroup";
import trashIcon from "../../../assets/img/trash.svg";
import EditButton from "../../general/editButton";
import { useTranslation } from "react-i18next";
import popUpAlert from "../../general/popUpAlert";
import Empty from "../../general/Empty";
import SubmitInvitations from "./submitInvitations";
import imgSelectGroups from "../../../assets/img/img-select-groups.png";
import { useLoadingFetch } from "../../../hooks/useLoadingFetch";
import Spinner from "../../../components/general/Spinner";
import emptyImg from "../../../assets/img/general/empty.svg";

const GuestsListBoard = () => {
  const context = useAppContext();
  const { sent, not_sent, not_open, open, uuid } =
    context.sharedState?.invitation;
  const navigate = useNavigate();
  const { loading, fetch } = useLoadingFetch(ApiGroups.getGroups);
  const { id } = useParams();
  const [query, setQuery] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState({
    actual: 1,
    next: null,
    back: null,
    first: 1,
    last: 1
  });

  const [groups, setGroups] = useState([]);
  const [amountGroups, setAmountGroups] = useState(0);
  const totalpages = amountGroups > 20 ? Math.ceil(amountGroups / 20) : "";
  const [groupsCopy, setGroupsCopy] = useState([]);
  const [modalAddGroup, setModalAddGroup] = useState(false);
  const [modalWhatsapp, setModalWhatsapp] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalGroupDelete, setModalGroupDelete] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [modalEditGroup, setModalEditGroup] = useState(false);
  const { t } = useTranslation();
  const [filterButton, setFilterButton] = useState("total");
  const [order, setOrder] = useState("");
  const [deleteGroup, setDeleteGroup] = useState({});
  const [editGroup, setEditGroup] = useState();
  const [reFetch, setReFetch] = useState(false);
  const { filtering, orderFilter } = useFilterObject();
  const [selectGroups, setSelectGroups] = useState([]);
  const [alertSelectGroups, setAlertSelectGroups] = useState(false);
  const [modalSubmit, setModalSubmit] = useState(false);
  const [allGroups, setAllGroups] = useState([]);
  const actionTitle = t("actionTypes.title");
  const actionsType = [
    {
      name: t("actionTypes.title"),
      type: "",
    },
    { name: t("actionTypes.delete"), type: "delete" },
  ];
  const queryTypes = {
    total: "",
    pag: "page=",
    search: "search=",
    isOpened: "is_opened=",
    isSent: "is_sent=",
  };

  useEffect(() => {
    Datalayer.push("guests_step");
  }, []);

  useEffect(() => {
    (async () => {
      const hasManyQueries =
        query !== "" && searchQuery !== "" ? "&" : searchQuery ? "?" : "";
      const totalQuery =
        query === "" && searchQuery === ""
          ? ""
          : `${query}${hasManyQueries}${searchQuery}`;
      clean();
      try {
        const response = await fetch(id, totalQuery);
        const nextPage = response?.next;
        const previousPage = response?.previous;
        setPage({
          actual: 1,
          next: nextPage,
          back: previousPage,
          first: 1,
          last: totalpages
        });

        const groupWithGuests = response.results;
        setAmountGroups(response?.count);
        setGroups(orderFilter(groupWithGuests, "id", "decreasing"));
      } catch {}
    })();
  }, [reFetch, modalAddGroup, query, searchQuery]);

  useEffect(() => {
    const updateInvitation = async () => {
      const invitationId = context.sharedState.invitation?.uuid;
      const newInvitation = await Api.getInvitation(invitationId);
      context.setSharedState({
        ...context.sharedState,
        invitation: newInvitation,
      });
    };
    handleQuery("all");
    updateInvitation();
  }, [reFetch]);
  useEffect(() => {
    if (!modalSubmit) {
      clean();
    }
  }, [modalSubmit]);

  const checkingSelectGroups = () => {
    const selectedGroups = selectGroups.filter(
      (group) =>
        group?.is_sent == false ||
        group?.has_answer == false ||
        group?.has_sent_error == true
    );
    if (!selectedGroups.length) {
      setAlertSelectGroups(true);
    } else {
      setAlertSelectGroups(false);
    }
    setModalSubmit(true);
  };

  const removeGuest = async (id) => {
    const response = await ApiGroups.deleteGroups(
      context.sharedState.invitation?.uuid,
      id
    );
    response === undefined
      ? popUpAlert(false, t("popUpSave.negative"))
      : popUpAlert(true, t("popUpSave.positive"));
    setReFetch((data) => !data);
    setModalDelete(!modalDelete);
  };

  const removeAllGroups = async (selected = true) => {
    if (selected) {
      selectGroups.forEach(async (group) => {
        try {
          await ApiGroups.deleteGroups(
            context.sharedState.invitation?.uuid,
            group.uuid
          );
          setReFetch((data) => !data);
        } catch (error) {
          return;
        }
      });
    } else if (!selected) {
      allGroups.forEach(async (group) => {
        try {
          await ApiGroups.deleteGroups(
            context.sharedState.invitation?.uuid,
            group.uuid
          );
          setAllGroups([]);
          setReFetch((data) => !data);
        } catch (error) {
          return;
        }
      });
    }

    setModalGroupDelete(false);
  };

  const clean = () => {
    setSelectGroups([]);
  };

  const orderList = (e, colum) => {
    if (!e.target.className.includes("order-active")) {
      setGroups(orderFilter(groups, colum));
      setOrder(colum);
    } else {
      setGroups(orderFilter(groups, colum));
      setOrder("");
    }
    return;
  };

  const exportList = async () => {
    const invitationUuid = context.sharedState.invitation?.uuid;
    if (invitationUuid) {
      const payload = {
        invitation_uuid: invitationUuid,
      };

      const excelFile = await ApiGroups.getBatch(payload);
      const url = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${excelFile}`;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Guests${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
    }
  };

  const sendInvitations = async () => {
    const selectedGroups = selectGroups.filter(
      (group) => group?.is_sent == false
    );
    const reSendGroupsIds = selectGroups
      .filter((group) => group?.is_sent === true && group?.has_answer === false)
      .map((group) => group.id);

    let invitation = context.sharedState.invitation;

    const groupIds = selectedGroups.map((group) => group.id);
    if (context.user?.total_invitations >= selectedGroups?.length) {
      Datalayer.push("send_invitations");
      await Api.sendInvitations(groupIds, invitation.uuid);
      let user = context.user;
      user.total_invitations -= selectedGroups?.length;
      invitation.worn_invitations += selectedGroups?.length;
      context.setUser(user);
      context.setSharedState({
        ...context.sharedState,
        invitation: invitation,
      });
      const updatedGroups = groupsCopy.map((group) => {
        if (groupIds.includes(group.id)) {
          group.is_sent = true;
          return group;
        } else {
          return group;
        }
      });

      setGroups(updatedGroups);
    } else {
      navigate(
        `/buy-invitations?next=${window.location.pathname}?step=4&groups=${groupIds.length}`,
        { replace: false }
      );
    }
    await Api.sendInvitations(reSendGroupsIds, invitation.uuid);
    setReFetch(!reFetch);
    setSelectGroups([]);
    setSubmitted(true);
  };
  const handlePagination = async (url, newCount = 0) => {
    try {
      const response = await ApiGroups?.getGroupsQuery(url);
      const nextPage = response?.next;
      const previousPage = response?.previous;
      setPage({
        actual: page.actual + newCount,
        next: nextPage,
        back: previousPage,
      });

      const groupWithGuests = response.results;
      setAmountGroups(response?.count);
      setGroups(orderFilter(groupWithGuests, "id", "decreasing"));
    } catch {}
  };
  const handlePage = async (pageDirection) => {
    if (pageDirection === "back") {
      await handlePagination(page.back, -1);
    }
    if (pageDirection === "forward") {
      await handlePagination(page.next, 1);
    }

    if(pageDirection === "first"){
      await handlePagination(page.first, (page.actual * -1) +1);
    }

    if(pageDirection === "last"){
      await handlePagination(page.last, (totalpages - page.actual)+1);
    }
  };
  const handleQuery = (e, type) => {
    setPage({ ...page, actual: 1 });
    if (type === queryTypes.search) {
      // setFilterButton("total")
      setSearchQuery(`${e !== "" ? queryTypes.search : ""}${e}`);
    } else if (type === queryTypes.isSent) {
      setFilterButton(`sent${e}`);
      setQuery(`?${queryTypes.isSent}${e}`);
    } else if (type === queryTypes.isOpened) {
      setFilterButton(`isOpen${e}`);
      setQuery(`?${queryTypes.isOpened}${e}`);
    } else if (type === queryTypes.total) {
      setFilterButton("total");
      setQuery("");
    }
  };

  const selectGroup = (e, group) => {
    if (e.target.checked === true) {
      setSelectGroups((selectGroup) => [...selectGroup, group]);
    } else if (e.target.checked === false) {
      const filteringSelect = selectGroups?.filter((item) => item !== group);
      setSelectGroups(filteringSelect);
    }
  };
  const selectAllGroups = (e) => {
    if (e.target.checked === true) {
      setSelectGroups(groups);
    } else if (e.target.checked === false) {
      setSelectGroups([]);
    }
  };
  const handleActions = (e) => {
    if (e === "delete") {
      setModalGroupDelete(true);
    }
  };
  useEffect(() => {
    setSubmitted(false);
  }, [modalSubmit]);

  const selectStateGroup = (group) => {
    // if(group.has_answer){
    //   return <p className="has-answer ">{t("guestListBoard.a")}</p>
    // }
    if (group.has_sent_error) {
      return <p className="error-to-sent ">{t("guestListBoard.e")}</p>;
    }
    if (group.is_sent) {
      return <p className="is-sent ">{t("guestListBoard.s")}</p>;
    }
    return <p className="is-not-sent ">{t("guestListBoard.ns")}</p>;
  };
  const openUrl = (groupId, idInvitation) => {
    const url = `/invitation/${idInvitation}/group/${groupId}?owner=true`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const openWhatsapp = (groupId, idInvitation, name, phone) => {
      const url = `https://wa.me/${phone}?text=${t("guestListBoard.whatsappMessage1")}${name}${t("guestListBoard.whatsappMessage2")}${context.sharedState.invitation?.event_name}. ${t("guestListBoard.whatsappMessage3")}: ${window.location.origin}/invitation/${idInvitation}/group/${groupId}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div className="guest-list-board-container">
      <Button
        text={t("guestListBoard.sendInvitations")}
        buttonType="button"
        className="guest-send-invitation-button"
        type="primary"
        onClick={checkingSelectGroups}
      />

      <div className="guest-list-header">
        <div className="guest-list-search">
          <input
            placeholder={t("guestListBoard.searchPlaceholder")}
            className="input "
            type="search"
            onInput={(e) => handleQuery(e.target.value, queryTypes.search)}
          />
          <Icon icon="fe:search" />
        </div>

        <div className="buttons-add-guest-container">
          <Button
            text={t("guestListBoard.buttonExportList")}
            buttonType="button"
            className="button-export-list"
            icon="fluent:arrow-download-16-regular"
            onClick={exportList}
          />
          <Button
            text={t("guestListBoard.buttonAddGuests")}
            type="primary"
            buttonType="button"
            className="button-add-guest"
            icon="fluent:add-12-regular"
            onClick={() => setModalAddGroup(true)}
          />
        </div>
      </div>
      <div className="table-guests-container">
        <Button
          className="table-guests-delete-all"
          type="scondary"
          text={t("guestListBoard.deleteAll")}
          icon="heroicons:trash-solid"
          onClick={async () => {
            const { results } = await fetch(id, "");

            setAllGroups(results);
            setModalGroupDelete(!modalGroupDelete);
          }}
        />
        <div>
          <div className="table-guest-static-filters-container">
            <div className="table-guest-static-filters">
              <p
                onClick={() => !loading && handleQuery("", queryTypes.total)}
                className={
                  filterButton === "total"
                    ? "table-guest-static-filter filter-button-active"
                    : "table-guest-static-filter"
                }
              >
                {t("guestListBoard.filterAll")} ({sent + not_sent})
              </p>
              <p
                onClick={() => !loading && handleQuery(true, queryTypes.isSent)}
                className={
                  filterButton === "senttrue"
                    ? "table-guest-static-filter filter-button-active"
                    : "table-guest-static-filter"
                }
              >
                {t("guestListBoard.filterSend")} (
                {
                  sent
                  // filtering(groupsCopy, "is_sent", true)?.length
                }
                )
              </p>
              <p
                onClick={() =>
                  !loading && handleQuery(false, queryTypes.isSent)
                }
                className={
                  filterButton === "sentfalse"
                    ? "table-guest-static-filter filter-button-active"
                    : "table-guest-static-filter"
                }
              >
                {t("guestListBoard.filterNoSend")} (
                {
                  not_sent
                  // filtering(groupsCopy, "is_sent", false)?.length
                }
                )
              </p>
              <p
                onClick={() =>
                  !loading && handleQuery(true, queryTypes.isOpened)
                }
                className={
                  filterButton === "isOpentrue"
                    ? "table-guest-static-filter filter-button-active"
                    : "table-guest-static-filter"
                }
              >
                {t("guestListBoard.filterOpen")} ({open})
              </p>
              <p
                onClick={() =>
                  !loading && handleQuery(false, queryTypes.isOpened)
                }
                className={
                  filterButton === "isOpenfalse"
                    ? "table-guest-static-filter filter-button-active"
                    : "table-guest-static-filter"
                }
              >
                {t("guestListBoard.filterNotOpen")} ({not_open})
              </p>
              {/* <p
            onClick={() => filterButtons n", "is_opened", true)}
            className={
              filterButton.focus === "open"
                ? "table-guest-static-filter filter-button-active"
                : "table-guest-static-filter"
            }
          >
            {t("guestListBoard.filterOpen")} (
            {filtering(groupsCopy, "is_opened", true)?.length})
          </p> */}
            </div>
            {selectGroups.length >= 2 && (
              <select
                className="input input-actions"
                onChange={(e) => handleActions(e.target.value)}
                value={actionTitle}
              >
                {actionsType.map((action, key) => (
                  <option value={action.type} key={action.type + key}>
                    {action.name}
                  </option>
                ))}
              </select>
            )}
          </div>
          <div className="table-guests">
            <div className="table-guest-container table-guest-title">
              <div className="table-guest-email">
                <p
                  onClick={(e) => orderList(e, "email")}
                  className={order === "email" ? " order-active" : ""}
                >
                  {t("guestListBoard.tableTitleEmail")}
                </p>
              </div>
              <CheckBoxButton
                onClick={(e) => selectAllGroups(e)}
                className="table-guest-check"
                id="table-guest-check"
                checked={
                  selectGroups?.length === groups?.length && groups?.length
                    ? true
                    : false
                }
              />
              <div className="table-guest-name ">
                <p
                  onClick={(e) => orderList(e, "name")}
                  className={order === "name" ? "order-active" : ""}
                >
                  {t("guestListBoard.tableTitleName")}
                </p>
              </div>
              <div className="table-guest-phone">
                <p>{t("guestListBoard.tableTitlePhone")}</p>
              </div>
              <div className="table-guest-modified">
                <p
                  onClick={(e) => orderList(e, "updated")}
                  className={order === "updated" ? "order-active" : ""}
                >
                  {t("guestListBoard.tableTitleModified")}
                </p>
              </div>
              <div className="table-guest-state">
                <p>{t("guestListBoard.tableTitleState")}</p>
              </div>
              <div className="table-guest-numbers-menbers">
                <p>{t("guestListBoard.tableTitleNumbersMenbers")}</p>
              </div>

              <div className="table-guest-younger">
                <p>{t("guestListBoard.tableTitleYounger")}</p>
              </div>
              <div className="table-guest-actions">
                <p>{t("guestListBoard.tableTitleActions")}</p>
              </div>
            </div>
            <div className="table-guest-body-container">
              {groups.length === 0 && !loading && (
                <Empty tittle={t("guestListBoard.emptyTable")} />
              )}

              {!loading ? (
                groups?.map((group, index) => {
                  return (
                    <div
                      className={
                        selectGroups?.includes(group.uuid)
                          ? "table-guest-body group-selected"
                          : "table-guest-body"
                      }
                      key={index}
                    >
                      <div className="table-guest-container ">
                        <p
                          onClick={(e) =>
                            e.target.parentNode.classList.toggle("group-active")
                          }
                          className="table-guest-email"
                        >
                          {group.email}
                        </p>
                        <CheckBoxButton
                          checked={selectGroups?.includes(group) ? true : false}
                          className="table-guest-check"
                          id={`table-guest-check-${index}`}
                          onClick={(e) => selectGroup(e, group)}
                        />
                        <p className="table-guest-name">{group.name}</p>
                        <p className="table-guest-phone">
                          {group.phone_number}
                        </p>
                        <p className="table-guest-modified">
                          {group.updated.slice(0, 10)}{" "}
                          {group.updated.slice(11, 16)}
                        </p>
                        <div className="table-guest-state">
                          {selectStateGroup(group)}
                        </div>
                        <p className="table-guest-numbers-menbers">
                          {group.guests?.length}
                          <Icon icon="bi:people-fill" />
                        </p>

                        <p className="table-guest-younger">
                          {
                            group?.guests.filter(
                              (guest) => guest.is_under_age === true
                            ).length
                          }
                        </p>
                        <div className="table-guest-actions">
                          {group?.is_sent && (
                            <Icon
                              icon="ion:eye"
                              onClick={() => {
                                openUrl(group.uuid, uuid);
                              }}
                            />
                          )}
                          {!group?.is_sent && (
                          <Icon
                            icon="mdi:whatsapp"
                            style={{color: "c0c0c0"}}
                            onClick={() => {
                              setModalWhatsapp(true)
                            }}
                          />
                              
                          )}
                          {group?.is_sent && (
                          <Icon
                            icon="mdi:whatsapp"
                            onClick={() => {
                                openWhatsapp(group.uuid, uuid, group.name, group.phone_number)
                            }}
                          />
                          )}

                          <Icon
                            icon="fluent:edit-48-filled"
                            onClick={() => {
                              setEditGroup(group);
                              setModalEditGroup(true);
                            }}
                          />

                          <Icon
                            icon="heroicons:trash-solid"
                            onClick={() => {
                              setDeleteGroup(group);
                              setModalDelete(!modalDelete);
                            }}
                          />
                        </div>
                      </div>
                      <ul className="table-guest-menbers">
                        {orderFilter(group?.guests, "id", "creasing").map(
                          (guest, index) => {
                            return (
                              <li key={index}>
                                {guest?.first_name == "null"
                                  ? "Datos desconocidos"
                                  : `${guest?.first_name} ${guest?.last_name}`}

                                <Icon
                                  icon={
                                    guest.is_under_age &&
                                    "ic:baseline-child-friendly"
                                  }
                                />
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  );
                })
              ) : (
                <Spinner typeColor={"purple"} />
              )}
            </div>
          </div>
          <div className="table-resumen">
            <p>{`${selectGroups.length} ${t("general.of")} ${
              groups.length
            }`}</p>
            <div className={"table-resumen-pagination-container"}>
              {/*<Icon
                  icon="material-symbols:keyboard-double-arrow-left-rounded"
                  className={"table-resumen-pagination-icon"}
                  onClick={() => handlePage("first")}
                />*/}
              {page.back && (
                <Icon
                  icon="material-symbols:arrow-back-ios-new-rounded"
                  className={"table-resumen-pagination-icon"}
                  onClick={() => handlePage("back")}
                />
              )}
              <p className={"table-resumen-pagination"}>
                {page.actual}
                {totalpages > 1 ? "/" : ""}
                {totalpages}
              </p>
              {page.next && (
                <Icon
                  icon="material-symbols:arrow-forward-ios-rounded"
                  className={"table-resumen-pagination-icon"}
                  onClick={() => handlePage("forward")}
                />
              )}
              {/* <Icon
                  icon="material-symbols:keyboard-double-arrow-right-rounded"
                  className={"table-resumen-pagination-icon"}
                  onClick={() => handlePage("last")}
                />*/}
            </div>
            <div className="table-resumen-states-container">
              {/* <div className="table-resumen-state-container">
              <p className="has-answer ">{t("guestListBoard.a")}</p>
              <p className="table-resumen-state-text">{t("guestListBoard.answered")}</p>
            </div> */}
              <div className="table-resumen-state-container">
                <p className="is-sent ">{t("guestListBoard.s")}</p>
                <p className="table-resumen-state-text">
                  {t("guestListBoard.sent")}
                </p>
              </div>
              <div className="table-resumen-state-container">
                <p className="is-not-sent ">{t("guestListBoard.ns")}</p>
                <p className="table-resumen-state-text">
                  {t("guestListBoard.notSent")}
                </p>
              </div>
              <div className="table-resumen-state-container">
                <p className="error-to-sent ">{t("guestListBoard.e")}</p>
                <p className="table-resumen-state-text">
                  {t("guestListBoard.error")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal onOpen={modalAddGroup} setOnOpen={setModalAddGroup} buttonOn>
        <AddGuest setOnOpen={setModalAddGroup} onFormAction={setReFetch} />
      </Modal>
      <Modal onOpen={modalEditGroup} setOnOpen={setModalEditGroup} buttonOn>
        <div className="add-groups-container">
          <AddGroup
            group={editGroup}
            setOnOpen={setModalEditGroup}
            onFormAction={setReFetch}
          />
        </div>
      </Modal>

      <Modal onOpen={modalGroupDelete} setOnOpen={setModalGroupDelete}>
        <div className="table-group-delete-modal">
          {allGroups.length || selectGroups.length ? (
            <>
              <div className="table-group-delete-img">
                <img src={trashIcon}></img>
              </div>
              <p className="table-group-delete-modal-title ">
                {t("guestListBoard.confirm")}
              </p>
              <p className="table-group-delete-modal-content ">
                {t("guestListBoard.removeMany")}
              </p>
              <p className="table-group-delete-modal-content ">
                {allGroups.length ? allGroups.length : selectGroups.length}{" "}
                {t("guestListBoard.contactSelected")}
              </p>
              <div className="table-group-delete-buttons">
                <EditButton
                  icon="heroicons-solid:x"
                  className="table-group-delete-cancel-button"
                  text={t("guestListBoard.cancel")}
                  onClick={() => setModalGroupDelete(false)}
                />

                <Button
                  className="table-group-delete-delete-button"
                  type="primary"
                  text={t("guestListBoard.accept")}
                  icon="bi:check-lg"
                  onClick={() =>
                    removeAllGroups(allGroups.length ? false : true)
                  }
                />
              </div>
            </>
          ) : (
            <>
              <div className="table-group-delete-img">
                <img src={trashIcon}></img>
              </div>

              <p
                className="table-group-delete-modal-title "
                style={{ textAlign: "center" }}
              >
                {t("guestListBoard.noGuest")}
              </p>

              <div className="table-group-delete-buttons">
                <Button
                  icon="heroicons-solid:x"
                  className="table-group-delete-cancel-button"
                  text={t("guestListBoard.understood")}
                  onClick={() => setModalGroupDelete(false)}
                />
              </div>
            </>
          )}
        </div>
      </Modal>
      <Modal onOpen={modalDelete} setOnOpen={setModalDelete}>
        <div className="table-group-delete-modal">
          <div className="table-group-delete-img">
            <img src={trashIcon}></img>
          </div>
          <p className="table-group-delete-modal-title ">
            {t("guestListBoard.confirm")}
          </p>
          <p className="table-group-delete-modal-content ">
            {t("guestListBoard.removeMessage")}
          </p>
          <div className="table-group-delete-buttons">
            <EditButton
              icon="heroicons-solid:x"
              className="table-group-delete-cancel-button"
              text={t("guestListBoard.cancel")}
              onClick={() => setModalDelete(false)}
            />
            <Button
              className="table-group-delete-delete-button"
              type="primary"
              text={t("guestListBoard.accept")}
              icon="bi:check-lg"
              onClick={() => removeGuest(deleteGroup.uuid)}
            />
          </div>
        </div>
      </Modal>
      <Modal onOpen={modalSubmit} setOnOpen={setModalSubmit} buttonOn>
        {alertSelectGroups && submitted === false ? (
          <div className="alert-select-groups-container">
            <p>{t("guestListBoard.alertSelectGroupsText")}</p>
            <img src={imgSelectGroups} />
            <Button
              type="button"
              text={t("guestListBoard.accept")}
              onClick={() => {
                setAlertSelectGroups(false);
                setModalSubmit(false);
              }}
            />
          </div>
        ) : (
          <SubmitInvitations
            setModalSubmit={setModalSubmit}
            submit={sendInvitations}
            selectGroups={selectGroups}
            submitted={submitted}
            setSubmitted={setSubmitted}
          />
        )}
      </Modal>
      <Modal onOpen={modalWhatsapp} setOnOpen={setModalWhatsapp} buttonOn>
          <div style={{marginTop: "15px"}}>{t("guestListBoard.whatsappError")}</div>
      </Modal>
    </div>
  );
};
export default GuestsListBoard;
