import { useEffect, useState } from "react";
import { useAppContext } from "../../context/state";
import { useNavigate } from "react-router-dom";
import DesignBoard from "./designBoard";
import InformationBoard from "./informationBoard";
import PreviewBoard from "./previewBoard";
import PollBoard from "../polls/pollBoard";
import "../../assets/css/creationBoard.css";
import GuestsListBoard from "./guestList/guestsListBoard";
import TrackingBoard from "./trackingBoard";
import Api from "../../services/invitationsApi";
import PollsApi from "../../services/pollsApi";
import InvitationsServices from "../../services/invitationsServices";
import UsersServices from "../../services/usersServices";
import UserApi from "../../services/usersApi";
import useCheckLogin from "../../hooks/useCheckLogin";
import Stepper from "./Stepper";
import useStepper from "../../hooks/useStepper";
import EditButton from "../general/editButton";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";

function CreationBoardFake(props) {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [isTemplateLoad, setIsTemplateLoad] = useState(false);
  const context = useAppContext();
  const [checkLogin] = useCheckLogin();
  const [designStep, setDesignStep] = useState(0);
  const stepperList = [
    t("creation.design"),
    t("creation.information"),
    t("creation.questionary"),
    t("creation.preview"),
    t("creation.guestsList"),
    t("creation.tracking"),
  ];
  const navigate = useNavigate();

  useEffect(() => {
    const recoverInvitation = async (templateId) => {
      const template = await Api.getTemplate(templateId);
      if (!template) {
        navigate("/");
        return;
      }

      if (UsersServices.isLogIn()) {
        let invitation = InvitationsServices.initEmptyInvitation(context.user);
        invitation.configuration = template.configuration;
        invitation.envelope_configuration = template.envelope_configuration;

        let user = context?.user;
        if (!user) {
          user = await UserApi.getCurrentUser();
          context.setUser(user);
        }
        invitation = { ...invitation, user: user.id, user_uuid: user.uuid };
        invitation = await Api.createInvitation(invitation);
        context.setSharedState({
          ...context.sharedState,
          invitation: invitation,
        });
        navigate(`/invitation-laboratory/${invitation.uuid}/`, {
          replace: true,
        });
        return;
      }

      const invitation = {
        uuid: uuid(),
        configuration: template.configuration,
        envelope_configuration: template.envelope_configuration,
      };
      context.setSharedState({
        invitation: invitation,
        isTrying: true,
      });
      setIsTemplateLoad(true);
    };

    if (!props.template) {
      navigate("/");
      return;
    }

    recoverInvitation(props.template);
  }, []);

  return (
    <div className="creation-board-container">
      <Stepper
        stepState={step}
        setStepState={setStep}
        stepperList={stepperList}
        setDesignStep={setDesignStep}
        designStep={designStep}
        currentStep={0}
        isTrying={true}
      />

      <div className="creation-board container ">
        {step === 0 && isTemplateLoad && (
          <DesignBoard
            designStep={{ designStep, setDesignStep }}
            isTrying={true}
          />
        )}
      </div>
    </div>
  );
}
export default CreationBoardFake;
