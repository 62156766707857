import { useEffect, useState } from "react";
import { useAppContext } from "../../context/state";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import useScrollbySize from "../../hooks/useScroll";
import Api from "../../services/invitationsApi";
import "../../assets/css/eventsDashboard.css";
import InvitationInformation from "../invitations/invitationInformation";
import Button from "../general/Button";
import Empty from "../general/Empty";
import useFilterObject from "../../hooks/useFilterObject";

function EventsDashboard() {
  const [currentInvitations, setCurrentInvitations] = useState([]);
  const [tagActive, setTagActive] = useState(1);
  const { orderFilter } = useFilterObject();
  const location = useLocation();
  const context = useAppContext();
  const { t } = useTranslation();
  const scroll = useScrollbySize(500, 600, "top")
  const FOLLOWING = 1;
  const FINISHED = 2;
  const DRAFTS = 3;
  const ALL = 4;

  useEffect(() => {
    const getMyInvitations = async () => {
      let myInvitations = [];
      myInvitations = await Api.getInvitations();
      context.setSharedState({
        ...context.sharedState,
        invitations: myInvitations,
      });

      if (
        myInvitations?.filter((invitation) => {
          const eventDate = new Date(invitation.date);
          return invitation.is_draft === false && eventDate >= new Date();
        }).length < 1 ||
        location.state === DRAFTS
      ) {
        setTagActive(DRAFTS);
      } else {
        setTagActive(FOLLOWING);
      }
    };
    getMyInvitations();
    scroll()
  }, []);

  useEffect(() => {
    const contextInvitations = context.sharedState?.invitations;
    let tempCurrentInvitations;

    if (tagActive === FOLLOWING) {
      setTagActive(FOLLOWING);

      tempCurrentInvitations = contextInvitations?.filter((invitation) => {
        const eventDate = new Date(invitation.date);

        return invitation.is_draft === false && eventDate >= new Date();
      });
    } else if (tagActive === FINISHED) {
      tempCurrentInvitations = contextInvitations?.filter((invitation) => {
        const eventDate = new Date(invitation.date);
        return invitation.is_draft === false && eventDate < new Date();
      });
    } else if (tagActive === DRAFTS) {
      tempCurrentInvitations = contextInvitations?.filter(
        (invitation) => invitation.is_draft === true,
      );
    } else {
      tempCurrentInvitations = contextInvitations;
    }

    return setCurrentInvitations(
      orderFilter(tempCurrentInvitations, "updated", "decreasing"),
    );
  }, [tagActive, context.sharedState.invitations]);

  return (
    <>
      <div className="events-dashboard">
        <div className="events-dashboard-header">
          <h1>{t("events.myEvents")}</h1>
          <div className="events-dashboard-buttons">
            <Link to="/templates">
              <Button
                type="primary"
                text={t("events.createInvitation")}
                icon="fa6-solid:plus"
              />{" "}
            </Link>
            {/*            
            <Link to="/templates">
              <Button text={t("events.selectTemplates")} />
            </Link> */}
          </div>
        </div>
        <div className="events-dashboard-body">
          <div className="events-dashboard-filters">
            <div
              className={
                tagActive === FOLLOWING
                  ? "events-dashboard-filter events-dashboard-filter-active"
                  : "events-dashboard-filter"
              }
              onClick={() => setTagActive(FOLLOWING)}
            >
              <p>{t("events.following")}</p>
            </div>
            <div
              className={
                tagActive === FINISHED
                  ? "events-dashboard-filter events-dashboard-filter-active"
                  : "events-dashboard-filter"
              }
              onClick={() => setTagActive(FINISHED)}
            >
              <p>{t("events.finished")}</p>
            </div>
            <div
              className={
                tagActive === DRAFTS
                  ? "events-dashboard-filter events-dashboard-filter-active"
                  : "events-dashboard-filter"
              }
              onClick={() => setTagActive(DRAFTS)}
            >
              <p> {t("events.drafts")}</p>
            </div>
            <div
              className={
                tagActive === ALL
                  ? "events-dashboard-filter events-dashboard-filter-active"
                  : "events-dashboard-filter"
              }
              onClick={() => setTagActive(ALL)}
            >
              <p>{t("events.all")}</p>
            </div>
          </div>

          <div className="invitation-info-container">
            {currentInvitations?.length ? (
              currentInvitations?.map((invitation, key) => {
                return (
                  <div key={key}>
                    <InvitationInformation invitationData={invitation} />
                  </div>
                );
              })
            ) : (
              <Empty tittle={t("events.empty")} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default EventsDashboard;
