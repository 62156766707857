// import editar from "../../assets/img/icons/edit.svg";

import { Icon } from "@iconify/react";

export default function EditButton({
  icon,
  color,
  text,
  colorText,
  onClick,
  type,
  iconSide,
  className,
  id,
  disabled,
}) {
  let generalStyles = {
    display: "flex",
    flexDirection: iconSide == "rigth" ? "row-reverse" : "row",
    gap: "10px",
    alignItems: "center",
    color: color,
    backgroundColor: "unset",
    border: "none",
    fontSize: "unset",
    cursor: "pointer",
    color: colorText ? `${colorText}` : `${color}`,
    fontSize: "16px",
    fontWeight: !className && 400,
    width: "max-content",
  };

  return (
    <button
      disabled={disabled}
      id={id && id}
      onClick={onClick}
      type={type}
      style={generalStyles}
      className={className}
    >
      {icon && <Icon icon={icon} color={color} />}
      {text}
    </button>
  );
}
