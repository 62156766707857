import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { ImagesGrid } from "polotno/side-panel";
import { getImageSize } from "polotno/utils/image";
import Api from "../../../services/invitationsApi";
import SidePanelTextInput from "./SidePanelTextInput";
import { UploadPanel } from "./uploadPanel";
import { useTranslation } from "react-i18next";

export default function ImagesGridPanel({ store, section }) {
  const { t } = useTranslation();
  const [images, setImages] = useState([]);
  const [uploadImages, setUploadImages] = useState([]);
  const [description, setDescription] = useState("");
  const [filters, setFilters] = useState({ section: section });
  const totalImages = [...uploadImages, ...images];
  const brandWithCredit = "unsplash";
  const getImages = async () => {
    let images = await Api.getAssets(filters);

    if (filters.search && filters.search.length > 1) {
      const third_party_images = await Api.getThirdPartyImages(filters);
      images = third_party_images.concat(images);
    }

    if (images?.length > 0) {
      setImages(images);
    }
  };

  const getInputValue = (event) => {
    const value = event.target.value;
    setDescription(value);
  };

  useEffect(() => {
    const getImagesByTitle = async () => {
      if (description?.length > 2) {
        const newFilters = { ...filters, search: description };
        setFilters({ ...newFilters });
      } else {
        const newFilters = { ...filters };
        delete newFilters.search;
        setFilters(newFilters);
      }
    };

    getImagesByTitle();
  }, [description]);

  useEffect(() => {
    getImages();
  }, [filters]);

  const addLine = (startHead, endHead, dash) => {
    store.activePage.addElement({
      type: "line",
      x: store.width / 2 - 300 / 2,
      y: store.height / 2,
      width: 400,
      height: 10,
      name: "", // name of element, can be used to find element in the store
      color: "black",
      rotation: 0,
      dash: [dash ? dash : 0], // array of numbers, like [5, 5]
      startHead: startHead ? startHead : "", // can be empty, arrow, triangle, circle, square, bar
      endHead: endHead ? endHead : "", // can be empty, arrow, triangle, circle, square, bar
      // can user select element?
      // if false, element will be "invisible" for user clicks
    });
  };

  return (
    <div className="custom-panel">
      <SidePanelTextInput getInputValue={getInputValue} />
      <UploadPanel setUploadImages={setUploadImages}></UploadPanel>
      {filters.section === 3 && (
        <>
          <p>{t("design.boardUI.sidePanel.lines")}</p>
          <div className="container-line">
            <div onClick={() => addLine()}>
              <Icon icon="pepicons-pop:line-x" width="50" height="50" />
            </div>

            <div onClick={() => addLine("", "", 5)}>
              <Icon icon="ant-design:dash-outlined" width="50" height="50" />
            </div>
            <div onClick={() => addLine("", "", 1)}>
              <Icon
                icon="ant-design:small-dash-outlined"
                width="50"
                height="50"
              />
            </div>
            <div onClick={() => addLine("", "arrow")}>
              <Icon icon="pajamas:arrow-up" width="50" height="50" rotate={1} />
            </div>
            <div onClick={() => addLine("arrow", "arrow")}>
              <Icon icon="octicon:arrow-both-16" width="50" height="50" />
            </div>
            <div onClick={() => addLine("", "triangle")}>
              <Icon
                icon="streamline:arrow-up-1-solid"
                width="50"
                height="50"
                rotate={1}
              />
            </div>
          </div>
          <p>{t("design.boardUI.sidePanel.shapes")}</p>
        </>
      )}

      <ImagesGrid
        getImageClassName={() => "side_panel_polotno_img"}
        images={totalImages}
        isLoading={totalImages.length ? false : true}
        getPreview={(image) => image?.source}
        onSelect={async (image, pos, element) => {
          let { width, height } = await getImageSize(image?.source);

          while (width > store.width || height > store.height) {
            width *= 0.8;
            height *= 0.8;
          }

          const x = (pos?.x || store.width / 2) - width / 2;
          const y = (pos?.y || store.height / 2) - height / 2;
          store.activePage.addElement({
            type: image.type === "1" ? "svg" : "image",
            src: image.source,
            width,
            height,
            x,
            y,
          });
        }}
        shadowEnabled={true}
        getCredit={(image) =>
          image?.source.includes(brandWithCredit) && (
            <p>
              Photo by{" "}
              <span className="side_panel_polotno_credit--remarks">
                Unsplash
              </span>
            </p>
          )
        }
        rowsNumber={!totalImages.length ? 1 : 2}
        crossOrigin="anonymous"
      />
    </div>
  );
}
