import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useScrollbySize from "../../hooks/useScroll";
import Api from "../../services/paymentsApi";
import Datalayer from "../../services/datalayer";
import "../../assets/css/buy-invitations.css";
import { Title } from "chart.js";
import { isEuropean } from "../../services/utils";
import Button from "../general/Button";
import { totallyFree } from "../../constants/payments";

function BuyInvitations(props = { toUrl: "/my-account/my-events", groups: 0 }) {
  const [products, setProducts] = useState([]);
  const [currentPriceId, setCurrentPriceId] = useState();
  const [currentCouponId, setCurrentCouponId] = useState();
  // const [isSelected, setIsSelected] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const scroll = useScrollbySize(500, 600, "top");
  const eurosCurrency = isEuropean();

  useEffect(() => {
    const getCurrentProducts = async () => {
      const currentProducts = await Api.getProducts(eurosCurrency);
      if (!currentProducts) {
        return;
      }
      setProducts(currentProducts);
      return;
    };

    getCurrentProducts();
    scroll();
  }, []);

  const changeProduct = (event) => {
    const ids = event.target.value.split(",");
    setCurrentPriceId(ids[0]);
    setCurrentCouponId(ids[1]);
  };

  const startSession = async () => {
    if (currentPriceId) {
      Datalayer.push("payment_pending");
      const currency = eurosCurrency ? "EUR" : "USD";
      const [url, paymentUuid] = await Api.createSessionUrl(
        currentPriceId,
        props.toUrl,
        currentCouponId,
        currency
      );
      if (currentPriceId.search(totallyFree) == 0) {
        navigate(`/payment/${paymentUuid}/success/?next=${props.toUrl}`, {
          replace: false,
        });
      } else {
        if (url.indexOf("stripe") !== -1) {
          window.location.replace(url);
        }
      }
    }
  };

  return (
    <div className="buy-invitations__main">
      <div className="buy-invitations__body container">
        <p className="buy-invitations__title">
          {t("payment.shoppingCartTitle")}
        </p>
        <p className="buy-invitations__text">
          No pagarás por el número de invitados si no por el número de
          invitaciones enviadas, es decir, si en una invitación cuentas con 5
          personas de una misma familia, únicamente pagarás por un envío
          &#128512;.
          <br />
          <br />
          <p>
            Es normal que no tengas claro cuántas invitaciones vas a enviar. ¡No
            te preocupes! Selecciona un número estimado (intenta ir a mínimos) y
            luego siempre tendrás tiempo de realizar una nueva compra según el
            número de invitados finales.
          </p>
        </p>
        <p className="buy-invitations__subtitle">
          {t("payment.shoppingCartSubtitle")}
        </p>

        <div className="buy-invitations__select-container">
          <select
            className="buy-invitations__select-body"
            onChange={(event) => changeProduct(event)}
          >
            <option
              className="buy-invitations__select-option"
              value=""
              defaultValue
            >
              ---
            </option>
            {products.map((product) => {
              const text = `${product.name} ${product.total} ${
                eurosCurrency ? "€" : "$"
              }`;
              return (
                <option
                  className="buy-invitations__select-option"
                  key={product.price_id}
                  value={`${product.price_id},${product.coupon}`}
                >
                  {text}
                </option>
              );
            })}
          </select>
        </div>
        <Button
          type={"primary"}
          onClick={startSession}
          text={t("payment.buy")}
        />
      </div>
    </div>
  );
}
export default BuyInvitations;
