import AddPollElement from "./addPollElement";
import PollQuestions from "./pollQuestions";
import QuestionDetails from "./questionDetails";

function PollCreation() {
  const changeSettings = () => {};

  return (
    <div className="poll-creation-container">
      <AddPollElement />
      <PollQuestions changeSettings={changeSettings} />
      {/* <QuestionDetails /> */}
    </div>
  );
}
export default PollCreation;
