import CheckBoxButton from "../general/CheckBoxButton";
function TermsSelectForm(
  props = { possibilities: [], answers: [], guestId: "" }
) {
  return (
    <div>
      {props.possibilities.map((possibility) => {
        const answer = props.answers.filter(
          (answers) => possibility.id == answers.option
        );
        const handleClick = async (e) => {
          await props.modifyMulti(
            possibility.question,
            possibility.id,
            answer.length > 0 ? answer.id : null,
            e.target.checked
          );
        };

        return (
          <div key={possibility.uuid} className="invitation-question-checkbox">
            <CheckBoxButton
              id={`${possibility.uuid}${props.guestId}`}
              type="checkbox"
              onClick={handleClick}
              name={`${possibility.question}`}
              required={props.question.required}
              checked={answer.length > 0}
            />

            <label htmlFor={`${possibility.uuid}${props.guestId}`}>
              {possibility.text}
            </label>
          </div>
        );
      })}
    </div>
  );
}
export default TermsSelectForm;
