import "../../assets/css/profilePage.css";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { useAppContext } from "../../context/state";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useScrollbySize from "../../hooks/useScroll";
import Api from "../../services/usersApi";
import CampForm from "../general/CampForm";
import Button from "../general/Button";
import Modal from "../general/modal";
import EditButton from "../general/editButton";
import FormChangePass from "../general/FormChangePass";
import CloseAccount from "../general/CloseAccount";
import countries from "../../constants/countries";
import languages from "../../constants/languages";
import timeZones from "../../constants/timeZones";

function ProfilePageComponent() {
  const navigate = useNavigate();
  const [inEdit, setInEdit] = useState(false);
  const [view, setView] = useState(false);
  const [myModal, setMyModal] = useState(null);
  const [form, setForm] = useState({});
  const [newData, setNewData] = useState({});
  const [user, setUser] = useState({});
  const selectCamps = ["time_zone", "language", "country"];
  const scroll = useScrollbySize(400, 600, "top")
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    resetField,

    formState: { errors, isValid },
  } = useForm();
  const context = useAppContext();

  useEffect(() => {
    const getCurrentUser =() => {
      let tempUser;
      // if (context.sharedState?.user === null) {
      tempUser = context.user
      setUser(tempUser);
      // } else {
      // tempUser = context?.user;
      // setUser(context?.user);
      // }

      const myForm = {
        first_name: tempUser?.first_name,
        last_name: tempUser?.last_name,
        country: tempUser?.country,
        town: tempUser?.town,
        language: tempUser?.language,
        phone_number: tempUser?.phone_number,
        time_zone: tempUser?.time_zone,
      };
      // context.setSharedState({
      //   ...context.sharedState,
      //   user: { ...myForm },
      // });
      setForm(myForm);
    };

    getCurrentUser();
    scroll()
  }, [context.user]);

  const saveData = async () => {
    if (inEdit && "uuid" in user && isValid) {
      const myForm = await Api.changeUser(user.uuid, newData);

      setUser(myForm.data);
      const prueba = {
        first_name: myForm.data.first_name,
        last_name: myForm.data.last_name,
        country: myForm.data.country,
        town: myForm.data.town,
        language: myForm.data.language,
        phone_number: myForm.data.phone_number,
        time_zone: myForm.data.time_zone,
      };

      context.setUser(myForm.data)
      setForm(prueba);

      setInEdit(false);
    }
  };

  const openModal = (typeModal) => {
    switch (typeModal) {
      case "account":
        setMyModal(
          <Modal onOpen={true} setOnOpen={setView} buttonOn={true}>
            <CloseAccount setView={setView} />
          </Modal>
        );
        break;
      case "pass":
        setMyModal(
          <Modal onOpen={true} setOnOpen={setView} buttonOn={true}>
            <FormChangePass setOnOpen={setView} />
          </Modal>
        );
        break;
    }
    setView(true);
  };

  const onSubmit = (data) => {
    const myForm = { ...form };
    Object.entries(data).map((e) => {
      myForm[e[0]] = e[1];
    });
    setNewData(myForm);
  };

  const getOptions = (type) => {
    switch (type) {
      case "time_zone":
        return timeZones;
      case "language":
        return languages;
      case "country":
        return countries;
      default:
        return [];
    }
  };

  return (
    <div className="profilepage__main">
      <h1 className="profilepage__title">{t("profilePage.title1")}</h1>

      <div className="profilepage__container">
        <div className="profilepage__header">
          <p className="profilepage__subtitle">{t("profilePage.subtitle1")}</p>
          {!inEdit && (
            <EditButton
              onClick={() => {
                setInEdit(!inEdit);
                document.getElementById("form").reset();
              }}
              text={
                inEdit ? t("profilePage.cancelEdit") : t("profilePage.edit")
              }
              icon={
                inEdit ? "fluent:dismiss-20-filled" : "fluent:edit-48-filled"
              }
              color="#E33D8C"
              colorText="#361A3C"
            />
          )}
        </div>
        <div>
          <form
            id="form"
            className="profilepage__container-body"
            onChange={handleSubmit(onSubmit)}
          >
            {Object.entries(form).map((e, i) => {
              return (
                <CampForm
                  errors={errors}
                  watch={watch}
                  key={i}
                  campValue={e[0]}
                  defaultValue={e[1]}
                  inEdit={inEdit}
                  isSelect={selectCamps.includes(e[0])}
                  options={getOptions(e[0])}
                  setForm={setForm}
                  form={form}
                  register={register}
                  resetField={resetField}
                />
              );
            })}
          </form>
          {inEdit ? (
            <div className="profilepage__save-buttons">
              <Button
                onClick={saveData}
                text={t("general.save")}
                icon="material-symbols:save-outline"
                color="#E33D8C"
                colorText="#361A3C"
                type="primary"
              />
              <Button
                onClick={() => {
                  setInEdit(!inEdit);
                  document.getElementById("form").reset();
                }}
                text={
                  inEdit ? t("profilePage.cancelEdit") : t("profilePage.edit")
                }
                icon={
                  inEdit ? "fluent:dismiss-20-filled" : "fluent:edit-48-filled"
                }
                color="#E33D8C"
                colorText="#361A3C"
              />
            </div>
          ) : null}{" "}
        </div>
        <div className="total-invitation-profile-container">
          <div className="profilepage__header">
            <p className="profilepage__subtitle">
              {t(`profilePage.totalInvitationsTittle`)}
            </p>{" "}
            <EditButton
              icon="fa6-solid:plus"
              text={t("payment.buy")}
              onClick={() => navigate("/buy-invitations")}
            />
          </div>{" "}
          <div className="campform__main">
            <p className="campform__text campform__campvalue">
              {t(`profilePage.totalInvitations`)}:
            </p>

            <p className="campform__text campform__defaultvalue">
              {user?.total_invitations}
            </p>
          </div>
        </div>
        <div className="profilepage__center">
          <Button
            text={t("profilePage.changePassword")}
            onClick={() => openModal("pass")}
          />
        </div>
      </div>
      <h1 className="profilepage__title">{t("profilePage.title2")}</h1>

      <div className="profilepage__container-align_left">
        <div className="profilepage__container profilepage__container-secondary">
          <p className="profilepage__subtitle">{t("profilePage.subtitle2")}</p>
          <div className="profilepage__container-body profilepage__container-column">
            <button
              className="profilepage__button-container"
              onClick={() => openModal("account")}
            >
              {t("profilePage.remove")}
            </button>
          </div>
        </div>
      </div>

      {view ? myModal : null}
    </div>
  );
}
export default ProfilePageComponent;
