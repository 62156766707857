import { useAppContext } from "../../context/state";
import QuestionMulti from "./questionMulti";
import QuestionSingle from "./questionSingle";
import QuestionTerms from "./questionTerms";
import Api from "../../services/pollsApi";
import { Icon } from "@iconify/react";
import Modal from "../general/modal";
import { useState } from "react";
import Button from "../general/Button";
import EditButton from "../general/editButton";
import { useTranslation } from "react-i18next";
import Empty from "../general/Empty";

function PollQuestions(props = {}) {
  const context = useAppContext();
  const [modalDelete, setModalDelete] = useState(false);
  const [activeQuestion, setActiveQuestion] = useState();
  const { t } = useTranslation();

  const removeQuestion = async (id, uuid) => {
    const questions = context.sharedState.questions;
    const question = questions.filter((question) => question.id === id)[0];
    const filteredQuestions = questions.filter(
      (question) => question.id !== id
    );

    context.setSharedState({
      ...context.sharedState,
      questions: filteredQuestions,
    });
    const possibilities = context.sharedState.questionsPossibilities.filter(
      (possibility) => possibility.question === id
    );
    if (possibilities.length > 0) {
      await Promise.allSettled(
        possibilities?.map(async (possibility) => {
          await Api.removePosibility(
            context.sharedState.invitation.uuid,
            possibility.uuid
          );
        })
      );
    }
    await Api.removeQuestion(
      context.sharedState.invitation.uuid,
      question.uuid
    );
    filteredQuestions.map((question, index) => {
      const payload = {
        order: index + 1,
      };
      Api.changeQuestion(
        payload,
        context.sharedState.invitation.uuid,
        question.uuid
      );
      question.order = index + 1;
      return question;
    });
    setModalDelete(false);
  };

  const goUp = (id) => {
    const questions = context.sharedState.questions.sort(
      (firstQuestion, secondQuestion) => {
        return firstQuestion.order - secondQuestion.order;
      }
    );
    let firstQuestion = questions.filter((question) => id === question.id)[0];
    let secondQuestion = questions.filter(
      (question) => question.order === firstQuestion.order - 1
    )[0];
    firstQuestion.order--;
    secondQuestion.order++;
    let filteredQuestions = questions.filter(
      (question) =>
        question.id !== firstQuestion.id && question.id !== secondQuestion.id
    );
    filteredQuestions.push(firstQuestion);
    filteredQuestions.push(secondQuestion);
    filteredQuestions = filteredQuestions.sort(
      (firstQuestion, secondQuestion) => {
        return firstQuestion.order - secondQuestion.order;
      }
    );
    context.setSharedState({
      ...context.sharedState,
      questions: filteredQuestions,
    });

    saveOrder(firstQuestion, secondQuestion);
  };

  const goDown = (id) => {
    const questions = context.sharedState.questions.sort(
      (firstQuestion, secondQuestion) => {
        return firstQuestion.order - secondQuestion.order;
      }
    );
    let firstQuestion = questions.filter((question) => id === question.id)[0];
    let secondQuestion = questions.filter(
      (question) => question.order === firstQuestion.order + 1
    )[0];
    firstQuestion.order++;
    secondQuestion.order--;
    let filteredQuestions = questions.filter(
      (question) =>
        question.id !== firstQuestion.id && question.id !== secondQuestion.id
    );
    filteredQuestions.push(firstQuestion);
    filteredQuestions.push(secondQuestion);
    filteredQuestions = filteredQuestions.sort(
      (firstQuestion, secondQuestion) => {
        return firstQuestion.order - secondQuestion.order;
      }
    );
    context.setSharedState({
      ...context.sharedState,
      questions: filteredQuestions,
    });

    saveOrder(firstQuestion, secondQuestion);
  };

  const saveOrder = (firstQuestion, secondQuestion) => {
    const payloadFirst = {
      order: firstQuestion.order,
    };
    Api.changeQuestion(
      payloadFirst,
      context.sharedState.invitation.uuid,
      firstQuestion.uuid
    );

    const payloadSecond = {
      order: secondQuestion.order,
    };
    Api.changeQuestion(
      payloadSecond,
      context.sharedState.invitation.uuid,
      secondQuestion.uuid
    );
  };

  return (
    <div className="questions-container">
      {context.sharedState.questions.length == 0 && (
        <Empty tittle={t("poll.empty")} />
      )}
      {context.sharedState.questions
        ?.sort((firstQuestion, secondQuestion) => {
          return firstQuestion.order - secondQuestion.order;
        })
        .map((question, index) => {
          const type = parseInt(question?.type);
          return (
            <div className="question-container" key={question.id}>
              <div className="question">
                {type < 4 && (
                  <QuestionMulti question={question} index={index} />
                )}
                {type > 3 && type !== 11 && (
                  <QuestionSingle question={question} index={index} />
                )}
                {type === 11 && (
                  <QuestionTerms question={question} index={index} />
                )}
              </div>
              <div className="actions-container">
                <div className="actions">
                  <Icon
                    className="actions-poll-icon"
                    icon="fluent:delete-20-filled"
                    onClick={() => {
                      setActiveQuestion(question);
                      setModalDelete(true);
                    }}
                  />
                </div>

                <div className="down-up-icons-container">
                  {question.order > 1 && (
                    <Icon
                      className="actions-poll-icon"
                      icon="fa6-solid:arrow-up"
                      onClick={() => goUp(question.id)}
                    />
                  )}
                  {question.order !== context.sharedState.questions.length && (
                    <Icon
                      className="actions-poll-icon"
                      icon="fa6-solid:arrow-down"
                      onClick={() => goDown(question.id)}
                    />
                  )}
                </div>
              </div>
              <Modal onOpen={modalDelete} setOnOpen={setModalDelete}>
                <div className="moda-delete-question-container">
                  <p className="table-group-delete-modal-title ">
                    {t("poll.deleteTitle")}
                  </p>
                  <p className=" table-group-delete-modal-body">
                    {t("poll.deleteBody")}
                  </p>
                  <div className="table-group-delete-buttons">
                    <EditButton
                      icon="heroicons-solid:x"
                      className="table-group-delete-cancel-button"
                      text={t("poll.noDelete")}
                      onClick={() => setModalDelete(false)}
                    />
                    <Button
                      className="table-group-delete-delete-button"
                      type="primary"
                      text={t("poll.delete")}
                      icon="bi:check-lg"
                      onClick={() =>
                        removeQuestion(activeQuestion.id, activeQuestion.uuid)
                      }
                    />
                  </div>
                </div>
              </Modal>
            </div>
          );
        })}
    </div>
  );
}
export default PollQuestions;
