import { useState, useEffect } from "react";
import { useAppContext } from "../../src/context/state";
import InvitationsServices from "../services/invitationsServices";

const usePreviewEmail = ({ limit, currentStep }) => {
  const context = useAppContext();
  const [step, setStep] = useState(currentStep);
  const [stepCondition, setStepCondition] = useState(0);

  const conditions = () => {
    const currentInvitation = context.sharedState?.invitation;

    if (
      currentInvitation?.configuration?.pages === undefined ||
      currentInvitation?.envelope_configuration?.pages === undefined
    ) {
      return setStepCondition(0);
    } else if (
      !currentInvitation?.email_topic?.length ||
      currentInvitation?.email_topic === undefined
    ) {
      return setStepCondition(1);
    } else if (currentInvitation != null) {
      return setStepCondition(limit);
    }
  };

  const oneStep = (value) => {
    if (value <= stepCondition) {
      return setStep(value);
    }
  };
  const next = async (value, limit) => {
    // if (value === 0) {
    //   // await InvitationsServices.saveDesign(
    //   //   context.sharedState.invitation,
    //   //   context.invitationStore,
    //   //   context.envelopeStore
    //   // );
    // }
    if (value < stepCondition) {
      return value < limit && setStep(++value);
    }
  };
  const previous = (value) => {
    if (value <= stepCondition) {
      return value > 0 && setStep(--value);
    }
  };

  useEffect(() => {
    conditions();
  }, [context.sharedState.invitation, oneStep, next, previous]);

  return {
    step,
    oneStep,
    next,
    previous,
    stepCondition,
  };
};
export default usePreviewEmail;
