import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { AppWrapper } from "./context/state";
import { MapProvider } from "./context/maps";
import CreationDesignPage from "./pages/invitations/creationDesignPage";
import CreationDesignFakePage from "./pages/invitations/creationDesignFakePage";
import BuyInvitationsPage from "./pages/payments/buyInvitationsPage";
import SelectTemplatePage from "./pages/invitations/selectTemplatePage";
import DashboardPage from "./pages/users/dashboardPage";
import Footer from "./components/general/footer";
import Header from "./components/general/header";
import NotFoundPage from "./pages/general/notFoundPage";
import RecoveryPasswordPage from "./pages/users/recoveryPasswordPage";
import PaymentSuccessPage from "./pages/payments/paymentSuccessPage";
import PaymentCanceledPage from "./pages/payments/paymentCanceledPage";
import ProfilePage from "./pages/users/profilePage";
import SignInPage from "./pages/users/signInPage";
import SignOutPage from "./pages/users/signOutPage";
import SignUpPage from "./pages/users/signUpPage";
import VerifyAccountPage from "./pages/users/verifyAccountPage";
import VerifiyingPage from "./pages/users/verifiyingPage";
import InvitationDesignBoard from "./components/invitations/invitationDesignBoard";
import ContactUs from "./pages/general/contactUs";
import InvitationPage from "./pages/invitations/invitationPage";
import MyAccount from "./pages/users/myAccount";
import FaqsPage from "./pages/users/faqsPage";
import Spinner from "./components/general/Spinner";
import AlertUX from "./components/general/AlertUX";
/* REMOVE - FREE INVITATIONS CAMPAIGN */
import TryKolibirdFreePage from "./pages/tryKolibirdFreePage";
/* REMOVE - FREE INVITATIONS CAMPAIGN */

function App() {
  return (
    <Suspense fallback={<Spinner />}>
      <AppWrapper>
        <Header />
        <AlertUX />
        <MapProvider>
          <div className="App">
            <Routes>
              <Route path="/" element={<SignInPage />} />
              {/* REMOVE - FREE INVITATIONS CAMPAIGN */}
              <Route
                path="/prueba-kolibird-gratis"
                element={<TryKolibirdFreePage />}
              />
              {/* REMOVE - FREE INVITATIONS CAMPAIGN */}
              <Route path="/templates" element={<SelectTemplatePage />} />
              <Route
                path="/try-kolibird"
                element={<CreationDesignFakePage />}
              />
              <Route
                path="/invitation-laboratory/:id"
                element={<CreationDesignPage />}
              />
              <Route path="/testing" element={<InvitationDesignBoard />} />
              <Route path="/sign-in" element={<SignInPage />} />
              <Route path="/sign-up" element={<SignUpPage />} />
              <Route path="/sign-out" element={<SignOutPage />} />
              <Route
                path="/recovery-password"
                element={<RecoveryPasswordPage />}
              />

              <Route path="/verify-account" element={<VerifyAccountPage />} />

              <Route path="/verifiying/:uuid" element={<VerifiyingPage />} />

              <Route path="/buy-invitations" element={<BuyInvitationsPage />} />
              <Route
                path="/payment/:uuid/success"
                element={<PaymentSuccessPage />}
              />
              <Route
                path="/payment/:uuid/canceled"
                element={<PaymentCanceledPage />}
              />
              <Route
                path="/invitation/:idInvitation/group/:idGroup"
                element={<InvitationPage />}
              />
              <Route
                path="/invitation/:idInvitation"
                element={<InvitationPage />}
              />
              <Route path="/tutorials" element={<FaqsPage />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/my-events" element={<DashboardPage />} />
              <Route path="/my-account" element={<MyAccount />}>
                <Route index element={<DashboardPage />} />
                <Route path="profile" element={<ProfilePage />} />
                <Route path="my-events" element={<DashboardPage />} />
                <Route path="tutorials" element={<FaqsPage />} />
                <Route path="contact-us" element={<ContactUs />} />
              </Route>
              <Route path="/*" element={<NotFoundPage />} />
            </Routes>
          </div>
        </MapProvider>
        <Footer />
      </AppWrapper>
    </Suspense>
  );
}
export default App;
