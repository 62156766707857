import { useEffect } from "react";
import useCheckLogin from "../../hooks/useCheckLogin";
import CreationBoard from "../../components/invitations/creationBoard";
import { useAppContext } from "../../context/state";
import useQuery from "../../hooks/useQuery";

function CreationDesignPage() {
  const query = useQuery();
  const { user } = useAppContext();
  const [checkLogin] = useCheckLogin();

  useEffect(() => {
    const check = async () => {
      await checkLogin();
    };
    check();
  }, []);

  return (
    <>
      {user && (
        <CreationBoard
          step={query.get("step") !== null ? parseInt(query.get("step")) : 0}
        />
      )}
    </>
  );
}
export default CreationDesignPage;
