import { useState } from "react";
import { hasLoadedNamespace } from "i18next";
import { useAppContext } from "../../../context/state";
import { useTranslation } from "react-i18next";
import RadioButton from "../../general/RadioButton";
import OneGuest from "./oneGuest";
import AddGroup from "./addGroup";
import ImportGuests from "./importGuests";

const AddGuest = ({ setOnOpen, onFormAction }) => {
  const context = useAppContext();
  const [addOnePerson, setAddOnePerson] = useState(true);
  const [newContact, setNewContact] = useState(true);
  const { t } = useTranslation();

  return (
    <div className="add-groups-container">
      <h3>{t("addGuest.title")}</h3>
      <div className="type-add-guest">
        <p
          className={
            newContact
              ? "events-dashboard-filter-active add-new-contact-type"
              : "add-new-contact-type"
          }
          onClick={() => setNewContact(true)}
        >
          {t("addGuest.newContact")}
        </p>
        <p
          className={
            !newContact
              ? "events-dashboard-filter-active add-new-contact-type"
              : "add-new-contact-type"
          }
          onClick={() => setNewContact(false)}
        >
          {t("addGuest.importList")}
        </p>
      </div>
      {newContact ? (
        <>
          <div className="single-or-group-container">
            <div
              className="single-or-group"
              onClick={() => setAddOnePerson(true)}
            >
              <RadioButton name="typeOfGuest" id="one" checked={addOnePerson} />
              <label htmlFor="one">{t("addGuest.guestOption")}</label>
            </div>
            <div
              className="single-or-group"
              onClick={() => setAddOnePerson(false)}
            >
              <RadioButton
                name="typeOfGuest"
                id="group"
                checked={!addOnePerson}
              />
              <label htmlFor="group">{t("addGuest.groupOption")}</label>
            </div>
          </div>
          {addOnePerson ? (
            <OneGuest onFormAction={onFormAction} />
          ) : (
            <AddGroup onFormAction={onFormAction} />
          )}
        </>
      ) : (
        <ImportGuests setOpen={setOnOpen} />
      )}
    </div>
  );
};
export default AddGuest;
