import ProgressBar from "@ramonak/react-progress-bar";
import CustomChart from "../general/CustomChart";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../assets/css/trackingList.css";
import { useAppContext } from "../../context/state";
import groupApi from "../../services/groupsApi";
import pollsApi from "../../services/pollsApi";
import Api from "../../services/invitationsApi";
import GuestsListBoardComponent from "./guestList/gestListBoardComponent";
import { Icon } from "@iconify/react";
import Datalayer from "../../services/datalayer";
import { useLoadingFetch } from "../../hooks/useLoadingFetch";
import ApiGroups from "../../services/groupsApi";

function TrackingBoard() {
  const context = useAppContext();
  const { total_guests } = context.sharedState?.invitation;
  const [totalGroups, setTotalGroups] = useState(0);
  const [groups, setGroups] = useState([]);
  const [guests, setGuests] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [questionsPossibilities, setQuestionsPossibilities] = useState([]);
  const [renderQuestion, setRenderQuestion] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const [query, setQuery] = useState("");
  const { mayAssist, willAssist, wontAssist } = getAssistants();
  const { loading, fetch } = useLoadingFetch(groupApi.getGroups);
  const chartData = [willAssist, wontAssist, mayAssist];
  const { t } = useTranslation();
  const [page, setPage] = useState({
    actual: 1,
    next: null,
    back: null,
  });

  useEffect(() => {
    Datalayer.push("tracking_step");
  }, []);

  useEffect(() => {
    const fetchGroups = async () => {
      if (context.sharedState.invitation) {
        const invitationId = context.sharedState.invitation?.uuid;
        const groups = await fetch(invitationId, query);
        const nextPage = groups?.next;
        const previousPage = groups?.previous;
        setPage({
          actual: 1,
          next: nextPage,
          back: previousPage,
        });
        setTotalGroups(groups.count);
        extractGroupsAndGuests(groups.results);
      }
    };
    fetchGroups();
  }, [context.sharedState.invitation, query]);

  useEffect(() => {
    async function fetchData() {
      if (context.sharedState.invitation) {
        const invitationId = context.sharedState.invitation?.uuid;
        const questions = await pollsApi.getQuestions(invitationId);
        const possibilities = await pollsApi.getPossibilities(invitationId);
        setQuestions(questions);
        setQuestionsPossibilities(possibilities);
      }
    }
    fetchData();
  }, [context.sharedState.invitation]);

  useEffect(() => {
    getRenderQuestions(questions);
  }, [questions]);

  const handleQuery = async (url, newCount = 0) => {
    try {
      const groups = await groupApi.getGroupsQuery(url);
      const nextPage = groups?.next;
      const previousPage = groups?.previous;
      setPage({
        actual: page.actual + newCount,
        next: nextPage,
        back: previousPage,
      });
      extractGroupsAndGuests(groups.results);
    } catch {}
  };

  const extractGroupsAndGuests = (allGroups) => {
    const guests = allGroups.reduce((allGuests, group) => {
      if (group.guests) {
        return [...allGuests, ...group.guests];
      }
      return allGuests;
    }, []);
    setGroups(allGroups);
    setGuests(guests);
    let contextState = context.sharedState;
    contextState.guests = guests;
    context.setSharedState(contextState);
  };

  function getAssistants() {
    const result = {
      mayAssist: context.sharedState?.invitation?.no_answer,
      willAssist: context.sharedState?.invitation?.attend,
      wontAssist: context.sharedState?.invitation?.not_attend,
    };
    return result;
  }
  const getRenderQuestions = (items) => {
    const type1 = items.filter((item) => item.type === "1");
    const type2 = items.filter((item) => item.type === "2");
    const type3 = items.filter((item) => item.type === "3");
    const allFilterQuestions = [...type1, ...type2, ...type3];
    setRenderQuestion(allFilterQuestions);
    setSelectedQuestion(allFilterQuestions[0]);
  };

  const exportTracking = async () => {
    const invitationUuid = context.sharedState.invitation?.uuid;
    if (invitationUuid) {
      const payload = {
        invitation_uuid: invitationUuid,
      };

      const excelFile = await Api.getInvitationResults(payload);
      const url = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${excelFile}`;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${t("tracking.results")}${Date.now()}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    }
  };

  const printTracking = async () => {
    const invitationUuid = context.sharedState.invitation?.uuid;
    if (invitationUuid) {
      const payload = {
        invitation_uuid: invitationUuid,
      };

      const htmlResponse = await Api.getInvitationPrintResults(payload);
      let win = window.open(
        "",
        "Kolibird",
        `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=${window.innerWidth},height=${window.innerHeight}`
      );
      win.document.body.innerHTML = `<html><head><style type='text/css' media='print'>  @page { size: landscape; } table, th, td { border: 1px solid black; border-collapse: collapse; }</style>${atob(
        htmlResponse
      )}</html>`;
      win.print();
    }
  };

  const handleQuestion = (e) => {
    const question = JSON.parse(e.value);
    setSelectedQuestion(question);
  };

  function renderPossibilities(question) {
    const filteredPossibilities = questionsPossibilities?.filter(
      (possibility) => possibility?.question_uuid == question?.uuid
    );
    const result = filteredPossibilities?.map((possibility, i) => {
      const answer = possibility.counter ? possibility.counter : 0;
      if (question.type === "1" || question.type === "3") {
        return (
          <div key={i} className={`possibility-render`}>
            <p className="answer-count">{answer || 0}</p>
            <p className="possibility-render-text">{possibility.text}</p>
          </div>
        );
      }
      if (question.type === "2") {
        const totalAssistant = question.is_group_question
          ? total_guests > 0
            ? total_guests
            : 1
          : totalGroups > 0
          ? totalGroups
          : 1;
        return (
          <div key={i} className={`possibility-render`}>
            <p className="answer-percentage">{`${Number(
              (answer * 100) / totalAssistant
            ).toFixed(0)}%`}</p>
            <div className="answer-count">
              <p>{possibility.text}:</p>
              <div className="answer-bar">
                {i === 0 || i % 2 === 0 ? (
                  <>
                    <ProgressBar
                      bgColor=" #6B3378"
                      className="bar-wrapper"
                      barContainerClassName="bar-container"
                      completed={Number((answer * 100) / totalAssistant)}
                      customLabel=" "
                    />{" "}
                  </>
                ) : (
                  <ProgressBar
                    bgColor="#E33D8C"
                    className="bar-wrapper"
                    barContainerClassName="bar-container"
                    completed={
                      Number((answer * 100) / totalAssistant)
                      // : 0
                    }
                    customLabel=" "></ProgressBar>
                )}
              </div>
            </div>
          </div>
        );
      }
    });
    return question?.type === "1" || question?.type === "3" ? (
      <div className="question-first-type">{result}</div>
    ) : (
      <div className="question-second-type">{result} </div>
    );
  }

  return (
    <>
      <div className="traquing-container">
        <div className="tracking-analitics">
          <div className="chart-data">
            <h1>{t("tracking.summary")}</h1>
            <div className="chart-data-container">
              <div>
                <p id="chart-data-container-title">
                  {t("tracking.totalGuests")}
                </p>
                <p className="total-guests">{total_guests}</p>
                <p>{t("tracking.kidsIncluded")}</p>
              </div>
              <div className="chart-data-graphic">
                <CustomChart
                  type="Doughnut"
                  data={chartData}
                  chartLegend={[
                    `${t("tracking.attend")}: ${willAssist}`,
                    `${t("tracking.notAttend")}: ${wontAssist}`,
                    `${t("tracking.noResponse")}: ${
                      total_guests - willAssist - wontAssist
                    }`,
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="tracking-questions">
            <div>
              {renderQuestion && (
                <div>
                  <h4>{t("tracking.questions")}</h4>
                  {renderQuestion.length ? (
                    <select
                      defaultValue={renderQuestion[0]?.text}
                      className="input"
                      onChange={(event) => handleQuestion(event.target)}>
                      {renderQuestion?.map((question, i) => (
                        <option
                          key={`question${i}`}
                          value={JSON.stringify(question)}>
                          {question.text}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <p>{t("tracking.noQuestions")}</p>
                  )}
                </div>
              )}
              {renderPossibilities(selectedQuestion)}
              <p className="tracking-questions-advice">
                {t("tracking.adviceExport")}
              </p>
            </div>
            <div className="tracking-next-step-container">
              <h4>{t("tracking.nextSteps")}</h4>
              <div className="tracking-next-steps">
                <div className="tracking-next-step">
                  <div className="tracking-print" onClick={printTracking}>
                    <Icon icon="fluent:print-16-regular" />
                  </div>

                  <p>{t("tracking.print")}</p>
                </div>
                <div className="tracking-next-step">
                  <div className="tracking-export" onClick={exportTracking}>
                    <Icon icon="fluent:arrow-download-16-regular" />
                  </div>

                  <p>{t("tracking.export")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <GuestsListBoardComponent
          page={page}
          setQuery={setQuery}
          handleQuery={handleQuery}
          initialGroups={groups}
          totalGroups={totalGroups}
          loadingGroups={loading}
        />
      </div>
    </>
  );
}

export default TrackingBoard;
