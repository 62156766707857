import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Api from "../../services/invitationsApi";
import PollApi from "../../services/pollsApi";
import ApiGroup from "../../services/groupsApi";
import { PostCard } from "../../components/animations/PostCard";
import InvitationQuestionary from "../../components/pollAnswer/invitationQuestionary";
import useQuery from "../../hooks/useQuery";
import "../../assets/css/invitationPreview.css";
import Button from "../../components/general/Button";
import Modal from "../../components/general/modal";
import InvitationQr from "../../components/invitations/invitationQr";
import Spinner from "../../components/general/Spinner";
import { StaticGoogleMap, Marker } from "react-static-google-map";
export default function InvitationPage() {
  const params = useParams();
  const query = useQuery();
  const owner = query.get("owner");
  const { idInvitation, idGroup } = params;
  const [hasAnswer, setHasAnswer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [frontEnvelope, setFrontEnvelope] = useState(null);
  const [card, setCard] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [possibilities, setPossibilities] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [hasQr, setHasQr] = useState(false);
  const [isInTime, setIsInTime] = useState(true);
  const [guests, setGuests] = useState([
    {
      id: 1,
      uuid: "a695ed3c-faf2-4558-a0ef-9d58b6f78747",
      first_name: "Invitado 1",
    },
    {
      id: 2,
      uuid: "7ae33beb-b944-4254-8050-60cca297a551",
      first_name: "Invitado 2",
    },
  ]);
  const [isCardActive, setIsCardActive] = useState(true);
  const [groupName, setGroupName] = useState("");
  const [backgroundStyle, setBackgroundStyle] = useState({});
  const [logoImage, setLogoImage] = useState(
    "https://kolibird-resources.s3.eu-west-3.amazonaws.com/emailSignature/signatureKolibird.png"
  );
  const navigate = useNavigate();
  const { t } = useTranslation();
  const mapLocation = data?.has_map ? data?.place_address : null;
  const getInvitation = async () => {
    let invitation;
    if (idGroup) {
      invitation = await Api.getInvitationPreview(idGroup);
    } else {
      invitation = await Api.getInvitation(idInvitation);
    }

    if (invitation?.invitation_logo) {
      setLogoImage(invitation.invitation_logo);
    }

    if (invitation?.background_image) {
      setBackgroundStyle({
        backgroundImage: `url({$invitation.background_image})`,
      });
    }

    setCard(invitation?.configuration);
    setFrontEnvelope(invitation?.envelope_configuration);
    setData(invitation);
    let invitationQuestions = [];
    if (idGroup) {
      invitationQuestions = await PollApi.getQuestionsPreview(idInvitation);
    } else {
      invitationQuestions = await PollApi.getQuestions(idInvitation);
    }
    setQuestions(
      invitationQuestions.sort((oldItem, item) => {
        return oldItem.order - item.order;
      })
    );

    let invitationPossibilities;
    if (idGroup) {
      invitationPossibilities = await PollApi.getPossibilitiesPreview(
        idInvitation
      );
    } else {
      invitationPossibilities = await PollApi.getPossibilities(idInvitation);
    }

    setPossibilities(invitationPossibilities);
    if (idGroup !== undefined) {
      const invitationGuests = await Api.getGuestsResponse(idGroup);
      setGuests(invitationGuests);
      const invitationAnswers = await PollApi.getAnswers(idInvitation, idGroup);
      setAnswers(invitationAnswers);
    }

    if (invitation?.deadline_response) {
      const todayNow = new Date();
      const deadLine = new Date(invitation.deadline_response);
      if (todayNow > deadLine) {
        setIsInTime(false);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    const initGroup = async () => {
      if (idGroup !== undefined) {
        const group = await ApiGroup.getGroup(idInvitation, idGroup);
        if (!group?.is_sent && group !== undefined) {
          setLoading(false);

          navigate("/error/", { replace: true });
        }

        if (!group?.is_opened && group !== undefined) {
          ApiGroup.changeGroupOpen(idInvitation, idGroup);
        }
        const title = group?.title ? group.title + " " : "";
        setGroupName(title + group?.name);
        setHasAnswer(group?.has_answer);
      }
    };

    initGroup();
    getInvitation();
  }, [idInvitation]);

  useEffect(() => {
    setHasQr(
      data?.has_qr &&
        (guests.filter((guest) => guest.is_confirmated).length > 0 || !idGroup)
    );
  }, [data, guests]);

  const guestNames = guests?.map((person) => person.first_name);
  let jointNames;
  if (guestNames?.length === 0) {
    jointNames = "";
  } else if (guestNames?.length === 1) {
    jointNames = guestNames[0];
  } else {
    jointNames =
      guestNames?.slice(0, guestNames.length - 1).join(", ") +
      " y " +
      guestNames[guestNames.length - 1];
  }

  return (
    <>
      {!frontEnvelope && !card && !data ? (
        <Spinner />
      ) : (
        <div className="invitation-page-container" style={backgroundStyle}>
          <div className="align-center logo-invitation-page">
            <img className="logo" width="200px" src={logoImage} />
          </div>
          <div className="invitation-page-content">
            {frontEnvelope && card && (
              <div className="preview-animation-envelope">
                <PostCard
                  postcardElement={card}
                  frontCard={frontEnvelope}
                  guests={jointNames}
                  groupName={groupName}
                />
              </div>
            )}

            {!isCardActive && qrModalOpen && !modalOpen && (
              <Modal onOpen={qrModalOpen} setOnOpen={setQrModalOpen} buttonOn>
                <InvitationQr
                  invitationId={idInvitation}
                  groupId={
                    idGroup ? idGroup : process.env.REACT_APP_CURRENT_URL
                  }
                />
              </Modal>
            )}
            {/* {!isCardActive && !qrModalOpen && modalOpen && ( */}
            <Modal onOpen={modalOpen} setOnOpen={setModalOpen} buttonOn>
              <InvitationQuestionary
                questions={questions}
                possibilities={possibilities}
                guests={guests}
                oldAnswers={answers}
                groupUuid={idGroup}
                invitationUuid={idInvitation}
                color={data.button_color}
                /*setOnOpen={setModalOpen}*/
              />
            </Modal>
            {/* )} */}
            {/* {new Date() <= new Date(data?.deadline_response) && ( */}
            {(hasQr || isInTime || hasAnswer) && (
              <div className="invitation-button-confirm-container">
                {owner && (
                  <p className="invitation-msg--warning">
                    {t("invitation.warning")}
                  </p>
                )}
                {hasAnswer && data?.virtual_place_url && (
                  <div>
                    <p className="invitation-msg">{t("invitation.thanks")}</p>
                    <p>
                      <a href={data?.virtual_place_url} target="_blank">
                        Web del evento
                      </a>
                    </p>
                  </div>
                )}
                {isInTime && (
                  <Button
                    type="primary"
                    onClick={() => {
                      setModalOpen(true);
                      setIsCardActive(!isCardActive);
                    }}
                    text={
                      hasAnswer
                        ? t("invitation.modify")
                        : t("invitation.confirm")
                    }
                    color={data.button_color}
                  />
                )}
                {hasQr && (
                  <Button
                    type="primary"
                    onClick={() => {
                      setQrModalOpen(true);
                      setIsCardActive(!isCardActive);
                    }}
                    text={t("invitation.showQr")}
                    color={data.button_color}
                  />
                )}
                {mapLocation && (
                  <StaticGoogleMap
                    size="300x300"
                    apiKey={process.env.REACT_APP_MAPS}
                    className="invitation-page-map"
                    zoom="16"
                  >
                    <Marker location={mapLocation} color="red" />
                  </StaticGoogleMap>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
