import "../../assets/css/spinner.css";

const Spinner = ({ typeColor, customClassStyle }) => {
  const color = typeColor === "purple" ? "lds-purple" : "lds-white";
  return (
    <div className={`lds-roller ${color} ${customClassStyle}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Spinner;
