import React from "react";
import reactDom from "react-dom";
import "../../assets/css/modal.css";
import { Icon } from "@iconify/react";
// onOpen and setOnOpen= is the state that you pass from father component to show or not the modal
// buttonOn = is a atribute, if you want or not a button closed
// children= what you want to display inside the modal
const Modal = ({ onOpen, setOnOpen, children, buttonOn, style={} }) => {
  const onClose = () => {
    setOnOpen(false);
  };

  return reactDom.createPortal(
    <>
      {onOpen && (
        <div className="modal-container" onMouseDown={onClose}>
          <div className="modal" onMouseDown={(e) => e.stopPropagation()} style={style}>
            {buttonOn && (
              <Icon
                icon="bi:x"
                onClick={onClose}
                className="button-closed-modal"
              />
            )}
            {children}
          </div>
        </div>
      )}
    </>,

    document.getElementById("root"),
  );
};
export default Modal;
