import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Api from "../../services/usersApi";
import { Icon } from "@iconify/react";
import "../../assets/css/recoveryPassword.css";
import forgotPasswordImg from "../../assets/img/general/forgotPassword.svg";
import sendEmail from "../../assets/img/general/sendEmail.svg";
import Button from "../general/Button";

function RecoveryPassword(props = { toUrl: null }) {
  const [recoveryComplete, setRecoveryComplete] = useState();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const { t } = useTranslation();

  const recoveryPassword = async (data) => {
    const response = await Api.recoveryPassword(data.email);
    setRecoveryComplete(true);
  };

  return !recoveryComplete ? (
    <div className="recovery-password-container">
      <h2 className="recovery-password-tittle">{t("passwordRecovery.title")}</h2>
      <div className="recovery-password-content-container">
        <img
          className="recovery-password-img"
          src={forgotPasswordImg}
          alt="img forgot password"
        ></img>
        <div className="recovery-password-content">
          <p>{t("passwordRecovery.subTitle")}</p>
          <form onSubmit={handleSubmit(recoveryPassword)}>
            <div className=" info-field">
              <label className="info-label" htmlFor="email_title">
                {t("passwordRecovery.email")}
              </label>
              <div
                id="info-input"
                className={
                  !errors?.email && watch().email?.length
                    ? " input-ok info-input-container"
                    : "  info-input-container"
                }
              >
                <input
                  type="text"
                  placeholder="Escribe tu correo"
                  {...register("email", {
                    required: true,
                    minLength: 5,
                    pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                  })}
                  className={errors?.email ? "error input" : "input"}
                />
                {watch()?.email?.length > 0 && !errors?.email && (
                  <div className={`complete-password`}>
                    <Icon icon="bi:check-circle-fill" />
                  </div>
                )}
                {errors?.email && <span className="error-point"></span>}

                {errors.email && (
                  <span className="error-text">{t("recovery.emailError")}</span>
                )}
              </div>
            </div>

            <div className="recovery-button-container">
              <Button
                type="primary"
                buttonType="submit"
                text={t("passwordRecovery.recovery")}
              />
            </div>
            <Link
              onClick={() => props.setOnOpen(false)}
              className="sign-acount"
              to="/sign-in"
            >
              {t("recovery.signIn")}
            </Link>
          </form>
        </div>
      </div>
    </div>
  ) : (
    <div className="recovery-password-container check-email-container">
      <div className="check-email-content">
        <img src={sendEmail} alt="image send email" />
        <h1>{t("passwordRecovery.title2")}</h1>
        <p>{t("passwordRecovery.subTitle2")}</p>
      </div>
      <div className="check-email-doubts">
        <p>{t("passwordRecovery.doubts")}</p>
        <a href="mailto:hi@kolibird.app?">hi@kolibird.app</a>
      </div>
    </div>
  );
}
export default RecoveryPassword;
