import { useState, useEffect } from "react";

import { ImagesGrid } from "polotno/side-panel";
import { getImageSize } from "polotno/utils/image";
import ColorPicker from "polotno/toolbar/color-picker";

import SidePanelTextInput from "./SidePanelTextInput";
import Api from "../../../services/invitationsApi";

export default function BackgroundPanel({ store, section }) {
  const [colorPickervalue, setColorPickerValue] = useState(
    store.activePage.background,
  );
  const [images, setImages] = useState([]);
  const [description, setDescription] = useState("");
  const [filters, setFilters] = useState({ section: section });

  const getImages = async () => {
    const images = await Api.getAssets(filters);
    if (images?.length > 0) {
      setImages(images);
    }
  };

  const getInputValue = (event) => {
    const value = event.target.value;
    setDescription(value);
  };

  useEffect(() => {
    const getImagesByTitle = async () => {
      if (description.length > 2) {
        const newFilters = { ...filters, search: description };
        setFilters({ ...newFilters });
      } else {
        const newFilters = { ...filters };
        delete newFilters.search;
        setFilters(newFilters);
      }
    };

    getImagesByTitle();
  }, [description]);

  useEffect(() => {
    getImages();
  }, [filters]);

  return (
    <div className="custom-panel">
      <SidePanelTextInput getInputValue={getInputValue} />
      <div className="polotno-color-picker-wrapper">
        <p className="polotno-color-picker-title">Color</p>
        <ColorPicker
          value={colorPickervalue}
          onChange={(newColor) => {
            store.pages.map((page) => {
              page.set({
                background: newColor,
              });
            });
            setColorPickerValue(newColor);
          }}
          store={store}
        />
      </div>
      <ImagesGrid
        images={images}
        getPreview={(image) => image.source}
        onSelect={async (image, pos, element) => {
          const width = store.width;
          const height = store.height;
          const x = (pos?.x || store.width / 2) - width / 2;
          const y = (pos?.y || store.height / 2) - height / 2;
          store.pages.map((page) => {
            page.addElement({
              type: image.type === "1" ? "svg" : "image",
              src: image.source,
              width,
              height,
              x,
              y,
            });
          });
        }}
        rowsNumber={2}
        crossOrigin="anonymous"
      />
    </div>
  );
}
