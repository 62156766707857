import { useEffect, useState } from 'react';


function FileForm(props={}) {

  return (
    <div>
cosas4
    </div>
  );
}
export default FileForm;
