import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/state";
import Api from "../../services/invitationsApi";
import InvitationsServices from "../../services/invitationsServices";
import UserApi from "../../services/usersApi";
import UsersServices from "../../services/usersServices";
import { limitNewInvitation } from "../../constants/limitNewInvitation";
import Modal from "../general/modal";
import { Icon } from "@iconify/react";
import Button from "../general/Button";
import { useTranslation } from "react-i18next";

function CreateInvitation({ children, newInvitation }) {
  const context = useAppContext();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const { t } = useTranslation();
  const checkInvitation = async () => {
    const draft = context.sharedState.invitations.filter(
      (invitation) => invitation.is_draft === true,
    );

    if (draft.length < limitNewInvitation && newInvitation == true) {
      let invitation = InvitationsServices.initEmptyInvitation(context.user);
      if (UsersServices.isLogIn()) {
        let user = context.user;
        if (user === null) {
          user = await UserApi.getCurrentUser();
          context.setUser(user);
        }
        invitation = { ...invitation, user: user.id, user_uuid: user.uuid };
        invitation = await Api.createInvitation(invitation);
      }

      context.setSharedState({
        ...context.sharedState,
        invitation: invitation,
      });

      navigate(`/invitation-laboratory/${invitation.uuid}/`, {
        replace: true,
      });
    } else if (draft.length >= limitNewInvitation) {
      setOpenModal(true);
    }
  };

  return (
    <>
      <div onClick={checkInvitation}>{children}</div>
      <Modal onOpen={openModal} setOnOpen={setOpenModal} buttonOn>
        <div className="modal-max-draft">
          <Icon icon="mdi:alert-circle" />
          <p>
            {t("events.modalLimit.title1")} {limitNewInvitation}{" "}
            {t("events.modalLimit.title2")}
          </p>
          <Button
            text={t("events.modalLimit.button")}
            onClick={() =>
              navigate("/my-account", {
                state: 3,
              })
            }
          />
        </div>
      </Modal>
    </>
  );
}

export default CreateInvitation;
