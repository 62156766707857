import { animateScroll as scroll } from "react-scroll";

export default function useScrollbySize(duration, size, position="bottom" ){
    const functionScroll = position === "top" ? scroll.scrollToTop : scroll.scrollToBottom 
    
    const response = ()=> {
        if(window.innerWidth < size){
            functionScroll({
                duration: duration,
                delay: 50,
                smooth: true,
                offset: -20,}
            )}
    }
    return response
}