import Modal from "./modal";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Button from "./Button";
import { useAppContext } from "../../context/state";
import UsersServices from "../../services/usersServices";
import { Icon } from "@iconify/react";


const AlertUX = () => {
  const [modal, setModal] = useState(false);
  const { t } = useTranslation();
  const context = useAppContext();

  const handleResize = () => {
    
    if (window.innerWidth < 800) {
      setModal(true);
    } else {
      setModal(false);
    }
  };

  useEffect(() => {
    (() => {
      if (
        window.location.pathname.includes("/my-account") ||
        window.location.pathname.includes("/invitation-laboratory") ||
        window.location.pathname.includes("/try-kolibird")
      ) {
        handleResize();
      }
    })();
  }, [UsersServices.isLogIn()]);

  return (
    <Modal onOpen={modal} setOnOpen={setModal} buttonOn>
      <div className="modal-max-draft">
        <Icon icon="mdi:alert-circle" />
        <p>{t("alertUx.content")}</p>
        <Button
          text={t("alertUx.button")}
          onClick={() => setModal(false)}
        ></Button>
      </div>
    </Modal>
  );
};
export default AlertUX;
