import { useState, useEffect } from "react";
import { UploadSection } from "polotno/side-panel";

import { TextSection, LayersSection } from "polotno/side-panel";
import useScrollbySize from "../../hooks/useScroll";
import { useAppContext } from "../../context/state";
import InvitationsServices from "../../services/invitationsServices";
import useInvitationCustomPanels from "../../hooks/useInvitationCustomPanels";
import { PolotnoWorkspace } from "./polotno/polotnoWorkspace";

export const InvitationDesignBoard = (props) => {
  const [store, setStore] = useState(props.store);
  const context = useAppContext();
  const scroll = useScrollbySize(500, 600, "top");
  const {
    customTemplatesSection,
    customPhotosSection,
    customIconsSection,
    customShapesSection,
    customSizeSection,
    customBackgroundSection,
    // TextSection,
    // styleSection,
    customTextSection,
  } = useInvitationCustomPanels();

  const sidePanelSections = [
    customTemplatesSection,
    // TextSection,
    // styleSection,
    customTextSection,
    customPhotosSection,
    customIconsSection,
    customShapesSection,
    customSizeSection,
    customBackgroundSection,
    LayersSection,
    UploadSection,
  ];
  useEffect(() => {
    scroll();
  }, []);

  useEffect(() => {
    const loadDesign = () => {
      const config = context.sharedState.invitation?.configuration;

      if (config?.pages?.length) {
        store.deletePages(store.pages.map((page) => page.id));
        if (Object.entries(config).length) {
          config?.pages[0]?.children?.map((child) => {
            if (child.type == "image" || child.type == "svg") {
              if (child.src.includes("seed")) {
                const newUrl = child.src.slice(0, child.src.indexOf("?seed"));
                child.src = `${newUrl}?seed=${Math.round(Math.random() * 100)}`;
              } else {
                child.src = `${child.src}?seed=${Math.round(
                  Math.random() * 100
                )}`;
              }
            }
          });

          store.loadJSON(config, true);
        }
      } else if (config?.pages === undefined) {
        store.addPage();
      }
    };

    loadDesign();
  }, []);
  return (
    <PolotnoWorkspace
      store={store}
      sidePanelSections={sidePanelSections}
      loading={props.loading}
    />
  );
};

export default InvitationDesignBoard;
