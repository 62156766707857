import { Icon } from "@iconify/react";
import { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../services/invitationsApi";
import PollApi from "../../services/pollsApi";
import ApiGroup from "../../services/groupsApi";
import Button from "../general/Button";
import DateForm from "./dateForm";
import DropDownForm from "./dropDownForm";
import EmailForm from "./emailForm";
import FileForm from "./fileForm";
import HourForm from "./hourForm";
import MultiSelectForm from "./multiSelectForm";
import TermsSelectForm from "./termsSelectForm";
import NumberForm from "./numberForm";
import ShortTextForm from "./shortTextForm";
import SingleOptionForm from "./singleOptionForm";
import TextForm from "./textForm";
import sendEmail from "../../assets/img/general/sendEmail.svg";

function InvitationQuestionary(
  props = {
    questions: [],
    possibilities: [],
    guests: [],
    oldAnswers: [],
    invitationUuid: null,
    groupUuid: null,
    color: "#e33d8c"
    /*setOnOpen: null*/
  }
) {
  const [guestsAttend, setGuestsAttend] = useState(props.guests);
  const [attends, setAttends] = useState({});
  const [answers, setAnswers] = useState(
    props.oldAnswers.map((answer) => {
      return {
        option:
          answer?.option_selected === "true" ? true : answer?.option_selected,
        question: answer.question,
        guest: answer.guest,
      };
    })
  );
  const questions = props.questions;
  const [required, setRequired] = useState(0);
  const [error, setError] = useState("");
  const [sentForm, setSentForm] = useState(false);
  const [isNotEnabled, setIsNotEnabled] = useState(false);
  const { t } = useTranslation();
  /**borrar despues de la invitacón descrita se cumpla */
  const hasElevenQuestion = useMemo(
    () => questions.find((question) => question.type == 11),
    [props.questions]
  );
  useEffect(() => {
    /**usamos find porque solo debería haber Una pregunta 11*/
    const areTermsAccepted = answers.filter(
      (answer) => answer?.question == hasElevenQuestion?.id
    );
    if (!hasElevenQuestion && isNotEnabled) {
      setIsNotEnabled(false);
    } else if (
      hasElevenQuestion &&
      hasElevenQuestion.required &&
      !areTermsAccepted.length
    ) {
      setIsNotEnabled(true);
    }
  }, [props.questions]);

  const mustRenderQuestions =
    props.guests.length == guestsAttend.length ? true : false;
  /*borrar cuando acabe la invitacion*/
  const mustRenderAttend =
    props.invitationUuid !== "d669fcc8-8775-4381-b8ba-191b5edf1b6a";

  useEffect(() => {
    const initialAttends = {};
    props.guests
      .filter((guest) => guest.is_confirmated === true)
      .forEach((guest) => (initialAttends[guest.uuid] = guest.is_confirmated));
    setAttends(initialAttends);
  }, []);
  {
    /*const onCloseModal = () => {
      props.setOnOpen(false);
  }; */
  }
  const modifyMulti = (question, option, guestId = null, answerId = null) => {
    let newAnswers = answers;
    let multi = answers;

    if (guestId !== null) {
      multi = multi.filter((answer) => answer.guest === guestId);
    }
    multi = multi.filter((answer) => Number(answer.option) === Number(option));

    if (multi.length > 0) {
      if (guestId !== null) {
        newAnswers = newAnswers.filter(
          (answer) =>
            !(
              Number(answer.option) === Number(option) &&
              answer.guest === guestId
            )
        );
      } else {
        newAnswers = newAnswers.filter(
          (answer) => Number(answer.option) !== Number(option)
        );
      }
    } else {
      let answer = { question: question, option: option };
      if (guestId !== null) {
        answer.guest = guestId;
      }

      if (answerId !== null) {
        answer.answerId = answerId;
      }

      newAnswers.push(answer);
    }

    setAnswers(newAnswers);
    return newAnswers;
  };
  const aggreeTerms = (question, option, answerId, checked) => {
    if (hasElevenQuestion?.required) {
      if (checked) {
        setIsNotEnabled(false);
      } else {
        setIsNotEnabled(true);
      }
    }
    modifyMulti(question, option, null, answerId);

    return;
  };

  const modifyForm = (question, option, guestId = null, answerId = null) => {
    const isRequired = props.questions.filter(
      (currentQuestion) => currentQuestion.id === question
    )[0].required;

    if (isRequired && option !== "") {
      setRequired(required + 1);
    } else if (isRequired && option === "") {
      setRequired(required - 1);
    }

    let newAnswers = [];
    if (guestId !== null) {
      newAnswers = answers.filter(
        (answer) => !(answer.guest === guestId && answer.question === question)
      );
    } else {
      newAnswers = answers.filter((answer) => answer.question !== question);
    }

    if (option !== "") {
      let answer = { question: question, option: option };
      if (guestId !== null) {
        answer.guest = guestId;
      }

      if (answerId !== null) {
        answer.answerId = answerId;
      }

      newAnswers.push(answer);
    }

    setAnswers(newAnswers);
    return newAnswers;
  };

  const modifyAttend = (guestUuid, status) => {
    // let newGuests = guestsAttend.filter((guest) => guest.uuid !== guestUuid);
    // newGuests.push({ uuid: guestUuid, is_confirmated: status });
    let newAttends = attends;
    newAttends[guestUuid] = status;
    const newGuests = guestsAttend.map((guest) => {
      if (guest.uuid === guestUuid) guest.is_confirmated = status;
      return guest;
    });
    setGuestsAttend(newGuests);
    setAttends(newAttends);

    if (
      guestsAttend.filter((guest) => guest.is_confirmated === true).length === 0
    ) {
      setAnswers([]);
    }
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (!props.groupUuid) {
      setSentForm(true);
      return;
    }
    if (props.groupUuid !== null) {
      guestsAttend
        .map((guest) => {
          return {
            uuid: guest.uuid,
            is_confirmated: guest?.is_confirmated,
          };
        })
        .forEach(async (guest) => {
          await Api.changeGuestResponse(guest, props.groupUuid);
        });

      const answersPayload = answers.map((answer) => {
        let payload = {
          option_selected: answer.option,
          question_id: answer.question,
          group_id: props.guests[0].group,
        };

        if (answer.guest !== undefined) {
          payload.guest_id = answer.guest;
        }

        return payload;
      });
      await PollApi.createAnswers(answersPayload, props.invitationUuid);
      await ApiGroup.changeGroupAnswered(props.invitationUuid, props.groupUuid);
      setSentForm(true);
    }
  };
  const renderQuestion = (question) => {
    const { type } = question;
    if (type != 11)
      return (
        <p>
          {question.text} {question.required && "*"}
        </p>
      );
    else
      return (
        <span>
          {t("pollAnswer.terms_1")}
          <a href={question.text} target="_blank">
            {t("pollAnswer.terms_2")}
          </a>
          ? {question.required && " *"}
        </span>
      );
  };

  return (
    <div className="invitation-questionary-container">
      {sentForm ? (
        <div className="send-answer-container">
          <img
            className="send-answer-img"
            src={sendEmail}
            alt="image confirmation"
          />
          <h3 className="send-answer-title">
            {t("pollAnswer.titleConfirmation")}
          </h3>
          {/* <p>{t("pollAnswer.titleConfirmation")}</p> */}
          {/* <div className="questionary-confirmation-container-buttons">
            <Button text={t("pollAnswer.primariButton")} 
              type="primary" 
              onClick={onCloseModal}
              />
          </div> */}
        </div>
      ) : (
        <form onSubmit={submitForm}>
          <h3>{t("pollAnswer.title")}</h3>
          {mustRenderAttend &&
            guestsAttend.map((guest, index) => {
              return (
                <div key={guest.id} className="invitation-questionary">
                  <p>
                    ¿{t("pollAnswer.attend")} {guest.first_name}{" "}
                    {guest?.last_name}?
                  </p>
                  <div className="invitation-questionary-inputs">
                    <div className="invitation-questionary-input-container">
                      <input
                        id={`${guest.uuid}-true`}
                        type="radio"
                        name={guest.uuid}
                        onClick={() => modifyAttend(guest.uuid, true)}
                        required
                        defaultChecked={guest?.is_confirmated && true}
                      />

                      <label
                        className="secondary-button"
                        htmlFor={`${guest.uuid}-true`}>
                        <Icon icon="fa-solid:check" />
                        {t("pollAnswer.yes")}
                      </label>
                    </div>
                    <div className="invitation-questionary-input-container">
                      <input
                        id={`${guest.uuid}-false`}
                        type="radio"
                        name={guest.uuid}
                        onClick={() => modifyAttend(guest.uuid, false)}
                        required
                      />
                      <label
                        className="secondary-button"
                        htmlFor={`${guest.uuid}-false`}>
                        <Icon icon="heroicons-solid:x" />
                        {t("pollAnswer.no")}
                      </label>
                    </div>
                  </div>
                </div>
              );
            })}
          <div className="invitation-questions">
            {mustRenderQuestions &&
              guestsAttend.filter((guest) => guest.is_confirmated === true)
                .length > 0 &&
              props.questions.map((question, index) => {
                let form = [];
                let oldAnswerId;
                const oldAnswer = props.oldAnswers.filter(
                  (answer) => answer.question === question.id
                );
                if (oldAnswer.length > 0) {
                  oldAnswerId = oldAnswer[0].id;
                }
                form.push(renderQuestion(question));
                if (question.type === "1") {
                  const currentPossibilities = props.possibilities.filter(
                    (possibility) => question.id === possibility.question
                  );
                  if (question.is_group_question) {
                    props.guests.forEach((guest) => {
                      const answers_selection = answers.filter(
                        (answer) =>
                          answer.guest === guest.id &&
                          question.id === answer.question
                      );
                      attends[guest.uuid] == true &&
                        form.push(
                          <div
                            style={{
                              display: attends[guest.uuid] ? "block" : "none",
                            }}>
                            {guest.first_name}:
                            <MultiSelectForm
                              possibilities={currentPossibilities}
                              modifyMulti={(question, option, answerId) =>
                                modifyMulti(
                                  question,
                                  option,
                                  guest.id,
                                  answerId
                                )
                              }
                              question={question}
                              answers={answers_selection}
                              guestId={guest.uuid}
                            />
                          </div>
                        );
                    });
                  } else {
                    const answers_selection = answers.filter(
                      (answer) => question.id === answer.question
                    );
                    form.push(
                      <MultiSelectForm
                        question={question}
                        possibilities={currentPossibilities}
                        modifyMulti={(question, option, answerId) =>
                          modifyMulti(question, option, null, answerId)
                        }
                        answers={answers_selection}
                      />
                    );
                  }
                } else if (question.type === "2") {
                  const currentPossibilities = props.possibilities.filter(
                    (possibility) => question.id === possibility.question
                  );
                  if (question.is_group_question) {
                    props.guests.forEach((guest) => {
                      const answer_selection = answers.find(
                        (answer) =>
                          answer.guest === guest.id &&
                          question.id === answer.question
                      );
                      attends[guest.uuid] == true &&
                        form.push(
                          <div
                            style={{
                              display: attends[guest.uuid] ? "block" : "none",
                            }}>
                            {guest.first_name}:
                            <SingleOptionForm
                              possibilities={currentPossibilities}
                              modifyForm={(question, option) =>
                                modifyForm(
                                  question,
                                  option,
                                  guest.id,
                                  answer_selection?.id
                                )
                              }
                              question={question}
                              value={answer_selection?.option}
                              guestId={guest.uuid}
                            />
                          </div>
                        );
                    });
                  } else {
                    const answer_selection = answers.find(
                      (answer) =>
                        Number(question.id) === Number(answer.question)
                    );
                    form.push(
                      <SingleOptionForm
                        possibilities={currentPossibilities}
                        modifyForm={(question, option) =>
                          modifyForm(
                            question,
                            option,
                            null,
                            answer_selection?.id
                          )
                        }
                        question={question}
                        value={answer_selection?.option}
                      />
                    );
                  }
                } else if (question.type === "3") {
                  const currentPossibilities = props.possibilities.filter(
                    (possibility) => question.id === possibility.question
                  );
                  if (question.is_group_question) {
                    props.guests.forEach((guest) => {
                      const answer_selection = answers.find(
                        (answer) =>
                          answer.guest === guest.id &&
                          question.id === answer.question
                      );
                      attends[guest.uuid] == true &&
                        form.push(
                          <div
                            style={{
                              display: attends[guest.uuid] ? "block" : "none",
                            }}>
                            {guest.first_name}:
                            <DropDownForm
                              possibilities={currentPossibilities}
                              modifyForm={(question, option) =>
                                modifyForm(
                                  question,
                                  option,
                                  guest.id,
                                  answer_selection?.id
                                )
                              }
                              question={question}
                              value={answer_selection?.option}
                            />
                          </div>
                        );
                    });
                  } else {
                    const answer_selection = answers.find(
                      (answer) => question.id === answer.question
                    );
                    form.push(
                      <DropDownForm
                        possibilities={currentPossibilities}
                        modifyForm={(question, option) =>
                          modifyForm(
                            question,
                            option,
                            null,
                            answer_selection?.id
                          )
                        }
                        question={question}
                        value={answer_selection?.option}
                      />
                    );
                  }
                } else if (question.type === "4") {
                  if (question.is_group_question) {
                    props.guests.forEach((guest) => {
                      const answer_selection = answers.filter(
                        (answer) =>
                          answer.guest === guest.id &&
                          question.id === answer.question
                      );
                      attends[guest.uuid] == true &&
                        form.push(
                          <div
                            style={{
                              display: attends[guest.uuid] ? "block" : "none",
                            }}>
                            {guest.first_name}:
                            <NumberForm
                              questionId={question.id}
                              modifyForm={(question, option) =>
                                modifyForm(
                                  question,
                                  option,
                                  guest.id,
                                  answer_selection?.id
                                )
                              }
                              question={question}
                              value={answer_selection?.option}
                            />
                          </div>
                        );
                    });
                  } else {
                    const answer_selection = answers.filter(
                      (answer) => question.id === answer.question
                    )[0];
                    form.push(
                      <NumberForm
                        questionId={question.id}
                        modifyForm={(question, option) =>
                          modifyForm(
                            question,
                            option,
                            null,
                            answer_selection?.id
                          )
                        }
                        question={question}
                        value={answer_selection?.option}
                      />
                    );
                  }
                } else if (question.type === "5") {
                  if (question.is_group_question) {
                    props.guests.forEach((guest) => {
                      const answer_selection = answers.filter(
                        (answer) =>
                          answer.guest === guest.id &&
                          question.id === answer.question
                      );
                      attends[guest.uuid] == true &&
                        form.push(
                          <div
                            style={{
                              display: attends[guest.uuid] ? "block" : "none",
                            }}>
                            {guest.first_name}:
                            <ShortTextForm
                              questionId={question.id}
                              modifyForm={(question, option) =>
                                modifyForm(
                                  question,
                                  option,
                                  guest.id,
                                  answer_selection?.id
                                )
                              }
                              question={question}
                              value={answer_selection?.option}
                            />
                          </div>
                        );
                    });
                  } else {
                    const answer_selection = answers.filter(
                      (answer) => question.id === answer.question
                    )[0];
                    form.push(
                      <ShortTextForm
                        questionId={question.id}
                        modifyForm={(question, option) =>
                          modifyForm(
                            question,
                            option,
                            null,
                            answer_selection?.id
                          )
                        }
                        question={question}
                        value={answer_selection?.option}
                      />
                    );
                  }
                } else if (question.type === "6") {
                  if (question.is_group_question) {
                    props.guests.forEach((guest) => {
                      const answer_selection = answers.filter(
                        (answer) =>
                          answer.guest === guest.id &&
                          question.id === answer.question
                      );
                      attends[guest.uuid] == true &&
                        form.push(
                          <div
                            style={{
                              display: attends[guest.uuid] ? "block" : "none",
                            }}>
                            {guest.first_name}:
                            <TextForm
                              questionId={question.id}
                              modifyForm={(question, option) =>
                                modifyForm(
                                  question,
                                  option,
                                  guest.id,
                                  answer_selection?.id
                                )
                              }
                              question={question}
                              value={answer_selection?.option}
                            />{" "}
                          </div>
                        );
                    });
                  } else {
                    const answer_selection = answers.filter(
                      (answer) => question.id === answer.question
                    )[0];
                    form.push(
                      <TextForm
                        questionId={question.id}
                        modifyForm={(question, option) =>
                          modifyForm(
                            question,
                            option,
                            null,
                            answer_selection?.id
                          )
                        }
                        question={question}
                        value={answer_selection?.option}
                      />
                    );
                  }
                } else if (question.type === "7") {
                  if (question.is_group_question) {
                    props.guests.forEach((guest) => {
                      const answer_selection = answers.filter(
                        (answer) =>
                          answer.guest === guest.id &&
                          question.id === answer.question
                      );
                      attends[guest.uuid] == true &&
                        form.push(
                          <div
                            style={{
                              display: attends[guest.uuid] ? "block" : "none",
                            }}>
                            {guest.first_name}:
                            <EmailForm
                              questionId={question.id}
                              modifyForm={(question, option) =>
                                modifyForm(
                                  question,
                                  option,
                                  guest.id,
                                  answer_selection?.id
                                )
                              }
                              question={question}
                              value={answer_selection?.option}
                            />{" "}
                          </div>
                        );
                    });
                  } else {
                    const answer_selection = answers.filter(
                      (answer) => question.id === answer.question
                    )[0];
                    form.push(
                      <EmailForm
                        questionId={question.id}
                        modifyForm={(question, option) =>
                          modifyForm(
                            question,
                            option,
                            null,
                            answer_selection?.id
                          )
                        }
                        question={question}
                        value={answer_selection?.option}
                      />
                    );
                  }
                } else if (question.type === "8") {
                  if (question.is_group_question) {
                    props.guests.forEach((guest) => {
                      const answer_selection = answers.filter(
                        (answer) =>
                          answer.guest === guest.id &&
                          question.id === answer.question
                      );
                      attends[guest.uuid] == true &&
                        form.push(
                          <div
                            style={{
                              display: attends[guest.uuid] ? "block" : "none",
                            }}>
                            {guest.first_name}:
                            <DateForm
                              questionId={question.id}
                              modifyForm={(question, option) =>
                                modifyForm(
                                  question,
                                  option,
                                  guest.id,
                                  answer_selection?.id
                                )
                              }
                              question={question}
                              value={answer_selection?.option}
                            />
                          </div>
                        );
                    });
                  } else {
                    const answer_selection = answers.filter(
                      (answer) => question.id === answer.question
                    )[0];
                    form.push(
                      <DateForm
                        questionId={question.id}
                        modifyForm={(question, option) =>
                          modifyForm(
                            question,
                            option,
                            null,
                            answer_selection?.id
                          )
                        }
                        question={question}
                        value={answer_selection?.option}
                      />
                    );
                  }
                } else if (question.type === "9") {
                  if (question.is_group_question) {
                    props.guests.forEach((guest) => {
                      const answer_selection = answers.filter(
                        (answer) =>
                          answer.guest === guest.id &&
                          question.id === answer.question
                      )[0];
                      attends[guest.uuid] == true &&
                        form.push(
                          <div
                            style={{
                              display: attends[guest.uuid] ? "block" : "none",
                            }}>
                            {guest.first_name}:
                            <HourForm
                              questionId={question.id}
                              modifyForm={(question, option) =>
                                modifyForm(
                                  question,
                                  option,
                                  guest.id,
                                  answer_selection?.id
                                )
                              }
                              question={question}
                              value={answer_selection?.option}
                            />{" "}
                          </div>
                        );
                    });
                  } else {
                    const answer_selection = answers.filter(
                      (answer) => question.id === answer.question
                    )[0];
                    form.push(
                      <HourForm
                        questionId={question.id}
                        modifyForm={(question, option) =>
                          modifyForm(
                            question,
                            option,
                            null,
                            answer_selection?.id
                          )
                        }
                        question={question}
                        value={answer_selection?.option}
                      />
                    );
                  }
                } else if (question.type === "10") {
                  if (question.is_group_question) {
                    props.guests.forEach((guest) => {
                      const answer_selection = answers.filter(
                        (answer) =>
                          answer.guest === guest.id &&
                          question.id === answer.question
                      )[0];
                      attends[guest.uuid] == true &&
                        form.push(
                          <div
                            style={{
                              display: attends[guest.uuid] ? "block" : "none",
                            }}>
                            {guest.first_name}:
                            <FileForm
                              questionId={question.id}
                              modifyForm={(question, option, answerId) =>
                                modifyForm(question, option, guest.id, answerId)
                              }
                              question={question}
                              value={answer_selection?.option}
                            />{" "}
                          </div>
                        );
                    });
                  } else {
                    const answer_selection = answers.filter(
                      (answer) => question.id === answer.question
                    )[0];
                    form.push(
                      <FileForm
                        questionId={question.id}
                        modifyForm={(question, option, answerId) =>
                          modifyForm(question, option, null, answerId)
                        }
                        question={question}
                        value={answer_selection?.option}
                      />
                    );
                  }
                }
                if (question.type === "11") {
                  const currentPossibilities = props.possibilities.filter(
                    (possibility) => question.id === possibility.question
                  );
                  if (question.is_group_question) {
                    props.guests.forEach((guest) => {
                      const answers_selection = answers.filter(
                        (answer) =>
                          answer.guest === guest.id &&
                          question.id === answer.question
                      );
                      attends[guest.uuid] == true &&
                        form.push(
                          <div
                            style={{
                              display: attends[guest.uuid] ? "block" : "none",
                            }}>
                            {guest.first_name}:
                            <TermsSelectForm
                              possibilities={currentPossibilities}
                              modifyMulti={(question, option, answerId) =>
                                modifyMulti(
                                  question,
                                  option,
                                  guest.id,
                                  answerId
                                )
                              }
                              question={question}
                              answers={answers_selection}
                              guestId={guest.uuid}
                            />
                          </div>
                        );
                    });
                  } else {
                    const answers_selection = answers.filter(
                      (answer) => question.id == answer.question
                    );
                    form.push(
                      <TermsSelectForm
                        question={question}
                        possibilities={currentPossibilities}
                        modifyMulti={aggreeTerms}
                        answers={answers_selection}
                      />
                    );
                  }
                }
                return (
                  <div key={question.id} className="invitation-question">
                    {form}
                  </div>
                );
              })}
          </div>
          <div className="invitation-question-submit-button-container">
            <Button
              icon="material-symbols:send-rounded"
              buttonType="submit"
              type="primary"
              disabled={isNotEnabled}
              text={t("pollAnswer.submit")}
              color={props.color}
            />
          </div>
        </form>
      )}
    </div>
  );
}
export default InvitationQuestionary;
