import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../context/state";
import Button from "./Button";
import { Icon } from "@iconify/react";
import Api from "../../services/usersApi";
import "../../assets/css/changePass.css";
import { useForm } from "react-hook-form";
import popUpAlert from "./popUpAlert";

const FormChangePass = ({ setOnOpen }) => {
  const { t } = useTranslation();
  const context = useAppContext();
  const [pass, setPass] = useState("");
  const [secondPass, setSecondPass] = useState("");
  const [showPass, setShowPass] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: "all" });
  const [checkPassword, setCheckPassword] = useState({
    capital: false,
    lowercase: false,
    number: false,
    minLength: false,
    maxLength: false,
  });

  const handleInput = (e) => {
    const tempPass = e.target.value.replace(/\s+/g, "");
    const password = { ...checkPassword };

    password.capital = /[A-Z]/.test(tempPass);
    password.lowercase = /[a-z]/.test(tempPass);
    password.number = /[0-9]/.test(tempPass);
    password.minLength = tempPass.length >= 8;
    password.maxLength = tempPass.length <= 15;

    setCheckPassword(password);
    setPass(tempPass);
  };

  const onSubmit = async (data) => {
    const userId = context.user.uuid;

    const tempPass = { password: data.password };
    const response = await Api.changeUser(userId, tempPass);
    if (response == undefined) {
      popUpAlert(false, t("popUpSave.negative"));
    } else {
      popUpAlert(true, t("popUpSave.positive"));

      setOnOpen(false);
    }
  };

  return (
    <form className="changepass__form" onSubmit={handleSubmit(onSubmit)}>
      <div className="changepass__slot">
        <div className="info-field">
          <label className="info-label" htmlFor="password">
            {t("signUp.password")}
          </label>
          <div
            id="info-input"
            className={
              errors.password?.type === "required" ||
              errors.password?.type === "maxLength"
                ? "info-input-container input-password"
                : " input-ok info-input-container input-password"
            }
          >
            <input
              type={showPass ? "password" : "text"}
              placeholder={t("signUp.passwordPlaceholder")}
              id="password"
              {...register("password", {
                required: true,
                minLength: 8,
                maxLength: 15,
                pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*?[0-9])/,
              })}
              autoComplete="new-password"
              className={errors?.password ? "error input" : "input"}
              onInput={handleInput}
              value={pass}
            />
            <span
              onClick={() => setShowPass(!showPass)}
              className="show-password"
            >
              {showPass ? (
                <Icon icon="fluent:eye-16-filled" />
              ) : (
                <Icon icon="fluent:eye-off-16-filled" />
              )}
            </span>
            {errors.password && <span className="error-point"></span>}
            {errors.password?.type === "required" && (
              <span className="error-text">Campo requerido</span>
            )}
            {errors.password?.type === "maxLength" && (
              <span className="error-text">
                Debe tener menos de 15 caracteres
              </span>
            )}
            {watch().password?.length > 0 && !errors?.password ? (
              <div className={`complete-password`}>
                <Icon icon="bi:check-circle-fill" />
              </div>
            ) : null}
          </div>
          <div className="check-password-container">
            <div className={`check-password-${checkPassword.lowercase}`}>
              <Icon icon="bi:check-circle-fill" />
              <p>{t("signUp.lowercaseError")}</p>
            </div>
            <div className={`check-password-${checkPassword.capital}`}>
              <Icon icon="bi:check-circle-fill" />
              <p>{t("signUp.uppercaseError")}</p>
            </div>
            <div className={`check-password-${checkPassword.number}`}>
              <Icon icon="bi:check-circle-fill" />
              <p>{t("signUp.numberError")}</p>
            </div>
            <div className={`check-password-${checkPassword.minLength}`}>
              <Icon icon="bi:check-circle-fill" />
              <p>{t("signUp.charsError")}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="info-field">
        <label className="info-label">{t("profilePage.repeatPassword")}</label>
        <div className="info-input-container">
          <input
            type={showPass ? "password" : "text"}
            autoComplete="new-password"
            {...register("secondPassword", {
              validate: (value) => value === pass,
            })}
            onInput={(e) => setSecondPass(e.target.value)}
            className={
              !errors.secondPassword
                ? "changepass__input input"
                : "changepass__input-error input"
            }
          />
        </div>

        {errors.secondPassword && (
          <p className="changepass__error">{t("profilePage.mustMatch")}</p>
        )}
      </div>

      <Button text={t("general.save")} buttonType="submit" />
    </form>
  );
};

export default FormChangePass;
