import { JSONCard } from "../animations/JSONCard";

function Invitation({ configuration }) {
  const styles = {
    // aqui solo quite el scale y le agregue el 100% width y height
    transform: "rotate(-90deg)",
    width: "100%",
    height: "100%",
  };

  return (
    // aqui le cree un div padre para poder darle un tamaño que no afectara el jsonCard ( creo que se podria mejorar pero asi mismo funciona)
    <div className="invitation--frame">
      <div style={styles}>
        <JSONCard card={configuration} isACard={true} index={0}></JSONCard>
      </div>
    </div>
  );
}
export default Invitation;
