import { useEffect, useState } from "react";
import { useAppContext } from "../../context/state";
import { useTranslation } from "react-i18next";
import Api from "../../services/invitationsApi";
import TemplatesBoard from "./templatesBoard";
import TemplateFilters from "./templateFilters";
import "../../assets/css/templates.css";
import EditButton from "../general/editButton";
import { animateScroll as scroll } from "react-scroll";
import { templateList } from "polotno/utils/api";

function SelectTemplate() {
  const [filters, setFilters] = useState({});
  const context = useAppContext();
  const { t } = useTranslation();
  const scrollType = {
    duration: 500,
    delay: 50,
    smooth: true,
    offset: -20,
  };

  useEffect(() => {
    const getTags = async () => {
      let newFilterTagTypes = [];
      if (context.sharedState.filterTagTypes?.length) {
        newFilterTagTypes = context.sharedState.filterTagTypes;
      } else {
        newFilterTagTypes = await Api.getFilterTagTypes();
      }

      let newFilterTags = [];
      if (context.sharedState.filterTags?.length) {
        newFilterTags = context.sharedState.filterTags;
      } else {
        newFilterTags = await Api.getFilterTags();
      }

      let newTemplates = [];
      let lastPage;
      if (context.sharedState.templates?.length === 0) {
        [newTemplates, lastPage] = await getTemplatesWithTags();
      } else {
        newTemplates = context.sharedState.templates;
      }
      context.setSharedState({
        ...context.sharedState,
        templates: newTemplates,
        lastTemplatePage: lastPage,
        currentTemplatePage: 1,
        filterTagTypes: newFilterTagTypes,
        filterTags: newFilterTags,
      });
    };

    getTags();
  }, []);

  useEffect(() => {
    const getTemplates = async () => {
      if (context.sharedState.templates?.length >= 0) {
        const [newTemplates, lastPage] = await getTemplatesWithTags();

        context.setSharedState({
          ...context.sharedState,
          templates: newTemplates,
          lastTemplatePage: lastPage,
          currentTemplatePage: 1,
        });
      }
    };

    getTemplates();
  }, [filters]);

  useEffect(() => {
    const getTemplates = async () => {
      const [newTemplates, lastPage] = await getTemplatesWithTags(
        context.sharedState.currentTemplatePage
      );
      context.setSharedState({
        ...context.sharedState,
        templates: newTemplates,
        lastTemplatePage: lastPage,
      });
    };

    getTemplates();
  }, [context.sharedState.currentTemplatePage]);

  const getTemplatesWithTags = async (page) => {
    const pageFilter = page ? { page: page } : {};
    const arrayOfTags = Object.values(filters);
    if (!arrayOfTags?.length) {
      return await Api.getTemplates({
        ...pageFilter,
      });
    }
    const values = await Promise.allSettled(
      arrayOfTags.map((tag) => {
        return Api.getTemplates({
          tags__name: tag,
          ...pageFilter,
        });
      })
    );
    let allTemplates = [];
    let lastPage = 0;
    let templateIds = [];
    values.forEach((result) => {
      if (result.status === "fulfilled") {
        const [templates, totalPages] = result.value;
        lastPage = Math.max(lastPage, totalPages);
        templates.forEach((template) => {
          if (!templateIds.includes(template.uuid)) allTemplates.push(template);
          templateIds.push(template.uuid);
        });
      }
    });
    return [allTemplates, lastPage];
  };

  const addFilter = (tag, tagType) => {
    const newFilters = { ...filters };
    if (newFilters[tagType.name] === tag) {
      delete newFilters[tagType.name];
    } else {
      newFilters[tagType.name] = tag;
    }
    context.setSharedState({ ...context.sharedState, currentTemplatePage: 1 });
    setFilters(newFilters);
  };

  const addSearch = (name) => {
    const newFilters = { ...filters, search: name };
    context.setSharedState({ ...context.sharedState, currentTemplatePage: 1 });
    setFilters(newFilters);
  };

  const nextPage = () => {
    const page = context.sharedState.currentTemplatePage + 1;
    context.setSharedState({
      ...context.sharedState,
      currentTemplatePage: page,
    });
    scroll.scrollToTop(scrollType);
  };

  const prevPage = () => {
    const page = context.sharedState.currentTemplatePage - 1;
    context.setSharedState({
      ...context.sharedState,
      currentTemplatePage: page,
    });
    scroll.scrollToTop(scrollType);
  };

  return (
    <div className=" container creation-board ">
      <div className="templates-container">
        <TemplateFilters addFilter={addFilter} />
        {/* <TemplatesSearcher addSearch={addSearch} /> */}
        <TemplatesBoard />
      </div>
      <div className="template-steps-container">
        {context.sharedState.currentTemplatePage > 1 && (
          <div className="template-before">
            <EditButton
              onClick={prevPage}
              text={t("templates.previous")}
              color="#e33d8c"
              icon="ic:round-navigate-before"
            />
          </div>
        )}
        {context.sharedState.currentTemplatePage <
          context.sharedState.lastTemplatePage && (
          <div className="template-next">
            <EditButton
              onClick={nextPage}
              text={t("templates.next")}
              color="#e33d8c"
              iconSide="rigth"
              icon="ic:round-navigate-next"
            />
          </div>
        )}
      </div>
    </div>
  );
}
export default SelectTemplate;
