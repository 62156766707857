import axios from "axios";
import UsersApi from "./usersApi";
import { generateApiToken } from "./utils";
import { captureException } from "@sentry/react";
class Api {
  static getConfig() {
    return {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    };
  }

  static getConfigNoAuth() {
    return {
      headers: {
        token: generateApiToken(),
      },
    };
  }

  static async getQuestions(invitationId) {
    const url = `/polls/${invitationId}/questions/`;

    const config = Api.getConfig();

    let questions;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        config
      );
      questions = response.data;
    } catch (error) {
      captureException({ error: error, rest: config });
      await UsersApi.refreshToken();
      return error.response.data.error;
    }
    return questions;
  }

  static async getQuestionsPreview(invitationId) {
    const url = `/polls/${invitationId}/questions/group/`;

    const config = Api.getConfigNoAuth();

    let questions;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        config
      );
      questions = response.data;
    } catch (error) {
      captureException({ error: error, config });
      return error.response.data.error;
    }
    return questions;
  }

  static async createQuestion(payload, invitationId) {
    const url = `/polls/${invitationId}/questions/`;

    const config = Api.getConfig();

    let poll;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        payload,
        config
      );
      poll = response.data;
    } catch (error) {
      await UsersApi.refreshToken();
      return null;
    }
    return poll;
  }

  static async changeQuestion(payload, invitationId, questionId) {
    const url = `/polls/${invitationId}/questions/${questionId}/`;

    const config = Api.getConfig();

    let poll;
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        payload,
        config
      );
      poll = response.data;
    } catch (error) {
      await UsersApi.refreshToken();
      return error.response.data.error;
    }
    return poll;
  }

  static async removeQuestion(invitationId, questionId) {
    const url = `/polls/${invitationId}/questions/${questionId}/`;

    const config = Api.getConfig();

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        config
      );
    } catch (error) {
      await UsersApi.refreshToken();
      return error.response.data.error;
    }
  }

  static async getPossibilities(invitationId) {
    const url = `/polls/${invitationId}/possibility-questions/`;

    const config = Api.getConfig();

    let possibilities;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        config
      );
      possibilities = response.data;
    } catch (error) {
      await UsersApi.refreshToken();
      return error.response.data.error;
    }
    return possibilities;
  }

  static async getPossibilitiesPreview(invitationId) {
    const url = `/polls/${invitationId}/possibility-questions/group/`;

    const config = Api.getConfigNoAuth();

    let possibilities;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        config
      );
      possibilities = response.data;
    } catch (error) {
      return error.response.data.error;
    }
    return possibilities;
  }

  static async createPosibility(payload, invitationId) {
    const url = `/polls/${invitationId}/possibility-questions/`;

    const config = Api.getConfig();

    let possibility;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        payload,
        config
      );
      possibility = response.data;
    } catch (error) {
      await UsersApi.refreshToken();
      return null;
    }
    return possibility;
  }

  static async changePosibility(payload, invitationId, possibilityId) {
    const url = `/polls/${invitationId}/possibility-questions/${possibilityId}/`;

    const config = Api.getConfig();

    let possibility;
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        payload,
        config
      );
      possibility = response.data;
    } catch (error) {
      await UsersApi.refreshToken();
      return error.response.data.error;
    }
    return possibility;
  }

  static async removePosibility(invitationId, possibilityId) {
    const url = `/polls/${invitationId}/possibility-questions/${possibilityId}/`;

    const config = Api.getConfig();

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        config
      );
    } catch (error) {
      await UsersApi.refreshToken();
      return error.response.data.error;
    }
  }

  static async getAnswers(invitationId, groupId) {
    const url = `/polls/${invitationId}/answers/group/${groupId}/`;

    const config = Api.getConfigNoAuth();

    let answers;
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        config
      );
      answers = response.data;
    } catch (error) {
      return error.response.data.error;
    }
    return answers;
  }

  static async createAnswers(payload, invitationId) {
    const url = `/polls/${invitationId}/answers/`;

    const config = Api.getConfigNoAuth();

    let answers;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        payload,
        config
      );
      answers = response.data;
    } catch (error) {
      return error.response.data.error;
    }
    return answers;
  }
}
export default Api;
