import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import EditButton from "./editButton";
import { Icon } from "@iconify/react";

const SaveButton = ({ handleClick, saveError }) => {
  const [status, setStatus] = useState(null);
  let LOADING = 3;
  let ERROR = 1;
  let SAVE = 0;

  const { t } = useTranslation();
  const stateButton = async () => {
    setStatus(LOADING);
    handleClick && (await handleClick());

    saveError === true ? setStatus(ERROR) : setStatus(SAVE);
  };
  useEffect(() => {
    (status === ERROR || status === SAVE) &&
      setTimeout(() => {
        setStatus(null);
      }, 2000);
  }, [status]);

  return (
    <div className="save-buttons-container">
      <div
        className={`save-button-container loading ${
          status === LOADING && "save-active"
        }`}
      >
        <Icon icon="eos-icons:bubble-loading" />
      </div>
      <div
        className={`save-button-container save ${
          status === SAVE && "save-active"
        }`}
      >
        <EditButton
          className="save-button"
          icon="fa6-solid:check"
          text={t("design.savedDesign")}
        />
      </div>
      <div
        className={`save-button-container error-save ${
          status === ERROR && "save-active"
        }`}
      >
        <EditButton
          className="save-button"
          icon="heroicons:trash-solid"
          text={t("design.errorDesign")}
        />
      </div>
      <div
        className={`save-button-container save-button-original ${
          status === null && "prosesing"
        }`}
      >
        <EditButton
          className="save-button"
          icon="fluent:save-24-regular"
          text={t("design.saveDesign")}
          onClick={stateButton}
        />
      </div>
    </div>
  );
};
export default SaveButton;
