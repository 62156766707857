import "../../assets/css/toggleButton.css";
import { useEffect, useState } from "react";

const ToggleButton = ({ id, onClick, checked, className, validations }) => {
  const [check, setcheck] = useState(false);

  useEffect(() => {
    if (checked == true) {
      setcheck(true);
    } else {
      setcheck(false);
    }
  }, [checked]);
  return (
    <div className={`toggle-container ${className}`}>
      {validations ? (
        <input
          onClick={onClick}
          type="checkbox"
          {...validations}
          className={`toggle-input ${className}`}
          id={id}
        />
      ) : (
        <input
          type="checkbox"
          className={
            check
              ? `toggle-input toggle-input-checked ${className}`
              : `toggle-input ${className}`
          }
          onChange={() => setcheck(!check)}
          onClick={onClick}
          id={id}
          checked={checked}
        />
      )}
      <label htmlFor={id} className="toggle-label" id="toggle-label"></label>
    </div>
  );
};
export default ToggleButton;
