import {QRCodeSVG} from 'qrcode.react';

export default function InvitationQr({ invitationId, groupId }) {
  return (
    <>
      <QRCodeSVG
        value={`kolibird:${invitationId},${groupId}`}
        size={window.innerWidth < 650 ? window.innerWidth * 0.8 : 200}
        bgColor={"#ffffff"}
        fgColor={"#000000"}
        level={"L"}
        includeMargin={false}
        imageSettings={{
          src: "https://kolibird-resources.s3.eu-west-3.amazonaws.com/qrs/logoQr.png",
          x: undefined,
          y: undefined,
          height: 30,
          width: 30,
          excavate: true,
      }}/>
    </>
  );
}
