import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../context/state";
import { useForm } from "react-hook-form";
import Invitation from "./invitation";
import EditButton from "../general/editButton";
import letter from "../../assets/img/tempLetter.png";
import tempInvitation from "../../assets/img/tempInvitation.png";

export default function BodyEmail({ dataProps }) {
  const { t, i18n } = useTranslation();
  const context = useAppContext();
  // const [infoEvent] = useState(invitation);
  const invitation = dataProps.infoEvent.invitation;
  const [editEmailTitle, setEditEmailTitle] = useState(false);
  const [logoImage, setLogoImage] = useState("https://kolibird-resources.s3.eu-west-3.amazonaws.com/emailSignature/signatureKolibird.png");
  const [backColor, setBackColor] = useState("#efe1f2");
  const [buttonColor, setButtonColor] = useState("#e33d8c");

  const {
    register,
    watch,
    formState: { errors },
  } = useForm({ mode: "all" });
  const evenDate = new Date(invitation.date);

  const handleSubmit = (e) => {
    e.preventDefault();
    dataProps?.onSubmit(e);

    errors.email_title || !invitation?.email_title?.length > 0
      ? setEditEmailTitle(true)
      : setEditEmailTitle(false);
  };

  useEffect(() => {
    (() => {
      errors.email_title || !invitation.email_title?.length > 0
        ? setEditEmailTitle(true)
        : setEditEmailTitle(false);
    })();
  if(invitation?.invitation_logo){
    setLogoImage(invitation.invitation_logo);
  }

  if(invitation?.button_color){
    setButtonColor(invitation?.button_color)
  }

  if(invitation?.email_background_color){
    setBackColor(invitation?.email_background_color)
  }


  }, [dataProps.infoEvent?.invitation]);

  return (
    <div className="email-body " style={{backgroundColor: backColor}}>
      <p>{t("email.messageSample")}</p>
      <div id="header">
        <div className="email-header">
          <div className="align-center">
            <img
              className="logo"
              width="200px"
              src={logoImage}
            />
          </div>
        </div>
      </div>
      <div className="body-table email-table " role="presentation">
        <div>
          <div id="main" className="main-email">
            <div className="message-wrapper align-center">
              {dataProps.headerMessage && (
                <>
                  <h1 className="email-h1 separation">
                    {`${t("email.hi")} ${
                      context.sharedState.invitation.sender_name
                    } ${t("email.to")}: `}
                  </h1>
                  <div className=" email-info-field email-body-input separation">
                    <div className=" email-title-p">
                      <h2 className="email-body-title " style={{color: "#0d060f"}}>
                        {context.sharedState.invitation.event_name}
                      </h2>
                    </div>
                  </div>
                  {/* 
                  {editEmailTitle ? (
                    <form onSubmit={handleSubmit}>
                      <div className=" email-info-field email-body-input separation">
                        <div
                          id="info-input"
                          className={
                            !errors?.email_title && watch().email_title?.length
                              ? " input-ok info-input-container"
                              : "  info-input-container"
                          }
                        >
                          <input
                            {...register("email_title", {
                              required: true,
                              maxLength: 25,
                            })}
                            maxLength="25"
                            autoFocus
                            type="text"
                            id="email_title"
                            className={
                              errors?.email_title ? "error input" : "input"
                            }
                            defaultValue={invitation.email_title}
                          />
                          {errors?.email_title && (
                            <span className="error-point"></span>
                          )}

                          {errors?.email_title?.type === "maxLength" && (
                            <span className="error-text">
                              Maximo 25 caracteres
                            </span>
                          )}
                          {errors?.email_title?.type === "required" && (
                            <span className="error-text">Campo requerido</span>
                          )}
                        </div>
                        <div className="email-edit-button-container">
                          <EditButton
                            text={t("general.save")}
                            icon={"material-symbols:save-outline"}
                            color="#E33D8C"
                            colorText="#361A3C"
                          />
                          {invitation.email_title?.length ? (
                            <EditButton
                              onClick={() => setEditEmailTitle(false)}
                              type="button"
                              text={t("general.cancel")}
                              icon={"fluent:dismiss-20-filled"}
                              color="#E33D8C"
                              colorText="#361A3C"
                            />
                          ) : null}
                        </div>
                      </div>
                    </form>
                  ) : (
                    <div className=" email-title-p">
                      <h2 className="email-body-title ">
                        {invitation.email_title}
                      </h2>

                      <EditButton
                        onClick={() => setEditEmailTitle(true)}
                        type="button"
                        text=""
                        icon={"fluent:edit-48-filled"}
                        color="#E33D8C"
                        colorText="#361A3C"
                      />
                    </div>
                  )} */}

                  <p className="email-date align-center" style={{color: "#0d060f"}}>
                    {`${evenDate.toLocaleDateString(i18n.language, {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}`}{" "}
                    {t("email.textHours")}{" "}
                    {`${evenDate.getUTCHours()}:${
                      (evenDate.getMinutes() < 10 ? "0" : "") +
                      evenDate.getMinutes()
                    }`}
                    h
                  </p>

                  {invitation.place_name?.length &&
                  invitation.place_address?.length ? (
                    <>
                      <p className="email-address align-center separation" style={{color: "#0d060f"}}>
                        {`${invitation.place_name}`}
                      </p>
                      <p className="email-address align-center separation" style={{color: "#0d060f"}}>
                        {`${invitation.place_address}`}
                      </p>
                    </>
                  ) : null}
                  {dataProps.personalMessage && (
                    <>
                      <div className=" email-title-p email-body-textarea-p separation">
                        <p className="email-title-content" style={{color: "#0d060f"}}>
                          {invitation?.email_content}
                        </p>
                      </div>
                    </>
                  )}
                  <div
                    className="align-center button-wrapper email-table separation align-center cta-button "
                    role="presentation"
                  >
                    <a
                      className="email-a secondary-button "
                      href={`/invitation/${context.sharedState.invitation.uuid}/`}
                      target="_blank"
                      style={{color: buttonColor}}
                    >
                      {t("email.noAssist")}
                    </a>
                    <a
                      className="email-a primary-button "
                      href={`/invitation/${context.sharedState.invitation.uuid}/`}
                      target="_blank"
                      style={{backgroundColor: buttonColor}}
                    >
                      {t("email.assist")}
                    </a>
                  </div>
                </>
              )}
              {dataProps.envelope ? (
                <Invitation
                  className="separation"
                  configuration={
                    context.sharedState.invitation.envelope_configuration
                  }
                />
              ) : (
                <Invitation
                  className="separation"
                  configuration={context.sharedState.invitation.configuration}
                />
              )}

              <div
                className="align-center button-wrapper email-table separation align-center cta-button "
                role="presentation"
              >
                <a
                  className="email-a primary-button "
                  href={`/invitation/${context.sharedState.invitation.uuid}/`}
                  target="_blank"
                  style={{backgroundColor: buttonColor}}
                >
                  {t("email.openInvitation")}
                </a>
              </div>
            </div>
          </div>
          <div
            role="presentation"
            id="footer"
            className="footer-email align-center"
          >
            <p className="copyright">
              {t("email.noReply")} <a href="" style={{color: buttonColor}}>{t("email.noReplyTwo")}</a>
            </p>
          </div>
        </div>
      </div>
      <div role="presentation" className="align-center copyright-container">
        <p className="copyright">© {t("email.allRights")} </p>
      </div>
    </div>
  );
}
