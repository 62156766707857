import { useEffect } from 'react';
import useQuery from "../../hooks/useQuery"
import { useParams } from "react-router-dom";
import PaymentCanceled from "../../components/payments/paymentCanceled";
import useCheckLogin from "../../hooks/useCheckLogin"

function PaymentCanceledPage() {

  const [checkLogin] = useCheckLogin();
  const { uuid } = useParams();
  const query = useQuery();

    useEffect(() => {
      const check= async()=>{
          await checkLogin();}
      check()
  }, []);

  return (
    <>
      <PaymentCanceled paymentUuid={uuid} toUrl={query.get("next")} />
    </>
  );
}
export default PaymentCanceledPage;
