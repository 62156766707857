import SignUp from "../../components/users/signUp";

function SignUpPage() {
  return (
    <>
      <SignUp toUrl="/my-account" />
    </>
  );
}
export default SignUpPage;
