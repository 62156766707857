import logo from "../../assets/img/logo.svg";
import "../../assets/css/header.css";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useAppContext } from "../../context/state";
import UsersServices from "../../services/usersServices";
import Modal from "./modal";
import SignOut from "../users/signOut";
import { Icon } from "@iconify/react";
import UserApi from "../../services/usersApi";
import Button from "./Button";

function Header() {
  const context = useAppContext();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [modalopen, setModalopen] = useState(false);
  const [numberInvitationHover, setNumberInvitationHover] = useState(false);
  const [isLog, setIslog] = useState(false);
  const [user, setUser] = useState();
  const { t, i18n } = useTranslation();
  const currentLenguage = i18n.language.slice(0, 2);
  let pathname = window.location.pathname;
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIslog(context.user);
  }, [context.user]);

  return pathname == "/sign-in" ||
    pathname == "/sign-up" ||
    pathname?.includes("/invitation/") ? (
    <></>
  ) : (
    <>
      {!context.user?.has_first_purchase && (
        <div className="announcement-container">
          <p className="announcement-text">
            {t("announcement.announcementOne")}{" "}
            <span>{t("announcement.announcementSpan")}</span>{" "}
            {t("announcement.announcementTwo")}
          </p>
        </div>
      )}
      <header className="header_container">
        <div className="header container">
          <div className="logo-header">
            <a
              href={`${process.env.REACT_APP_CURRENT_URL}${
                currentLenguage == "en" ? `/${currentLenguage}/` : ""
              }`}
            >
              <img className="logo-header" src={logo} alt="logo" />
            </a>
          </div>

          <div className={isOpen ? "nav-container active" : "nav-container "}>
            <span className="close-nav " onClick={handleClick}></span>
            <div className="button-nav-bar" onClick={handleClick}></div>
            <nav className="nav ">
              <div className="nav-link">
                <a
                  href={`${process.env.REACT_APP_CURRENT_URL}${
                    currentLenguage == "en" ? `/${currentLenguage}/` : ""
                  }`}
                  className={`nav-item ${
                    pathname === "/" && "nav-item-active"
                  }`}
                >
                  {t("header.home")}
                </a>
                <div
                  className="header-nav header-events-types-container"
                  onMouseEnter={(e) =>
                    e.currentTarget.classList.add("active-header-events-types")
                  }
                  onMouseLeave={(e) =>
                    e.currentTarget.classList.remove(
                      "active-header-events-types"
                    )
                  }
                >
                  <div className="nav-item header-nav-item">
                    <p className=" header-lenguage">{t("general.event")}</p>
                  </div>
                  <div className="header-events-types">
                    <a
                      className="header-nav-item nav-item"
                      href={`${process.env.REACT_APP_CURRENT_URL}${
                        currentLenguage == "en" ? `/${currentLenguage}/` : "/"
                      }${t("general.wedding.url")}`}
                    >
                      {t("general.wedding.text")}
                    </a>
                    <a
                      className="header-nav-item nav-item"
                      href={`${process.env.REACT_APP_CURRENT_URL}${
                        currentLenguage == "en" ? `/${currentLenguage}/` : "/"
                      }${t("general.birthday.url")}`}
                    >
                      {t("general.birthday.text")}
                    </a>
                    <a
                      className="header-nav-item nav-item"
                      href={`${process.env.REACT_APP_CURRENT_URL}${
                        currentLenguage == "en" ? `/${currentLenguage}/` : "/"
                      }${t("general.communion.url")}`}
                    >
                      {t("general.communion.text")}
                    </a>
                    <a
                      className="header-nav-item nav-item"
                      href={`${process.env.REACT_APP_CURRENT_URL}${
                        currentLenguage == "en" ? `/${currentLenguage}/` : "/"
                      }${t("general.companies.url")}`}
                    >
                      {t("general.companies.text")}
                    </a>
                  </div>
                </div>

                <a
                  href={`${process.env.REACT_APP_CURRENT_URL}${
                    currentLenguage == "en" ? `/${currentLenguage}` : "/"
                  }pricing/`}
                  className="nav-item"
                >
                  {t("header.pricing")}
                </a>
                <a
                  href={`${process.env.REACT_APP_CURRENT_URL}/blog/`}
                  className={`nav-item ${
                    pathname === "/blog" && "nav-item-active"
                  }`}
                >
                  {t("header.blog")}
                </a>
                <Link
                  className={`nav-item ${
                    pathname === "/contact-us" && "nav-item-active"
                  }`}
                  to="/contact-us"
                >
                  {t("header.contact")}
                </Link>
              </div>
              <div className="acaunt">
                {!isLog ? (
                  <>
                    <Link
                      to="/sign-in"
                      className="sing-in"
                      onClick={handleClick}
                    >
                      {t("general.signIn")}
                    </Link>
                    <Link to="/sign-up" onClick={handleClick}>
                      <Button type="primary" text={t("general.signUp")} />
                    </Link>
                  </>
                ) : (
                  <>
                    <div
                      className="header-number-invitation"
                      onMouseEnter={() => setNumberInvitationHover(true)}
                      onMouseLeave={() => setNumberInvitationHover(false)}
                    >
                      <div
                        className={`hover-total-invitation hover-${numberInvitationHover}`}
                      >
                        <p className="hover-total-invitation-display">
                          {t("header.hoverInvitations")}:{""}
                          {` ${context.user?.total_invitations}`}
                        </p>
                      </div>
                      <div
                        className="header-total-invitation-icon-container"
                        onClick={() => navigate("/buy-invitations")}
                      >
                        <p className="number-total-Invitation">
                          {context.user?.total_invitations}
                        </p>
                        <Icon icon="fluent-mdl2:greeting-card" />
                      </div>
                    </div>

                    <Link
                      className="icon-profile"
                      to="/my-account/my-events"
                      onClick={handleClick}
                    >
                      <Icon icon="bi:person-fill" />

                      <p>{context.user?.username}</p>
                    </Link>
                  </>
                )}
              </div>
            </nav>
          </div>
          <Modal onOpen={modalopen} setOnOpen={setModalopen}>
            <SignOut setOnOpen={setModalopen} />
          </Modal>
        </div>
      </header>
    </>
  );
}
export default Header;
