import { EnvCardPiece } from "./EnvCardPiece";
import { useEffect } from "react";
import { CustomCanvas } from "./CustomCanvas";

export const CardPiece = ({ element, store }) => {
  const googleFonts = (fontFamily) => {
    const newLink = document.createElement("link");
    newLink.href = `https://fonts.googleapis.com/css?family=${fontFamily}`;
    newLink.rel = "stylesheet";
    document.head.append(newLink);
  };
  const image = new Image();
  let newSRC = element.src;

  /* if (src.includes('link.storjshare.io')) {
          const arrSrc = src.split('/')
          const nameImage1 = arrSrc[arrSrc.length - 1]
          const nameImage2 = nameImage1.substring(0, nameImage1.length - 7)
          const nameImage3 = nameImage2.split('%2F')[2]
          const arrmok = mokup.filter((e, i) => {
              return e.includes(nameImage3)
          })
          newSRC = arrmok[0]
      } */

  image.src = newSRC;
  useEffect(() => {
    if (element.type === "text") {
      const normalText = element.fontFamily;
      const normalizeText =
        normalText.length > 1
          ? normalText.split(" ").join("+") + "&display=swap"
          : normalText;
      googleFonts(normalizeText);
    }
  }, [element]);

  const flip = () => {
    const x = element.flipX ? "-1" : "1";
    const y = element.flipY ? "-1" : "1";
    return `scale(${x}, ${y})`;
  };

  const filters = () => {
    let filter;
    const blur = element.blurEnable ? `blur(${element.blurRadius}px )` : "";
    const exposition = element.brightnessEnabled ? `brightnessEnable:(1)` : "";
    const sepia = element.sepiaEnabled ? "sepia(1)" : "";
    const greyScale = element.grayscaleEnabled ? "grayscale(1)" : "";

    return `${blur}  ${exposition} ${sepia} ${greyScale}`;
  };

  if (element.type === "svg") {
    const { colorsReplace, height, rotation, src, width, x, y } = element;

    const myColors = Object.entries(colorsReplace);

    const externalStyles = {
      position: "absolute",
      top: `${y}px`,
      left: `${x}px`,
      width: `${width}px`,
      height: `${height}px`,
      color: myColors.length ? Object.entries(colorsReplace)[0] : null, //Si se quita esta linea dejo de poder ver las imagenes de nuestro estudio
      transform: `rotate(${rotation.toFixed(2)}deg)`,
    };

    return (
      <EnvCardPiece
        elementStyles={{
          ...externalStyles,
          rotate: `${element.rotation}deg`,
          transformOrigin: "top left ",
          transform: flip(),

          overflow: "hidden",
        }}>
        {/* <CustomCanvas
          src={src}
          element={element}
          elementStyles={externalStyles}
        /> */}
        <img
          src={element.src}
          style={{
            ...element,
            width: element.width / element.cropWidth,
            height: element.height / element.cropHeight,
          }}></img>
      </EnvCardPiece>
    );
  } else if (element.type === "text" && element.name !== "hostName") {
    const {
      align,
      fill,
      fontFamily,
      fontSize,
      fontStyle,
      fontWeight,
      height,
      letterSpacing,
      lineHeight,
      rotation,
      stroke,
      strokeWidth,
      text,
      textDecoration,
      width,
      x,
      y,
    } = element;

    const externalStyles = {
      position: "absolute",
      display: "flex",
      flexWrap: "wrap",
      top: `${y}px`,
      left: `${x}px`,
      width: `${width}px`,
      height: `${height}px`,
      transform: `rotate(${rotation.toFixed(2)}deg)`,
    };

    const internalStyles = {
      width: `${width || 100}px`,
      color: `${fill}`,
      fontSize: `${fontSize}px`,
      fontFamily: `${fontFamily}`,
      textDecoration: `${textDecoration}`,
      textAlign: `${align}`,
      lineHeight: `${lineHeight}`,
      letterSpacing: `${letterSpacing}`,
      fontStyle: `${fontStyle}`,
      fontWeight: `${fontWeight}`,
      WebkitTextStroke: `${strokeWidth}px ${stroke}`,
    };

    return (
      <EnvCardPiece
        elementStyles={{
          ...externalStyles,
          rotate: `${element.rotation}deg`,
          transformOrigin: "top left ",
          transform: flip(),
        }}>
        <div style={internalStyles}>
          {text.split("\n").map((e, i) => {
            if (e === "") return <br />;
            return element.name == "url" ? (
              <a
                href={
                  e.includes("http") || e.includes("https") ? e : `https://${e}`
                }
                target="_blank"
                style={internalStyles}
                key={i}>
                {e}
              </a>
            ) : (
              <p style={internalStyles} key={i}>
                {e}
              </p>
            );
          })}
          <br />
        </div>
      </EnvCardPiece>
    );
  } else if (element.type === "image") {
    const { cornerRadius, height, width, x, y } = element;

    const externalStyles = {
      position: "absolute",
      top: `${y}px`,
      left: `${x}px`,
      width: `${width}px`,
      height: `${height}px`,
      overflow: "visible",
      borderRadius: `${cornerRadius}px`,
      /* color: {{colorsReplace}} */
    };
    const flip = () => {
      const x = element.flipX ? "-1" : "1";
      const y = element.flipY ? "-1" : "1";
      return `scale(${x}, ${y})`;
    };

    return (
      <EnvCardPiece
        elementStyles={{
          ...externalStyles,
          rotate: `${element.rotation}deg`,
          transformOrigin: "top left ",
          transform: flip(),

          overflow: "hidden",
          filter: filters(),
          border: `${element.borderSize}px solid ${element.borderColor}`,
          element,
        }}>
        {/* <CustomCanvas
          src={element.src}
          element={element}
          elementStyles={externalStyles}
        /> */}

        <img
          src={element.src}
          style={{
            ...element,
            boxShadow: element.shadowEnabled
              ? `${element.shadowOffsetX}px ${element.shadowOffsetY}px ${element.shadowBlur}px ${element.blurRadius}px ${element.shadowColor}`
              : "none",
            objectFit: "cover",
            objectPosition: `-${
              element.cropX * (element.width / element.cropWidth)
            }px -${element.cropY * (element.height / element.cropHeight)}px`,

            width: element.width / element.cropWidth,
            height: element.height / element.cropHeight,
          }}></img>
      </EnvCardPiece>
    );
  }
};
