import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { SectionTab, TextSection } from "polotno/side-panel";
import { Button } from "@blueprintjs/core";
import { Icon } from "@iconify/react";
import ToggleButton from "../components/general/ToggleButton";

import square from "../assets/img/polotno/square.svg";
import rectanglev from "../assets/img/polotno/rectanglev.png";
import rectangleh from "../assets/img/polotno/rectangleh.png";
import ImagesGridPanel from "../components/invitations/polotno/imagesGridPanel";
import BackgroundPanel from "../components/invitations/polotno/backgroundPanel";
import TemplatesPanelSection from "../components/invitations/polotno/TemplatesPanelSection";
import { useAppContext } from "../context/state";
import waxSeal from "../assets/img/icons/waxSeal.svg";

import InvitationsServices from "../services/invitationsServices";
import StylePanel from "../components/invitations/polotno/stylePanel";
function useInvitationCustomPanels() {
  const { t } = useTranslation();
  const context = useAppContext();
  const textBlockedPage = t("design.boardUI.sidePanel.textBlockedPage");

  const sizes = [
    {
      name: "design.boardUI.toolbar.vertical",
      width: 612,
      height: 858,
      icon: rectanglev,
    },
    {
      name: "design.boardUI.toolbar.horizontal",
      width: 858,
      height: 612,
      icon: rectangleh,
    },
    {
      name: "design.boardUI.toolbar.square",
      width: 858,
      height: 858,
      icon: square,
    },
  ];

  const customSizeSection = {
    name: "sizes",
    Tab: (props) => (
      <SectionTab name={t("design.boardUI.sidePanel.size")} {...props}>
        <Icon icon="fluent:arrow-expand-24-regular" />
      </SectionTab>
    ),
    Panel: observer(({ store }) => {
      return (
        <div style={{ width: "80%" }}>
          {sizes.map(({ name, width, height, icon }, i) => (
            <div
              key={i}
              className="page-size-button"
              onClick={() => {
                store.setSize(width, height, true);
              }}
              style={{
                background: "#B06BBF",
                border: "1px solid #DFC4E6",
                borderRadius: "8px",
                marginBottom: "5px",
                color: "#FFFFFF",
                fontSize: "3vh",
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              <img src={icon} height="25" /> {t(name)}
            </div>
          ))}
        </div>
      );
    }),
  };

  const customTemplatesSection = {
    name: "custom-templates",
    Tab: (props) => (
      <SectionTab name={t("design.boardUI.sidePanel.templates")} {...props}>
        <Icon icon="fluent:board-24-regular" />
      </SectionTab>
    ),
    Panel: observer(({ store }) => {
      return <TemplatesPanelSection store={store} section="1" />;
      //return <ImagesGridPanel store={store} section={1} key={1} />;
    }),
  };

  const customPhotosSection = {
    name: "photosCustom",
    Tab: (props) => (
      <SectionTab name={t("design.boardUI.sidePanel.photos")} {...props}>
        <Icon icon="fluent:image-16-regular" />
      </SectionTab>
    ),
    Panel: observer(({ store }) => {
      return <ImagesGridPanel store={store} section={1} key={1} />;
    }),
  };

  const customIconsSection = {
    name: "iconsCustom",
    Tab: (props) => (
      <SectionTab name={t("design.boardUI.sidePanel.icons")} {...props}>
        <Icon icon="uil:icons" />
      </SectionTab>
    ),
    Panel: observer(({ store }) => {
      return <ImagesGridPanel store={store} section={2} key={2} />;
    }),
  };

  const customShapesSection = {
    name: "shapesCustom",
    Tab: (props) => (
      <SectionTab name={t("design.boardUI.sidePanel.shapes")} {...props}>
        <Icon icon="fluent:shapes-24-regular" />
      </SectionTab>
    ),
    Panel: observer(({ store }) => {
      return <ImagesGridPanel store={store} section={3} key={3} />;
    }),
  };

  const customBackgroundSection = {
    name: "backgroundCustom",
    Tab: (props) => (
      <SectionTab name={t("design.boardUI.sidePanel.background")} {...props}>
        <Icon icon="fluent:color-background-20-regular" />
      </SectionTab>
    ),
    Panel: observer(({ store }) => {
      return <BackgroundPanel store={store} section={4} />;
    }),
  };

  const customTextSection = {
    name: "photosCustoms",
    Tab: TextSection.Tab,

    Panel: observer(({ store }) => {
      let element;
      const groupElement = store.pages[0]?.children.find((element) => {
        return element.name === "url";
      });

      let state = groupElement?.id === undefined ? false : true;

      const toggleGroupName = async () => {
        if (groupElement?.id === undefined) {
          element = store.pages[0].addElement({
            type: "text",
            x: store.width / 2 - 300 / 2,
            y: store.height / 2,
            fill: "black",
            text: groupElement?.text ? groupElement.text : "URL",
            name: "url",

            textDecoration: "underline",
            fill: "#0036FF",
            fontSize: 25,
            width: 300,
            contentEditable: true,

            removable: false,
          });

          state = true;
        } else {
          store.deleteElements([groupElement?.id]);
          state = false;
        }
      };

      return (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
                justifyContent: "center",
                padding: "1rem  0",
                width: "100%",
              }}
            >
              <ToggleButton
                id="toggleGroupName"
                checked={state}
                onClick={toggleGroupName}
              />
              <p style={{ fontWeight: 600, margin: 0 }}>
                {t("design.boardUI.sidePanel.url")}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
                justifyContent: "center",
                // padding: "1rem  0",
                margin: "0 0 1rem 0",
                width: "100%",
                borderBottom: "1px solid #9ca7b3",
              }}
            ></div>
            <div
              style={{
                height: "calc(100% - 115px)",
              }}
            >
              {store.pages[1] != store.activePage &&
                TextSection.Panel({ store: store })}
            </div>
          </div>
        </>
      );
    }),
  };
  const styleSection = {
    name: "style",
    Tab: (props) => (
      <SectionTab name={t("design.boardUI.sidePanel.style")} {...props}>
        <Icon icon="fluent:design-ideas-24-regular" />
      </SectionTab>
    ),
    Panel: observer(({ store }) => {
      return <StylePanel store={store} size={0} />;
    }),
  };

  return {
    customSizeSection: customSizeSection,
    customPhotosSection: customPhotosSection,
    styleSection: styleSection,
    customBackgroundSection: customBackgroundSection,
    customIconsSection: customIconsSection,
    customShapesSection: customShapesSection,
    customTemplatesSection: customTemplatesSection,
    customTextSection: customTextSection,
  };
}

export default useInvitationCustomPanels;
