import { CirclePicker } from "react-color";
import { useTranslation } from "react-i18next";
import ColorPicker from "polotno/toolbar/color-picker";

import "../../../assets/css/polotno/stylePanel.css";
import { useState } from "react";

import Spinner from "../../general/Spinner";
import { imageUrl } from "../../../constants/texture";
import { Icon } from "@iconify/react";

export default function StylePanel({ store, size = 1 }) {
  const { t } = useTranslation();
  const [colorPickervalue, setColorPickerValue] = useState(
    store.pages[0].background
  );

  const [currentTexture, setCurrentTexture] = useState(null);
  const unclassifiedColors = [
    "#ffffff",
    "#fcfcf5",
    "#fdf4cf",
    "#f4e5bd",
    "#d3c0ab",
    "#b1a99c",
    "#585656",
    "#282828",
    "#3b5a62",
    "#101f52",
    "#20284c",
    "#1c2080",
    "#3548bf",
    "#69b2d8",
    "#bce3f9",
    "#d4e3e8",
    "#8c9dae",
    "#a9c7cf",
    "#cce2d9",
    "#b1dfdb",
    "#62aba9",
    "#0d6e5b",
    "#14773e",
    "#485f24",
    "#144402",
    "#1d3714",
    "#7a8372",
    "#77815c",
    "#9fb049",
    "#b6d522",
    "#39ff14",
    "#bdd198",
    "#cdd6b7",
    "#fcf29d",
    "#ffd952",
    "#eab415",
    "#daa521",
    "#f2af46",
    "#ff9600",
    "#fd5125",
    "#f78665",
    "#f8bf9d",
    "#ffcbb3",
    "#fae2d3",
    "#ffa5a5",
    "#f26b65",
    "#dd003e",
    "#cd2027",
    "#b0321a",
    "#b11c18",
    "#700909",
    "#fd01ca",
    "#e70272",
    "#f24e73",
    "#fd7ba7",
    "#fda9c5",
    "#fcdddd",
    "#ffecec",
    "#e4bcbd",
    "#be9494",
    "#d0a2bb",
    "#e3c4db",
    "#ae61a9",
    "#851248",
    "#461e38",
    "#795768",
    "#948ba0",
    "#bbb7c6",
    "#b4a9cb",
    "#c7c7f3",
    "#663399",
    "#460066",
    "#270c43",
    "#5c4120",
    "#bf9e7d",
    "#ae6b58",
    "#b2b2b2",
    "#d0d0d0",
    "#e5e4db",
  ];

  const changeBackgroundColor = (newColor) => {
    store.pages.map((page) => {
      page.set({
        background: newColor.hex,
      });
      setColorPickerValue(newColor.hex);
    });
  };

  return (
    <>
      {!imageUrl ? (
        <Spinner />
      ) : (
        <div className="custom-panel">
          <div className="custom-color-palette">
            {size === 1 && (
              <>
                <p className="color-palette-title">
                  {t("design.boardUI.sidePanel.colors")}
                </p>
                <div className="polotno-color-picker-wrapper">
                  <p className="polotno-color-picker-title">Picker</p>
                  <ColorPicker
                    value={colorPickervalue}
                    onChange={(newColor) => {
                      store.pages.map((page) => {
                        page.set({
                          background: newColor,
                        });
                      });
                      setColorPickerValue(newColor);
                    }}
                    store={store}
                  />
                </div>
                <CirclePicker
                  circleSize={12}
                  colors={unclassifiedColors}
                  circleSpacing={12}
                  onChangeComplete={changeBackgroundColor}
                />
              </>
            )}
            <p
              style={{
                margin: "30px 0 30px 12px",
              }}
            >
              {t("design.boardUI.sidePanel.textures")}
            </p>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(4, 1fr)",
                gap: 10,
                marginTop: "30px",
              }}
            >
              <div
                className={`texture-none ${
                  !currentTexture && "texture-selected"
                }`}
                onClick={() => {
                  store.pages.map((textItem) => {
                    const textureItem = textItem
                      .toJSON()
                      .children.find((chiel) => chiel.name === "texture");

                    if (textureItem) {
                      store.deleteElements([textureItem.id]);
                    }

                    setCurrentTexture(null);
                  });
                }}
              >
                <Icon
                  style={{
                    height: "100%",
                    width: "100%",
                    color: "#ff0000ba",
                  }}
                  icon="fluent-mdl2:blocked"
                />
              </div>

              {imageUrl.map((item, index) => {
                return (
                  <div
                    className={`texture ${
                      item === currentTexture && "texture-selected"
                    }`}
                    key={index}
                    onClick={async () => {
                      store.pages.map((textItem) => {
                        const textureItem = textItem
                          .toJSON()
                          .children.find((chiel) => chiel.name === "texture");

                        if (textureItem) {
                          store.deleteElements([textureItem.id]);
                        }

                        const elemento = textItem.addElement({
                          type: "image",
                          width: store.width,
                          height: store.height,
                          x: 0,
                          y: 0,
                          locked: true,
                          name: "texture",
                          src: `${item}?seed=${Math.random()}`,
                          selectable: false,
                        });
                        elemento.moveBottom();
                        setCurrentTexture(item);
                      });
                    }}
                  >
                    <img
                      src={item}
                      className="texture"
                      style={{
                        background: colorPickervalue,
                        width: "100%",
                        height: "100%",
                        borderRadius: "12px",
                        cursor: "pointer",
                        boxShadow: "2px 2px 5px #0000005e",
                        aspectRatio: "1 / 1",
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
