import { useState, useEffect } from "react";
import DesignSteps from "./designSteps";
import EnvelopeDesignBoard from "./envelopeDesignBoard";
import InvitationDesignBoard from "./invitationDesignBoard";
import { createStore } from "polotno/model/store";
import { useAppContext } from "../../context/state";
import InvitationsServices from "../../services/invitationsServices";
import Datalayer from "../../services/datalayer";
import Spinner from "../general/Spinner";

function DesignBoard({ designStep, isTrying = false }) {
  const [step, setStep] = useState(designStep.designStep);
  const [saveError, setIsSaveError] = useState();
  const [isLoading, setIsloading] = useState(true);

  const context = useAppContext();

  const [invitationStore] = useState(
    createStore({
      key: process.env.REACT_APP_POLOTNO_STORE_KEY,
    })
  );
  const [currentEnvelopeStore] = useState(
    createStore({
      key: process.env.REACT_APP_POLOTNO_STORE_KEY,
    })
  );

  const saveDesign = async (envelopeStore = currentEnvelopeStore) => {
    let updatedInvitation;
    // const groupElement = envelopeStore.pages[0]?.children.find((element) => {
    //   return element.name === "letter";
    // });
    // envelopeStore.deleteElements([groupElement?.id]);

    if (invitationStore.toJSON().width === 1080) {
      invitationStore.setSize(858, 858, true);
    }

    if (step === 0 && invitationStore !== null) {
      updatedInvitation = await InvitationsServices.saveDesign(
        context.sharedState.invitation,
        invitationStore,
        null,
        !isTrying
      );
    } else if (step === 1) {
      updatedInvitation = await InvitationsServices.saveDesign(
        context.sharedState.invitation,
        null,
        envelopeStore,
        !isTrying
      );
    }

    if (updatedInvitation) {
      setIsSaveError(false);
      context.setSharedState({
        ...context.sharedState,
        invitation: { ...updatedInvitation },
      });

      context.setInvitationStore(invitationStore);
      context.setEnvelopeStore(envelopeStore);
      Datalayer.push("design_step");
      setIsloading(false);

      return updatedInvitation;
    } else {
      setIsSaveError(true);
    }
    setIsloading(false);
  };

  useEffect(() => {
    (async () => {
      setIsloading(true);
      // if (designStep.designStep === 0) {
      await saveDesign();
      setStep(designStep.designStep);
      // }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [designStep.designStep, context.sharedState.invitation !== undefined]);

  return (
    <div className="design-board-wrapper">
      <DesignSteps
        setCurrentStep={designStep.setDesignStep}
        step={step}
        error={saveError}
        saveDesign={saveDesign}
        setIsloading={setIsloading}
      />
      <div style={{ position: "relative" }}>
        {step === 0 && (
          <>
            <InvitationDesignBoard
              store={invitationStore}
              loading={isLoading}
            />

            {context.user?.is_staff && (
              <div style={{ overflowWrap: "break-word" }}>
                <p>Imagen: {context.sharedState.invitation?.image}</p>
                <hr />
                <p>
                  {JSON.stringify(
                    context.sharedState.invitation?.configuration
                  )}
                </p>
              </div>
            )}
          </>
        )}
      </div>
      {step === 1 && (
        <>
          <EnvelopeDesignBoard
            saveDesign={saveDesign}
            store={currentEnvelopeStore}
            designStep={designStep.designStep}
            loading={isLoading}
          />
          {context.user?.is_staff && (
            <div style={{ overflowWrap: "break-word" }}>
              <p>Imagen: {context.sharedState.invitation?.envelope_image}</p>
              <hr />
              <p>
                {JSON.stringify(
                  context.sharedState.invitation?.envelope_configuration
                )}
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
}
export default DesignBoard;
