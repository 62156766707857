import { useState } from "react";
import RadioButton from "../general/RadioButton";

function SingleOptionForm(
  props = { possibilities: [], value: "", guestId: "" }
) {
  const [optionSelected, setOptionSelected] = useState(props.value);
  const [required, setRequired] = useState(props.question.required);

  const handleClick = async (e, possibility) => {
    setOptionSelected(possibility.id);
    const resultAnswer = await props.modifyForm(
      possibility.question,
      possibility.id
    );

    setRequired(resultAnswer.length > 0 ? false : props.question.required);
  };

  return (
    <div>
      {props.possibilities.map((possibility) => {
        return (
          <div
            className="invitation-question-single-option"
            key={possibility.uuid}>
            <RadioButton
              id={`${possibility.id}${props.guestId}`}
              name={`${possibility.question}${props.guestId}`}
              onClick={(e) => {
                handleClick(e, possibility);
              }}
              required={required}
              defaultChecked={possibility?.id == optionSelected}
            />

            <label htmlFor={`${possibility.id}${props.guestId}`}>
              {possibility.text}
            </label>
          </div>
        );
      })}
    </div>
  );
}
export default SingleOptionForm;
