import { useState } from "react";

function HourForm(props = { value: "" }) {
  const [hour, setHour] = useState(props.value);

  return (
    <div>
      <input
        className="input"
        type="hour"
        onChange={(event) => {
          setHour(event.target.value);
          props.modifyForm(props.question.id, event.target.value);
        }}
        value={hour}
        required={props.question.required}
      />
    </div>
  );
}
export default HourForm;
