import { useTranslation } from "react-i18next";
import useQuestions from "../../hooks/useQuestions";
import CheckBoxButton from "../general/CheckBoxButton";

function QuestionSingle(props = {}) {
  const { t } = useTranslation();

  const [
    required,
    setRequired,
    question,
    setQuestion,
    groupQuestion,
    setGroupQuestion,
    changeText,
  ] = useQuestions(props);

  const getTitle = () => {
    switch (props.question.type) {
      case "4":
        return t("poll.numberField");
      case "5":
        return t("poll.shortTextField");
      case "6":
        return t("poll.textField");
      case "7":
        return t("poll.emailField");
      case "8":
        return t("poll.dateField");
      case "9":
        return t("poll.hourField");
      case "10":
        return t("poll.loadFileField");
      default:
        return t("poll.unknown");
    }
  };

  return (
    <>
      <h4 className="type-question">{getTitle()}</h4>

      <p className="number-question">
        {t("poll.question")} {props.index + 1}
      </p>
      <div className="info-input-container question-input-container">
        <input
          className="input"
          type="text"
          onChange={(event) => setQuestion(event.target.value)}
          onBlur={(event) => changeText(event, props.question.uuid)}
          value={question != "null" ? question : ""}
        />
      </div>
      <div className="checkbox-options">
        <div className="checkbox-option">
          <CheckBoxButton
            id={`obligatory-multi-checkbox-${props.index}`}
            type="checkbox"
            onClick={(event) => setRequired(event.target.checked)}
            checked={required}
          />
          <label>{t("poll.labelObligatory")}</label>
        </div>
        <div className="checkbox-option">
          <CheckBoxButton
            id={`all-guests-multi-checkbox-${props.index}`}
            type="checkbox"
            onClick={(event) => setGroupQuestion(event.target.checked)}
            checked={groupQuestion}
          />
          <label>{t("poll.labelAllGuests")}</label>
        </div>
      </div>
    </>
  );
}
export default QuestionSingle;
