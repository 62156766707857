import { useEffect, useState } from "react";
import { useAppContext } from "../../context/state";
import { useParams } from "react-router-dom";
import DesignBoard from "./designBoard";
import InformationBoard from "./informationBoard";
import PreviewBoard from "./previewBoard";
import PollBoard from "../polls/pollBoard";
import "../../assets/css/creationBoard.css";
import GuestsListBoard from "./guestList/guestsListBoard";
import TrackingBoard from "./trackingBoard";
import Api from "../../services/invitationsApi";
import PollsApi from "../../services/pollsApi";
import InvitationsServices from "../../services/invitationsServices";
import UsersServices from "../../services/usersServices";
import Stepper from "./Stepper";
import useStepper from "../../hooks/useStepper";
import EditButton from "../general/editButton";
import { useTranslation } from "react-i18next";
import useScrollbySize from "../../hooks/useScroll";
import { set } from "react-hook-form";

function CreationBoard(props) {
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [designStep, setDesignStep] = useState(0);
  const context = useAppContext();
  const { id } = useParams();
  const scroll = useScrollbySize(800, 600, "top");

  const stepperList = [
    t("creation.design"),
    t("creation.information"),
    t("creation.questionary"),
    t("creation.preview"),
    t("creation.guestsList"),
    t("creation.tracking"),
  ];

  useEffect(() => {
    // Effect to recover the invitation
    const recoverInvitation = async (invitationId) => {
      let invitation = context.sharedState.invitations?.filter(
        (invitation) => invitationId === invitation.uuid
      );

      if (invitation.length > 0) {
        invitation = invitation[0];
      } else {
        invitation = await Api.getInvitation(invitationId);
      }

      const questions = await PollsApi.getQuestions(invitationId);
      const questionsPossibilities = await PollsApi.getPossibilities(
        invitationId
      );

      context.setSharedState({
        ...context.sharedState,
        invitation: invitation,
        questions: questions,
        questionsPossibilities: questionsPossibilities,
      });
    };
    scroll();
    recoverInvitation(id);
  }, []);

  return (
    context.sharedState.invitation && (
      <div className="creation-board-container">
        <Stepper
          stepState={step}
          setStepState={setStep}
          stepperList={stepperList}
          currentStep={props.step}
          setDesignStep={setDesignStep}
          designStep={designStep}
        />

        <div className="creation-board container ">
          {step === 0 && (
            <DesignBoard designStep={{ designStep, setDesignStep }} />
          )}
          {step === 1 && <InformationBoard />}
          {step === 2 && <PollBoard />}
          {step === 3 && <PreviewBoard />}
          {step === 4 && <GuestsListBoard />}
          {step === 5 && <TrackingBoard />}
        </div>
      </div>
    )
  );
}
export default CreationBoard;
