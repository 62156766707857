import { useState } from "react";

const useFilterObject = () => {
  const [orientation, setOrientation] = useState(0);

  function filtering(object, key, value, secondArrayKey) {
    if (secondArrayKey) {
      const fiteringTemp = object?.filter((item) =>
        item[secondArrayKey].find((secondItem) => secondItem[key] === value),
      );

      return fiteringTemp;
    } else {
      return object?.filter((item) => item[key] === value);
    }
  }

  function filteringNestedArray(object, nestedArray, key, value) {
    const filteredObject = object?.filter((response) => {
      const filteredNestedArray = response[nestedArray].filter(
        (res) => res[key] == value,
      );
      if (filteredNestedArray.length > 0) {
        response[nestedArray] = filteredNestedArray;
        return response;
      }
    });
    return filteredObject;
  }
  const searchFilter = (copyObjent, keys, value) => {
    let render;
    if (value?.length < 1) {
      return (render = copyObjent);
    }
    render = copyObjent?.filter((group) =>
      keys.find((key) =>
        group[key].toLowerCase().includes(value?.toLowerCase()),
      ),
    );

    return render;
  };

  const searchInfo = (dataArray, keys, value) => {
    let render;
    if (value?.length < 1) {
      return (render = []);
    }
    render = dataArray?.filter((group) =>
      keys.find((key) =>
        group[key].toLowerCase().includes(value?.toLowerCase()),
      ),
    );

    return render;
  };

  const orderFilter = (orderObject, key, direction) => {
    const order = orderObject?.sort((a, b) => (a[key] > b[key] ? 1 : -1));

    if (direction === "decreasing") {
      return order?.reverse();
    }
    if (direction === "creasing") {
      return order;
    }

    if (orientation === 0) {
      setOrientation(1);
      return order;
    } else if (orientation === 1) {
      setOrientation(0);

      return order.reverse();
    }
  };

  return {
    filtering,
    searchFilter,
    orderFilter,
    searchInfo,
    filteringNestedArray,
  };
};
export default useFilterObject;
