import useQuery from "../../hooks/useQuery"
import CreationBoardFake from "../../components/invitations/creationBoardFake";

function CreationDesignFakePage() {
  const query = useQuery();

  return (
    <>
      <CreationBoardFake template={query.get("template")}/>
    </>
  );
}
export default CreationDesignFakePage;
