import SignIn from "../../components/users/signIn";

function SignInPage() {
  return (
    <>
      <SignIn toUrl="/my-account" />
    </>
  );
}
export default SignInPage;
