import "../../assets/css/campForm.css";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const CampForm = ({
  campValue,
  defaultValue,
  inEdit,
  isSelect,
  options,
  setForm,
  form,
  register,
  resetField,
  errors,
}) => {
  const [item, setItem] = useState(defaultValue);
  const { t } = useTranslation();

  useEffect(() => {
    setItem(defaultValue);
    resetField(campValue);
  }, [inEdit]);

  return (
    <div className="campform__main">
      <p className="campform__text campform__campvalue">
        {t(`profilePage.${campValue}`)}:
      </p>

      {!inEdit ? (
        <p className="campform__text campform__defaultvalue">
          {!isSelect && !options.includes(defaultValue)
            ? item
            : options.map(
                (element) => element.value === defaultValue && element.name,
              )}
        </p>
      ) : !isSelect ? (
        <div
          id="info-input"
          className={" campform__input info-input-container"}
        >
          <input
            className="input campform__input"
            type="text"
            onInput={(e) => setItem(e.target.value)}
            value={item}
            {...register(
              campValue,
              campValue === "phone_number"
                ? {
                    pattern: /^[0-9]+$/i,
                    minLength: 8,
                    maxLength: 11,
                  }
                : {},
            )}
          />
          {errors?.phone_number && campValue === "phone_number" && (
            <span className="error-point"></span>
          )}
          {errors?.phone_number?.type === "pattern" &&
            campValue === "phone_number" && (
              <span className="error-text">Solo numeros</span>
            )}
          {errors?.phone_number?.type === "minLength" &&
            campValue === "phone_number" && (
              <span className="error-text">Telefono incorrecto</span>
            )}
          {errors?.phone_number?.type === "maxLength" &&
            campValue === "phone_number" && (
              <span className="error-text">Telefono incorrecto</span>
            )}
        </div>
      ) : (
        <div className=" campform__input">
          <select
            className="input event_title"
            onInput={(e) => setItem(e.target.value)}
            value={item || ""}
            {...register(campValue, {})}
          >
            {options?.map((element, i) => {
              return (
                <option value={element.value} key={i}>
                  {element.name}
                </option>
              );
            })}
          </select>
        </div>
      )}
    </div>
  );
};

export default CampForm;
