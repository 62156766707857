class Datalayer {
  static async push(event) {
    if (process.env.REACT_APP_ENV === "pro") {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event' : event,
        });
	}
  }
}
export default Datalayer;
