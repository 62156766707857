import RecoveryPassword from "../../components/users/recoveryPassword"

function RecoveryPasswordPage() {


  return (
    <>
      <RecoveryPassword toUrl="dashboard/"/>
    </>
  );
}
export default RecoveryPasswordPage;
