const questionTypes = [
  {
    id: 1,
    name: "poll.multipleChoice",
  },
  {
    id: 2,
    name: "poll.singleOption",
  },
  {
    id: 3,
    name: "poll.dropDown",
  },
  {
    id: 4,
    name: "poll.number",
  },
  {
    id: 5,
    name: "poll.shortText",
  },
  {
    id: 6,
    name: "poll.longText",
  },
  {
    id: 7,
    name: "poll.email",
  },
  {
    id: 8,
    name: "poll.date",
  },
  {
    id: 9,
    name: "poll.hour",
  },
  {
    id: 10,
    name: "poll.loadFile",
  },
  {
    id: 11,
    name: "poll.terms",
  },
];

export default questionTypes;
