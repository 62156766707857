import { useState } from "react";

function NumberForm(props = { value: "" }) {
  const [number, setNumber] = useState(props.value);

  return (
    <div className="invitation-question-numbers ">
      <input
        className="input"
        type="number"
        onChange={(event) => {
          setNumber(event.target.value);
          props.modifyForm(props.question.id, event.target.value);
        }}
        value={number}
        required={props.question.required}
      />
    </div>
  );
}
export default NumberForm;
