import { useEffect, useState } from "react";
import NavBar from "../../components/users/NavBar";
import "../../assets/css/faqsPage.css";
import { Icon } from "@iconify/react";
import faqsImg from "../../assets/img/faqsImg.svg";
import Api from "../../services/usersApi";
import { useTranslation } from "react-i18next";
import useScrollbySize from "../../hooks/useScroll";
import useFilterObject from "../../hooks/useFilterObject";

function FaqsPage() {
  const [isUser, setIsUser] = useState(false);
  const [faqs, setFaqs] = useState([]);
  const [filteredFaqs, setFilteredFaqs] = useState([]);
  const { searchInfo } = useFilterObject();
  const searchBar = document.getElementById("faqSearchBar");
  const { t } = useTranslation();
  const scroll = useScrollbySize(500, 600, "top") 

  useEffect(() => {
    window.location.href.includes("my-account/tutorials")
      ? setIsUser(true)
      : setIsUser(false);
    async function fetch() {
      const response = await Api.getUserFaqs();
      setFaqs(response);
    }
    fetch();
    scroll()
  }, []);

  return (
    <div className="faqs-container container">
      <div className={isUser ? "hidden-navbar" : "faqs-navbar"}>
        <NavBar />
      </div>
      <div className="faqs-info">
        <h1>FAQs</h1>
        <div className="search-faqs">
          <div className="search-bar-faqs">
            <h2>{t("faqs.title1")}</h2>
            <div>
              <div className="guest-list-search faqs-search-input">
                <input
                  placeholder={t("faqs.searchPlaceholder")}
                  className="input "
                  type="search"
                  onChange={(e) =>
                    setFilteredFaqs(
                      searchInfo(faqs, ["question", "answer"], e.target.value),
                    )
                  }
                />
                <Icon icon="fe:search" />
              </div>
            </div>
          </div>
          <img className="faqsImg" src={faqsImg} alt="Alternative Text" />
        </div>
        <div className="faq-description">
          <h2>{t("faqs.title2")}</h2>
          <p>
            {t("faqs.text")}: <span> hi@kolibird.app</span>
          </p>
        </div>
        <div className="most-faqs">
          {filteredFaqs?.map((faq, index) => {
            return (
              <div key={index} className="faqs-content">
                <p
                  className="title-faqs"
                  onClick={(e) =>
                    e.target.parentNode.classList.toggle("group-active")
                  }
                >
                  {faq.question}
                </p>
                <p className="answer-faqs">{faq.answer}</p>
              </div>
            );
          })}
          <div className="faqs-content">
            <p
              className="type-faqs"
              onClick={(e) => {
                e.target.parentNode.classList.toggle("type-active");
                let childs = document.querySelectorAll(".group-active");
                !e.target.parentNode.classList?.value.includes("type-active") &&
                  childs.forEach((child) =>
                    child.classList.remove("group-active"),
                  );
              }}
            >
              {t("faqs.generalQuestions")}
            </p>
            <div className="faqs-questions-container">
              {faqs?.map((faq, index) => {
                return (
                  faq.type === "1" && (
                    <div className="type-faq-content">
                      <p
                        className="title-faqs"
                        onClick={(e) =>
                          e.target.parentNode.classList.toggle("group-active")
                        }
                      >
                        {faq.question}
                      </p>
                      <p className="answer-faqs">{faq.answer}</p>
                    </div>
                  )
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default FaqsPage;
