import { useAppContext } from "../../context/state";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../services/pollsApi";
import { Icon } from "@iconify/react";

function Choice(props = {}) {
  const context = useAppContext();
  const { t } = useTranslation();
  const [text, setText] = useState(props.choice.text);

  const changeChoice = async (event, choiceUuid) => {
    const choice = {
      text: event.target.value,
    };
    await Api.changePosibility(
      choice,
      context.sharedState.invitation.uuid,
      choiceUuid,
    );

    const temporal = [...context.sharedState.questionsPossibilities];
    const changeContext = temporal.find((a) => a.uuid === choiceUuid);
    changeContext.text = choice.text;

    context.setSharedState({
      ...context.sharedState,
      questionsPossibilities: temporal,
    });
  };

  const removeChoice = (uuid) => {
    Api.removePosibility(context.sharedState.invitation.uuid, uuid);
    let newChoices = context.sharedState.questionsPossibilities.filter(
      (possibility) => possibility.uuid !== uuid,
    );
    context.setSharedState({
      ...context.sharedState,
      questionsPossibilities: newChoices,
    });
    props.setChoices(newChoices);
  };

  return (
    <div className="response-choice" key={props.choice.uuid}>
      <div className="response-choice-input-container">
        <p className="response-choice-plaseHolder">
          {t("poll.option")} {props.index + 1}
        </p>

        <input
          onChange={(event) => setText(event.target.value)}
          onBlur={(event) => changeChoice(event, props.choice.uuid)}
          type="text"
          value={text}
          className="input response-choice-input"
        />
      </div>

      {props.index > 0 && (
        <Icon
          className="response-choice-actions"
          icon="fluent:delete-20-filled"
          onClick={(event) => removeChoice(props.choice.uuid)}
        />
      )}
    </div>
  );
}
export default Choice;
