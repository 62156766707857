import axios from "axios";
import { default as languages, defaultLanguage } from "../constants/languages";
import {generateApiToken} from "./utils";

class Api {
  static getConfig() {
    return {
      headers: {
        Authorization: `Bearer ${localStorage.accessToken}`,
      },
    };
  }

  static getConfigNoAuth() {
    return {
      headers: {
        token: generateApiToken(),
      },
    };
  }

  static async signIn(email, password) {
    const url = "/o/token/";
    
    const config = Api.getConfigNoAuth();
    
    const payload = {
      grant_type: "password",
      username: email.toLowerCase(),
      password: password,
      client_id: process.env.REACT_APP_CLIENT,
      client_secret: process.env.REACT_APP_SECRET,
    };

    let response = {};
    try {
      response = await axios.post(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        payload,
        config
      );
    } catch (error) {
      return error.response;
    }

    localStorage.accessToken = response.data.access_token;
    localStorage.refreshToken = response.data.refresh_token;
    return response;
  }

  static async signUp(email, password, welcomeOffer="") {
    const url = "/users/";
    
    const config = Api.getConfigNoAuth();
    
    const currentLanguage = localStorage.i18nextLng.slice(0, 2);
    const userLanguage = languages
      .map((lang) => lang.value)
      .includes(currentLanguage)
      ? currentLanguage
      : defaultLanguage;
    const payload = {
      username: email.toLowerCase(),
      email: email.toLowerCase(),
      password: password,
      language: userLanguage,
      coupon: welcomeOffer
    };

    let user;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        payload,
        config
      );
      user = response.data;
    } catch (error) {
      return [user, error.response];
    }
    return [user, null];
  }

  static async recoveryPassword(email) {
    const url = "/users/recovery-password/";
    
    const config = Api.getConfigNoAuth();
    
    const payload = {
      username: email,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        payload,
        config
      );
    } catch (error) {
      return null;
    }
    return null;
  }

  static async getCurrentUser() {
    const url = "/users/current/";

    const config = Api.getConfig();

    let response = {};
    try {
      response = await axios.get(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        config,
      );
    } catch (error) {
      // await Api.refreshToken();
      throw error
    }

    return response.data;
  }

  static async getUserFaqs() {
    const url = "/users/faqs/";

    const config = Api.getConfigNoAuth();

    let response = {};
    try {
      response = await axios.get(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        config,
      );
    } catch (error) {
      return error.response.data.error;
    }

    return response.data;
  }

  static async refreshToken() {
    const url = "/o/token/";
    
    const config = Api.getConfigNoAuth();
    
    const payload = {
      grant_type: "refresh_token",
      refresh_token: localStorage.refreshToken,
      client_id: process.env.REACT_APP_CLIENT,
      client_secret: process.env.REACT_APP_SECRET,
    };

    let response = {};
    try {
      response = await axios.post(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        payload,
        config
      );
    } catch (error) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      return error.response.data.error;
    }

    localStorage.accessToken = response.data.access_token;
    localStorage.refreshToken = response.data.refresh_token;
    return null;
  }

  static async verifyUser(uuid) {
    const url = `/users/${uuid}/verify/`;
    
    const config = Api.getConfigNoAuth();
    
    const payload = {
      is_verified: true,
    };

    const response = await axios.patch(
      `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
      payload,
      config
    );

    return response;
  }

  static async changeUser(uuid, payload) {
    const url = `/users/${uuid}/`;

    const config = Api.getConfig();

    const response = await axios.patch(
      `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
      payload,
      config,
    );

    return response;
  }

  static async userContact(payload) {
    const url = "/users/contact/";
    
    const config = Api.getConfigNoAuth();

    let response = {};
    try {
      response = await axios.post(
        `${process.env.REACT_APP_URL}${process.env.REACT_APP_API_VERSION}${url}`,
        payload,
        config
      );
    } catch (error) {
      return error.response.data.error;
    }

    return null;
  }
}
export default Api;
