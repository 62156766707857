import { Icon } from "@iconify/react";
import { t } from "i18next";

import "../../../assets/css/polotno/sidePanelTextInput.css";

export default function SidePanelTextInput({ getInputValue }) {
  return (
    <form
      className="side-panel-search-wrapper"
      onSubmit={(event) => event.preventDefault()}
    >
      <Icon
        className="input-icon"
        icon="fluent:search-12-regular"
        color="#b06bbf"
      />
      <input
        className="side-panel-search"
        onChange={getInputValue}
        placeholder={t("design.boardUI.sidePanel.searchPlaceholder")}
      />
    </form>
  );
}
