import { createContext, useContext, useEffect, useState } from "react";

export const AppContext = createContext();
const custonColors = (colors) => {
  document?.documentElement.style.setProperty(
    "--background",
    colors.background
  );
  document?.documentElement.style.setProperty("--text", colors.text);
  document?.documentElement.style.setProperty(
    "--secondText",
    colors.secondText
  );
};

export function AppWrapper({ children }) {
  const [sharedState, setSharedState] = useState({
    invitations: [],
    invitation: null,
    questions: [],
    questionsPossibilities: [],
    filterTagTypes: [],
    filterTags: [],
    templates: [],
    currentTemplatePage: 1,
    lastTemplatePage: 1,
    previewEmailState: {
      envelope: true,
      personalMessage: false,
      headerMessage: false,
    },
  });

  const colors = {
    background: "#f4f4f4",
    text: "#28132d",
    secondText: "#ffffff",
  };

  useEffect(() => {
    custonColors(colors);
  }, []);

  const [user, setUser] = useState(null);

  const [invitationStore, setInvitationStore] = useState(null);
  const [envelopeStore, setEnvelopeStore] = useState(null);

  return (
    <AppContext.Provider
      value={{
        sharedState,
        setSharedState,
        user,
        setUser,
        invitationStore,
        setInvitationStore,
        envelopeStore,
        setEnvelopeStore,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
