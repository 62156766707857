import SelectTemplate from "../../components/invitations/selectTemplate";

function SelectTemplatePage() {
  return (
    <>
      <SelectTemplate />
    </>
  );
}
export default SelectTemplatePage;
