import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import { createPortal } from "react-dom";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/error404.css";
import logo from "../../assets/img/logoPurple.png";
import errorPicture from "../../assets/img/general/Error404Picture.svg";
function NotFoundPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  function handleClick() {
    navigate(-1);
  }
  return (
    <div className="error-404-box">
      <div className="error-404-header">
        <img src={logo} alt="" />
        <button onClick={handleClick}>
          <Icon icon="akar-icons:chevron-left" />
          {t("error404.goBack")}
        </button>
      </div>
      <div className="error-404-title">
        <h1>{t("error404.ups")}</h1>
        <p>{t("error404.notFound")}</p>
        <img src={errorPicture} alt="imagen de gatitos" />
      </div>

      <div className="error-404-redirect">
        <p>{t("error404.where")}</p>
        <a href={process.env.REACT_APP_CURRENT_URL}>
          <Icon icon="fluent:home-20-regular" />
          {t("general.home")}
        </a>
        <Link to="/my-events">
          <Icon icon="fluent:design-ideas-16-regular" />
          {t("general.myEvents")}
        </Link>
        <Link to="/">
          <Icon icon="fluent:mail-28-regular" />
          {t("error404.myMessages")}
        </Link>
        <Link to="/">
          <Icon icon="carbon:settings" />
          {t("error404.config")}
        </Link>
      </div>
    </div>
  );
}
export default function NotFoundPortal() {
  return createPortal(<NotFoundPage />, document.getElementById("root"));
}
