import { useNavigate } from "react-router-dom";
import { useAppContext } from "../context/state";
import Api from "../services/usersApi";
import UsersServices from "../services/usersServices";

function useCheckLogin(url) {
  const context = useAppContext();
  const navigate = useNavigate();

  const checkLogin = async () => {
    const url = window.location.pathname;
    
    
    if(!((url.search("/invitation/") > 0) && (url.search("/group/") > 0))){
      if (!UsersServices.isLogIn()) {
        return navigate("/sign-in/", { replace: true });
      }
      
      let user = context.user;
      const refresh= async ()=>{
          try{
            await Api.refreshToken();
            user = await Api.getCurrentUser();
          }catch{
            if (!UsersServices.isLogIn()) {
                return navigate("/sign-in/", { replace: true });
      }
          }
      }
      if (!user) {
        try{
        user = await Api.getCurrentUser();
        }catch{
          await refresh()
        }
      }
      context.setUser(user);
      if (user?.is_verified === false) {
        navigate(`/verify-account/?next=${url}`, { replace: true });
      }
    }
  };

  return [checkLogin];
}
export default useCheckLogin;
