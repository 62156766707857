import { useState } from "react";

function DropDownForm(props = { possibilities: [], value: "" }) {
  const [selectValue, setSelectValue] = useState(props.value);
  return (
    <div className="info-input-container">
      <select
        className="input"
        onChange={(event) => {
          setSelectValue(event.target.value);
          props.modifyForm(props.possibilities[0].question, event.target.value);
        }}
        required={props.question.required}>
        <option defaultValue={selectValue}>
          {props.possibilities.find(
            (poss) => Number(poss.id) === Number(props?.value)
          )?.text || ""}
        </option>
        {props.possibilities.map((possibility) => {
          return (
            <option key={possibility.uuid} value={possibility.id}>
              {possibility.text}
            </option>
          );
        })}
      </select>
    </div>
  );
}
export default DropDownForm;
