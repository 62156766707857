import { Icon } from "@iconify/react";
import React from "react";
import "../../assets/css/button.css";

export default function Button({
  text,
  type,
  icon,
  onClick,
  buttonType,
  className,
  disabled,
  color,
}) {
  return (
    <button
      disabled={disabled ? disabled : false}
      type={buttonType ? `${buttonType}` : "button"}
      className={
        type == "primary"
          ? `primary-button ${className}`
          : `secondary-button ${className}`
      }
      onClick={onClick}
      style={{backgroundColor:color}}
    >
      {icon && <Icon icon={icon} />}
      {text}
    </button>
  );
}
