import { useState } from "react";
import { useAppContext } from "../../context/state";
import Button from "../general/Button";
import { useTranslation } from "react-i18next";
import CreateInvitation from "./createInvitation";

function TemplateFilters(props = {}) {
  const context = useAppContext();
  const { t } = useTranslation();
  const [tagsActive, setTagsActive] = useState({});

  const openTagType = (e) => {
    e.target.classList.toggle("view");
  };

  const tagActive = (e, tag, tagType) => {
    if (tagsActive[tagType.name] === tag) {
      const newTagsActive = { ...tagsActive };
      delete newTagsActive[tagType.name];
      setTagsActive(newTagsActive);
    } else {
      let tempTagsActive = { [tagType.name]: tag };
      setTagsActive({ ...tagsActive, ...tempTagsActive });
    }
    props.addFilter(tag, tagType);
  };

  return (
    <div className="filters-container">
      <CreateInvitation newInvitation={true}>
        <Button
          type="primary"
          text={t("general.myDesign")}
          icon="ic:baseline-plus"></Button>
      </CreateInvitation>

      <div className="filter-container">
        <p className="title-filter">{t("templates.filters")}</p>
        {context.sharedState.filterTagTypes?.map((tagType, index) => {
          return (
            <div className={tagType.name} key={index}>
              <p className="tag-type" onClick={openTagType}>
                {tagType.name}
              </p>

              <div className="none tags-container">
                <div className="tags">
                  {context.sharedState.filterTags
                    ?.filter((tag) => tag.tag_type == tagType.id)
                    .map((tag) => (
                      <p
                        key={`tag${tag.id}`}
                        onClick={(e) => tagActive(e, tag.name, tagType)}
                        className={
                          tag.name != tagsActive[tagType.name]
                            ? "tag"
                            : "tag tag-active"
                        }
                        style={
                          tagType.name == "color"
                            ? {
                                backgroundColor: `#${tag.name}`,
                              }
                            : null
                        }>
                        {tagType.name == "color" ? null : tag.name}
                      </p>
                    ))}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default TemplateFilters;
