import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../assets/css/email.css";
import PreviewEmail from "./previewEmail";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../context/state";
import Datalayer from "../../services/datalayer";

function PreviewBoard() {
  const { t } = useTranslation();
  const [optionPreview, setOptionPreview] = useState(true);
  const navigate = useNavigate();
  const context = useAppContext();

  useEffect(() => {
    Datalayer.push("preview_step")
  }, []);

  return (
    <div className="preview white-container">
      <div className="preview-button-container">
        <button
          className="preview-email-button active-email-button"
          onClick={() => setOptionPreview(true)}
        >
          {t("preview.previewEmail")}
        </button>
        <button
          className="preview-invitation-button "
          onClick={() => {
            window.open(
              `/invitation/${context.sharedState.invitation.uuid}/`,
              "_blank",
              "noopener,noreferrer",
            );
          }}
        >
          {t("preview.previewInvitation")}
        </button>
      </div>
      <PreviewEmail />
    </div>
  );
}
export default PreviewBoard;
