import { useController } from "react-hook-form";
import { Autocomplete } from "@react-google-maps/api";
import { useRef } from "react";
import { useMapLoader } from "../../context/maps";

const AutocompleteInput = ({
  name,
  control,
  defaultValue,
  rules,
  errors,
  inputFunction,
  idInput,
  handleLocation,
  cancelCheckMap,
}) => {
  const { isLoaded, loadError } = useMapLoader();

  const { field } = useController({
    name,
    control,
    defaultValue,
    rules,
  });

  const autocompleteRef = useRef(null);

  const handlePlaceSelect = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      field.onChange(place.formatted_address || place.name || "");
      // Actualiza el valor del input con la dirección formateada
      if (place?.formatted_address) {
        handleLocation(true);
        inputFunction({
          target: {
            id: "place_address",
            value: place.formatted_address,
          },
        });
      } else {
        cancelCheckMap(place?.name || "");
      }
    }
  };

  const handleInput = (e) => {
    field.onChange(e.target.value);
    cancelCheckMap(e.target.value);
  };

  return isLoaded && !loadError ? (
    <Autocomplete
      onLoad={(autocomplete) => {
        autocompleteRef.current = autocomplete;
      }}
      onPlaceChanged={handlePlaceSelect}
      fields={["name", "geometry", "formatted_address"]}
      className="container-autocomplete">
      <input
        {...field}
        type="text"
        className={errors?.place_address ? "error input" : "input"}
        onInput={(e) => handleInput(e)}
        id={idInput}
        // defaultValue={defaultValue}
      />
    </Autocomplete>
  ) : (
    <input
      {...field}
      type="text"
      onInput={(e) => handleInput(e)}
      // defaultValue={defaultValue}
      className={errors?.place_address ? "error input" : "input"}
    />
  );
};

export default AutocompleteInput;
