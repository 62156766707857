import { useState } from "react";

function DateForm(props = { value: "" }) {
  const [date, setDate] = useState(props.value);

  return (
    <div className="info-input-container">
      <input
        type="date"
        className="input"
        onChange={(event) => {
          setDate(event.target.value);
          props.modifyForm(props.question.id, event.target.value);
        }}
        value={date}
        required={props.question.required}
      />
    </div>
  );
}
export default DateForm;
