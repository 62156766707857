import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { uploadInvitationImageFile } from "../../services/utils";
import "../../assets/css/designSteps.css";
import casette from "../../assets/img/icons/casette.svg";
import { useAppContext } from "../../context/state";
import InvitationsServices from "../../services/invitationsServices";
import EditButton from "../general/editButton";
import popUpAlert from "../general/popUpAlert";
import SaveButton from "../general/saveButton";
import Datalayer from "../../services/datalayer";

// import CreateInvitationButton from './createInvitationButton';

function DesignSteps(props) {
  const context = useAppContext();
  const [saveError, setIsSaveError] = useState();
  const [currentStep, setCurrentStep] = useState(0);
  const { t } = useTranslation();
  const steps = [
    { name: t("design.invitation") },
    { name: t("design.envelope") },
  ];

  return (
    <div className="design-steps">
      {/* <div className='design-steps-first-section'>
        <CreateInvitationButton />
      </div> */}

      <div className="design-steps-second-section">
        {/* <div className='undo-redo-wrapper'>
          <button>undo</button>
          <button>redo</button>
        </div> */}

        <div className="design-steps-wrapper">
          {steps.map((step, index) => {
            return (
              <p
                className={`nav-item ${
                  (currentStep || props.step) == index && "nav-item-active"
                }`}
                key={index}
                onClick={() => {
                  props.setIsloading(true);
                  setCurrentStep(index);
                  props.setCurrentStep(index);
                }}>
                {step.name}
              </p>
            );
          })}
        </div>
        <div className="save-design-button-wrapper">
          <SaveButton
            handleClick={props.saveDesign}
            saveError={props.saveError}
          />
        </div>
      </div>
    </div>
  );
}
export default DesignSteps;
