import empty from "../../assets/img/general/empty.svg";
import "../../assets/css/empty.css";

// accepts two props "title = string , subTitle=string"
export default function Empty({ tittle, subTittle }) {
  return (
    <div className="empty-container">
      <img src={empty} />
      {tittle?.length ? <h1>{tittle}</h1> : null}
      {subTittle?.length ? <p>{subTittle}</p> : null}
    </div>
  );
}
