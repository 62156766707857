import { useAppContext } from "../../context/state";
import { useTranslation } from "react-i18next";
import questionTypes from "../../constants/questionTypes";
import Api from "../../services/pollsApi";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";

function AddPollElement() {
  const context = useAppContext();
  const { t } = useTranslation();
  const [forbiddenTypes, setForbiddenTypes] = useState([10]);
  const icons = [
    "fluent:checkbox-checked-24-filled",
    "fluent:radio-button-24-filled",
    "ic:round-arrow-drop-down-circle",
    "ant-design:number-outlined",
    "material-symbols:short-text-rounded",
    "ph:text-align-left-bold",
    "ic:twotone-alternate-email",
    "akar-icons:calendar",
    "akar-icons:clock",
    "fluent:checkbox-checked-24-filled",
    "fluent:checkbox-checked-24-filled",
  ];
  useEffect(() => {
    const existElevenQuestion = context.sharedState?.questions.some(
      (question) => question.type == 11
    );
    const isElevenInForbidden = forbiddenTypes.some((type) => type == 11);
    if (existElevenQuestion && !isElevenInForbidden) {
      setForbiddenTypes((prev) => [...prev, 11]);
    }
    if (!existElevenQuestion && isElevenInForbidden) {
      setForbiddenTypes((prev) => prev.filter((type) => type != 11));
    }
  }, [context.sharedState]);

  const addQuestion = async (type) => {
    let questions = context.sharedState.questions;
    const order = context.sharedState.questions.length + 1;
    const required = type == 11 ? true : false;
    const questionsWithChoices = [1, 2, 3, 11];

    const newQuestion = {
      invitation: context.sharedState.invitation.id,
      text: "null",
      type: type,
      required: required,
      order: order,
    };
    const savedQuestion = await Api.createQuestion(
      newQuestion,
      context.sharedState.invitation.uuid
    );
    savedQuestion && questions.push(savedQuestion);

    let newChoices = context.sharedState.questionsPossibilities;
    if (questionsWithChoices.includes(type)) {
      const textResponse = type == 11 ? "Si" : "";
      const choice = {
        text: textResponse,
        question: savedQuestion.id,
      };

      const savedChoice = await Api.createPosibility(
        choice,
        context.sharedState.invitation.uuid
      );

      savedChoice && newChoices.push(savedChoice);
    }

    context.setSharedState({
      ...context.sharedState,
      questions: questions,
      questionsPossibilities: newChoices,
    });
  };

  return (
    <div className="poll-options-container">
      <h4>{t("poll.addElement")}</h4>
      <div className="poll-options">
        {questionTypes.map(
          (type, index) =>
            !forbiddenTypes.includes(type.id) && (
              <div
                className="poll-option"
                key={type.id}
                onClick={() => addQuestion(type.id)}>
                <Icon icon={icons[index]} />
                <p>
                  {t(type.name)}
                  {type.icon}
                </p>
              </div>
            )
        )}
      </div>
    </div>
  );
}
export default AddPollElement;
